/**
 * Helpers for common styled components queries
 */

// Get a color from the theme
const getColor = (colorKey) => ({ theme }) => theme.colors[colorKey];
const getSpace = (spaceKey1, spaceKey2, spaceKey3) => ({ theme }) => {
  const { space } = theme;

  if (spaceKey3) {
    return `${space[spaceKey1]} ${space[spaceKey2]} ${space[spaceKey3]}`;
  }

  if (spaceKey2) {
    return `${space[spaceKey1]} ${space[spaceKey2]}`;
  }

  return space[spaceKey1];
};

export { getColor, getSpace };
