/**
 * Contains different text styles
 */

import styled from 'styled-components';
import * as styledSystem from 'styled-system';

interface HeadlineProps extends styledSystem.SpaceProps {}

const H1 = styled.h1(({ theme }) => ({
  color: theme.colors.text.heading,
  margin: '24px 0',
}));

const H2 = styled.h2(({ theme }) => ({
  color: theme.colors.text.heading,
  fontSize: '22px',
  fontWeight: 'bold',
  textAlign: 'center',
}));

const H3 = styled.h3<HeadlineProps>(
  ({ theme }) => ({
    color: theme.colors.text.headingSecondary,
  }),
  styledSystem.space
);

export const Headline = {
  H1,
  H2,
  H3,
};

export const Subline = styled.p(({ theme }) => ({
  color: theme.colors.text.main,
  textAlign: 'center',
}));
