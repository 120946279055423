import * as React from 'react';
import styled from 'styled-components';

import { SmallIcon, IconsSmall, IconsDefault, MediumIcon } from '../icon';

const Button = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0 4px;
`;

interface IconButtonProps {
  title?: string;
  fill?: string;
  onClick?: (e: React.SyntheticEvent) => void;
}

interface MediumIconButtonProps extends IconButtonProps {
  iconName: keyof typeof IconsDefault;
}

export const MediumIconButton = (props: MediumIconButtonProps) => (
  <Button onClick={props.onClick} title={props.title}>
    <MediumIcon name={props.iconName} fill={props.fill} />
  </Button>
);

interface SmallIconButtonProps extends IconButtonProps {
  iconName: keyof typeof IconsSmall;
}

export const SmallIconButton = (props: SmallIconButtonProps) => (
  <Button onClick={props.onClick} title={props.title}>
    <SmallIcon name={props.iconName} fill={props.fill} />
  </Button>
);

export default SmallIconButton;
