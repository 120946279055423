import * as React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as HookApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';

import { baseTheme, yunaTheme } from './theme';

import './App.css';
import Router from './views/router';
import store from './redux/store';
import { client } from './api/apollo';
import ModalProvider from './components/modal';
import { NotificationProvider } from './components/notifications';

const queryClient = new QueryClient();

const App = () => {
  const theme = process.env.REACT_APP_THEME === 'yuna' ? yunaTheme : baseTheme;

  return (
    <Provider store={store}>
      {/* TODO: Remove ApolloProvider and only use hooks here */}
      <ApolloProvider client={client}>
        <HookApolloProvider client={client}>
          <QueryClientProvider client={queryClient}>
            {/* @ts-ignore - React 18 incompatibility */}
            <ThemeProvider theme={theme}>
              <ModalProvider>
                <NotificationProvider>
                  <Router />
                </NotificationProvider>
              </ModalProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </HookApolloProvider>
      </ApolloProvider>
    </Provider>
  );
};

export default App;
