import * as React from 'react';
import styled from 'styled-components';
import { Block } from '../../lib/block';

interface Props {
  version: string | null;
}

const StyledSmall = styled.small`
  color: ${(props) => props.theme.colors['gray-500']};
`;

export const VersionInfo = (props: Props) => {
  if (props.version === null) {
    return null;
  }
  return (
    <Block mx={4}>
      <StyledSmall>Version {props.version}</StyledSmall>
    </Block>
  );
};
