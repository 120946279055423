import { useQuery } from 'react-query';

import { camelcaseKeys } from '../utils';
import { getBaseUrl } from '../config';
import { useFetch } from '../fetch';
import { GetValidatedResultResponse } from './get-validated-result.types';

const QUERY_KEY = 'validatedResultsTickets';

interface GetValidatedResultProps {
  ticketId: string;
}

const useGetValidatedResult = () => {
  const { get } = useFetch();
  const baseUrl = getBaseUrl();

  return async ({ ticketId }: GetValidatedResultProps) => {
    const { data } = await get<GetValidatedResultResponse>(
      `${baseUrl}tickets/${ticketId}/`
    );
    return camelcaseKeys(data);
  };
};

interface GetOffersQueryProps {
  ticketId: string;
}

export function useGetOffersQuery({ ticketId }: GetOffersQueryProps) {
  const getValidatedResult = useGetValidatedResult();

  return useQuery([QUERY_KEY, ticketId], () => {
    return getValidatedResult({ ticketId });
  });
}
