import { ReactComponent as FileIcon24 } from './assets/24/File.svg';
import { ReactComponent as LocationIcon24 } from './assets/24/Location.svg';
import { ReactComponent as NewSnipIcon24 } from './assets/24/NewSnip.svg';
import { ReactComponent as SnippetsIcon24 } from './assets/24/Snippets.svg';

import { ReactComponent as ArrowDownIcon16 } from './assets/16/ArrowDown.svg';
import { ReactComponent as CloseIcon16 } from './assets/16/Close.svg';
import { ReactComponent as DotsIcon16 } from './assets/16/Dots.svg';
import { ReactComponent as NewSnipIcon16 } from './assets/16/NewSnip.svg';
import { ReactComponent as StarIcon16 } from './assets/16/Star.svg';
import { ReactComponent as StarFilledIcon16 } from './assets/16/StarFilled.svg';

type IconProps = {
  size: '16' | '24';
};

function ArrowDown() {
  return <ArrowDownIcon16 />;
}

function Close() {
  return <CloseIcon16 />;
}

function Dots() {
  return <DotsIcon16 />;
}

function File() {
  return <FileIcon24 />;
}

function Location() {
  return <LocationIcon24 />;
}
function NewSnip({ size }: IconProps) {
  return size === '24' ? <NewSnipIcon24 /> : <NewSnipIcon16 />;
}
function Snippets() {
  return <SnippetsIcon24 />;
}

type StarProps = {
  variant?: 'outline' | 'filled';
};

function Star({ variant = 'outline' }: StarProps) {
  if (variant === 'outline') {
    return <StarIcon16 />;
  }

  return <StarFilledIcon16 />;
}

export { ArrowDown, Close, Dots, File, Location, NewSnip, Snippets, Star };
