import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDropzone, DropEvent } from 'react-dropzone';
import { useMutation } from '@apollo/react-hooks';
import produce from 'immer';

import { UPLOAD_ATTACHMENT } from '../../mutations/attachment';
import { UploadContainer } from './upload-container';
import { UploadFileList, Attachment, UploadStatus } from './upload-file-list';
import { AttachmentType } from '../../types/apollo/globalTypes';
import {
  UploadAttachment as UploadAttachmentT,
  UploadAttachmentVariables,
} from '../../types/apollo/UploadAttachment';
import { FetchResult } from 'react-apollo';

const AttachmentTypeName: 'Attachment' = 'Attachment';

interface useFileListProps {
  onUpload(file: File): Promise<FetchResult<UploadAttachmentT>>;
  initialFiles?: Attachment[];
}

const useFileList = ({ initialFiles, onUpload }: useFileListProps) => {
  const [files, setFiles] = useState<Attachment[]>(initialFiles || []);

  // Helper to generate unique ids for the file
  const currentFileId = useRef(0);

  const uploadFile = useCallback(
    async (fileIndex: number) => {
      try {
        // Get the file
        const file = files[fileIndex];
        const result = await onUpload(file.fileToUpload!);

        // Update the status of the file when the upload is finished
        setFiles(
          produce((draft) => {
            const draftFile = draft[fileIndex];

            // Check if we have duplicates
            if (result.data!.uploadAttachment.duplicates) {
              draftFile.uploadStatus = UploadStatus.duplicate;
              draft[
                fileIndex
              ].duplicateTicketIds = result.data!.uploadAttachment.duplicates;
              draftFile.id = result.data!.uploadAttachment.attachment.id;
            } else {
              draftFile.uploadStatus = UploadStatus.finished;
              draftFile.id = result.data!.uploadAttachment.attachment.id;
            }
          })
        );
      } catch (err) {
        setFiles(
          produce((draft) => {
            draft[fileIndex].uploadStatus = UploadStatus.failed;
          })
        );
      }
    },
    // TODO: Check if eslint is correct here
    // eslint-disable-next-line
    [files]
  );

  const deleteFileWithId = useCallback(
    (fileId: string) => {
      setFiles(
        produce((draft) => {
          return draft.filter((file: any) => file.id !== fileId);
        })
      );
    },
    // TODO: Check if eslint is correct here
    // eslint-disable-next-line
    [files]
  );

  useEffect(() => {
    // Check if there are new files to upload
    const fileIndex = files.findIndex(
      (file) => file.uploadStatus === UploadStatus.uploading
    );

    if (fileIndex < 0) {
      return;
    }

    uploadFile(fileIndex);
    // TODO: Check if eslint is correct here
    // eslint-disable-next-line
  }, [files]);

  const uploadFiles = useCallback(async (newFiles: File[]) => {
    // add the new files to the list
    setFiles(
      produce((draft) => {
        // Generate objects which matches the Attachment type
        const filesToAdd = newFiles.map((file) => ({
          id: `draft_${currentFileId.current++}`,
          file: file.name,
          fileType: AttachmentType.pdf,
          fileName: file.name,
          uploadStatus: UploadStatus.uploading,
          fileToUpload: file,
          __typename: AttachmentTypeName,
        }));

        draft.push(...filesToAdd);
      })
    );
  }, []);

  return {
    files,
    uploadFiles,
    deleteFileWithId,
  };
};

type onDropAcceptedT = (files: File[], event: DropEvent) => void;

interface Props {
  ticketId: string;
  attachments?: Attachment[];
}

export const UploadForm = ({ attachments, ticketId }: Props) => {
  const [uploadAttachment] = useMutation<
    UploadAttachmentT,
    UploadAttachmentVariables
  >(UPLOAD_ATTACHMENT);

  const onUpload = useCallback(
    (file: File) => {
      return uploadAttachment({ variables: { file, ticketId } });
    },
    // TODO: Check if eslint is correct here
    // eslint-disable-next-line
    []
  );

  const { files, uploadFiles, deleteFileWithId } = useFileList({
    initialFiles: attachments,
    onUpload,
  });

  const onDropAccepted = useCallback<onDropAcceptedT>(
    (filesToUpload) => uploadFiles(filesToUpload),
    // TODO: Check if eslint is correct here
    // eslint-disable-next-line
    [files]
  );

  const {
    isDragActive,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'application/pdf',
    onDropAccepted,
  });

  return (
    <UploadFileList files={files} deleteFile={deleteFileWithId}>
      <UploadContainer
        isDragActive={isDragActive}
        isDragReject={isDragReject}
        size={files.length === 0 ? 'cover' : 'small'}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
      </UploadContainer>
    </UploadFileList>
  );
};
