import * as React from 'react';
import styled from 'styled-components';

import { Headline } from '../layout/text';
import { Flex, FlexChild } from '../layout';

const GlowBoxBase = styled.div(({ theme }) => ({
  background: theme.colors.bg.glowBox,
  borderRadius: '6px',
  boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.12)',
  padding: '12px 24px 24px',
  flex: 1,
  display: 'flex',
}));

const Counter = styled.span`
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100px;
  padding: 2px 8px 3px;
  letter-spacing: 0.06em;
  color: #ffffff;
  display: inline-block;
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
`;

interface GlowBoxTitleProps {
  title?: string;
  counter?: number;
  hideCounter?: boolean;
  renderToolbar?: React.ReactNode;
  noFlex?: boolean;
}

const GlowBoxTitle = ({
  title,
  counter,
  hideCounter = false,
  children,
  renderToolbar,
  noFlex = false,
}: React.PropsWithChildren<GlowBoxTitleProps>) => {
  const flex = noFlex === true ? undefined : 1;

  return (
    <GlowBoxBase>
      <Flex flexDirection="column" flex={flex}>
        <FlexChild>
          <Flex flex={flex}>
            <FlexChild>
              <Headline.H3 my={0}>{title}</Headline.H3>
            </FlexChild>
            {counter !== undefined && !hideCounter ? (
              <FlexChild>
                <Counter>{counter}</Counter>
              </FlexChild>
            ) : null}
            <FlexChild ml="auto">{renderToolbar}</FlexChild>
          </Flex>
        </FlexChild>
        {children}
      </Flex>
    </GlowBoxBase>
  );
};

export { GlowBoxBase, GlowBoxTitle };
