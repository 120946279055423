import { CamelCaseKeys } from 'camelcase-keys';

import { getBaseUrl } from '../config';
import { useFetch } from '../fetch';
import { camelcaseKeys } from '../utils';

/**
 * Purpose (kind) of the attachment.
 */
type AttachmentType = 'document' | 'plan' | 'picture';
/**
 * Media type of the attachment.
 */
type AttachmentFileType = 'pdf' | 'png' | 'jpeg';

interface BaseAttachmentItemResponse {
  id: number;
  description: string | null;
  file: string;
  file_type: AttachmentFileType;
  file_name: string;
  type: AttachmentType;
}

type SuggestionKpi =
  | 'address'
  | 'area'
  | 'company_contact_name'
  | 'company_name'
  | 'construction_year'
  | 'email'
  | 'hotel_rooms'
  | 'occupancy'
  | 'parking_spaces'
  | 'phonenumber'
  | 'property_use'
  | 'rental_cost';

type SuggestionItemResponse = {
  kpi: SuggestionKpi;
  value: {
    unit: string | null;
    number: number | null;
    number_system: string | null;
    original_value: string;
  };
  extra: {
    bbox: [number, number, number, number];
    page: {
      bbox: [number, number, number, number];
      number: string;
    };
    match: string | string[];
    coordinates: Array<{ h: number; w: number; x: number; y: number }>;
  };
};

interface ProcessedAttachmentItemResponse extends BaseAttachmentItemResponse {
  suggestions: SuggestionItemResponse[];
}

type ProcessedAttachmentItem = CamelCaseKeys<
  ProcessedAttachmentItemResponse,
  true
>;

/**
 * Placeholder item that gets added to the attachment list of a ticket until it
 * is processed by the backend.
 */
type ProcessedAttachmentItemPlaceholder = {
  id: number;
  status: 'uploading' | 'failed';
  file: string;
  type: AttachmentType;
  suggestions: [];
  /**
   * Special value to allow a distinction in TypeScript between items that are
   * generated by the server and by the frontend.
   */
  isPlaceholder: true;
  description: string | null;
  fileName: string;
};

interface BaseTicketItemResponse {
  id: number;
  title: string;
  attachments: BaseAttachmentItemResponse[];
  created_by: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
  };
  main_picture_id: number | null;
}

interface TicketItemDraftResponse extends BaseTicketItemResponse {
  status: 'draft';
}

interface TicketItemAnalysisResponse extends BaseTicketItemResponse {
  status: 'analysis';
}

interface TicketItemFailedResponse extends BaseTicketItemResponse {
  status: 'failed';
}

interface TicketItemReviewResponse extends BaseTicketItemResponse {
  status: 'review';
  attachments: ProcessedAttachmentItemResponse[];
  analysis_package_version: string;
}

interface TicketItemDoneResponse extends BaseTicketItemResponse {
  status: 'done';
  analysis_package_version: string;
}

interface TicketItemArchiveResponse extends BaseTicketItemResponse {
  status: 'archive';
}

type GetTicketResponse =
  | TicketItemDraftResponse
  | TicketItemAnalysisResponse
  | TicketItemFailedResponse
  | TicketItemReviewResponse
  | TicketItemDoneResponse
  | TicketItemArchiveResponse;

type GetTicketProps = {
  ticketId: number;
};

const useGetTicket = () => {
  const { get } = useFetch();
  const baseUrl = getBaseUrl();

  return async ({ ticketId }: GetTicketProps) => {
    const { data } = await get<GetTicketResponse>(
      `${baseUrl}tickets/${ticketId}/`
    );
    return camelcaseKeys(data);
  };
};

/**
 * Normalized (with camelcaseKeys) TicketResponse.
 */
type TicketItem = CamelCaseKeys<GetTicketResponse, true>;
/**
 * Normalized (with camelcaseKeys) TicketResponse when the ticket is processed.
 */
type TicketItemReview = Omit<
  Extract<
    TicketItem,
    {
      status: 'review';
    }
  >,
  'attachments'
> & {
  // Adds case when a attachment placeholder is added to the data (from
  // snippets)
  attachments: Array<
    ProcessedAttachmentItem | ProcessedAttachmentItemPlaceholder
  >;
};
type TicketItemDone = Omit<
  Extract<
    TicketItem,
    {
      status: 'done';
    }
  >,
  'attachments'
> & {
  attachments: Array<ProcessedAttachmentItem>;
};

export type {
  AttachmentType,
  TicketItem,
  TicketItemReview,
  TicketItemDone,
  ProcessedAttachmentItem,
  ProcessedAttachmentItemPlaceholder,
  ProcessedAttachmentItemResponse,
};
export { useGetTicket };
