import * as React from 'react';
import { Link } from 'react-router-dom';

import { Flex, FlexChild, Absolute } from '../../components/layout';
import { Headline } from '../../components/layout/text';
import { SubActionButton } from '../../components/button/sub-action';
import Icon, { IconsDefault } from '../../components/icon';
import { ModalTemplate, useModal } from '../../components/modal';
import { UserMenu } from '../../components/user/user-menu';
import { useCurrentUser } from '../../components/current-user';

interface Props {
  title: string;
  hideBackNavigation?: boolean;
  hideActionBar?: boolean;
  onClose?: () => void;
}

const Header = (props: Props) => {
  const { user: currentUser } = useCurrentUser();
  const { firstName = null, lastName = null } = currentUser || {};

  const { showModal } = useModal();
  const createNewTicket = React.useCallback(() => {
    showModal(
      {
        template: ModalTemplate.TICKET_EDIT,
        title: 'Neues Exposé anlegen',
      },
      props.onClose
    );
  }, [showModal, props.onClose]);

  return (
    <Flex alignItems="center">
      {!props.hideBackNavigation ? (
        <Absolute ml={-46}>
          <Link to="/" title="Zurück zur Übersicht">
            <Icon name={IconsDefault.navigationBack} fill="white" />
          </Link>
        </Absolute>
      ) : null}

      <FlexChild>
        <Headline.H1>{props.title}</Headline.H1>
      </FlexChild>

      {!props.hideActionBar ? (
        <FlexChild ml={5}>
          <SubActionButton
            onClick={createNewTicket}
            title="Neues Exposé anlegen"
          >
            Neues Exposé
          </SubActionButton>
        </FlexChild>
      ) : null}

      <FlexChild ml="auto">
        <UserMenu firstName={firstName} lastName={lastName} />
      </FlexChild>
    </Flex>
  );
};

export { Header };
