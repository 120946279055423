import * as React from 'react';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { FieldRenderProps, useForm } from 'react-final-form';
import { useEffectReducer, EffectReducer } from 'use-effect-reducer';

import { GroupItemResult } from '../form-data-loader';
import { Box, Flex, FlexChild } from '../layout';
import { TextLabelFloat } from '../input/text-label-float';
import ActionButtonHighlight from '../button/action-button-highlight';
import { FormLabel } from './style';
import { ActionButton } from '../../lib/buttons/action-button';
import { FormDataEntry } from '../form-data/form-data.types';
import { InputValueFieldMapper } from '../form-data-field-mapper';
import { AddressValidationSelect } from './address-validation.select';
import { useViewerSwitcher } from '../viewer-switcher';
import { useGoogleMapsClient } from '../google-maps-client';

/* -----------------------------------------------------------------------------
 * Reducer
 * ---------------------------------------------------------------------------*/

type AddressValidationState =
  | {
      value: 'idle';
      addressStringToValidate: undefined;
      error: undefined;
    }
  | {
      value: 'validating';
      addressStringToValidate: string;
      error: undefined;
    }
  | {
      value: 'success';
      addressStringToValidate: string;
      error: undefined;
    }
  | {
      value: 'error';
      addressStringToValidate: string;
      error: string;
    };

type AddressValidationEvent =
  | {
      type: 'START_VALIDATION';
      addressStringToValidate: string;
    }
  | {
      type: 'VALIDATION_SUCCESS';
      geocoderResult: google.maps.GeocoderResult;
    }
  | {
      type: 'VALIDATION_FAILED';
      error: string;
    };

type AddressValidationEffect =
  | {
      type: 'validateWithGeocode';
      addressStringToValidate: string;
    }
  | {
      type: 'replaceFormValuesWithGeocodeResult';
      geocoderResult: google.maps.GeocoderResult;
    };

const addressValidationReducer: EffectReducer<
  AddressValidationState,
  AddressValidationEvent,
  AddressValidationEffect
> = (state, event, exec) => {
  switch (state.value) {
    case 'idle':
    case 'success':
    case 'error': {
      switch (event.type) {
        case 'START_VALIDATION': {
          exec({
            type: 'validateWithGeocode',
            addressStringToValidate: event.addressStringToValidate,
          });

          return {
            value: 'validating',
            addressStringToValidate: event.addressStringToValidate,
            error: undefined,
          };
        }

        default:
          return state;
      }
    }

    case 'validating': {
      switch (event.type) {
        case 'VALIDATION_SUCCESS': {
          exec({
            type: 'replaceFormValuesWithGeocodeResult',
            geocoderResult: event.geocoderResult,
          });

          return {
            ...state,
            value: 'success',
          };
        }

        case 'VALIDATION_FAILED': {
          return {
            ...state,
            value: 'error',
            error: event.error,
          };
        }

        default:
          return state;
      }
    }

    default:
      return state;
  }
};

/* -----------------------------------------------------------------------------
 * Utils
 * ---------------------------------------------------------------------------*/

// Maps the api keys from google to our form fields
const addressComponents = new Map([
  ['street_address', 'StreetName'],
  ['route', 'StreetName'],
  ['street_number', 'StreetNumber'],
  ['locality', 'City'],
  ['country', 'Country'],
  ['postal_code', 'PostalCode'],
]);

// Parses a Geocoding result returned by the google maps api
function parseGeoCodingResult(
  result: google.maps.GeocoderResult,
  prefix: string
) {
  const results: Record<string, FormDataEntry> = {};

  result.address_components.forEach((addressComponent) => {
    addressComponent.types.forEach((type) => {
      if (addressComponents.has(type)) {
        const key = `${prefix}${addressComponents.get(type)}`;

        // Add the address-component to the results
        results[key] = {
          inputValue: addressComponent.long_name,
          type: 'STRING',
        };
      }
    });
  });

  // Add the coordinates to the result
  results[`${prefix}LocationLat`] = {
    inputValue: result.geometry.location.lat().toString(),
    type: 'STRING',
  };
  results[`${prefix}LocationLng`] = {
    inputValue: result.geometry.location.lng().toString(),
    type: 'STRING',
  };

  return results;
}

/* -----------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------*/

const LocationLabel = styled.span(({ theme }) => ({
  borderRadius: '3px',
  backgroundColor: theme.colors['gray-300'],
  color: theme.colors['gray-700'],
  fontWeight: 600,
  fontSize: '11px',
  letterSpacing: '0.06em',
  padding: '4px 6px',
  display: 'inline-block',
  marginRight: '6px',
}));

const LocationValue = styled.span(({ theme }) => ({
  color: theme.colors['gray-700'],
  fontSize: '11px',
}));

const mapButtonstyle = css(({ theme }) => ({
  backgroundColor: '#ffffff',
  border: `1px solid ${theme.colors['gray-300']}`,
  padding: '6px 8px',
  color: theme.colors['gray-700'],

  '&:hover': {
    backgroundColor: theme.colors['gray-100'],
  },
}));

/* -----------------------------------------------------------------------------
 * ValidatedLocationValue
 * ---------------------------------------------------------------------------*/

const ValidatedLocationValue = ({
  input: { value },
}: FieldRenderProps<string, HTMLElement>) => {
  return <LocationValue>{value}</LocationValue>;
};

/* -----------------------------------------------------------------------------
 * ValidationStatus
 * ---------------------------------------------------------------------------*/

interface ValidationStatusProps {
  uiState: AddressValidationState;
  prefix: string;
}

const ValidationStatus = ({ uiState, prefix }: ValidationStatusProps) => {
  switch (uiState.value) {
    case 'error':
      return <div>Es wurde keine Adresse gefunden</div>;

    case 'success': {
      return (
        <>
          <div>
            <LocationLabel>Lat</LocationLabel>
            <InputValueFieldMapper
              name={`${prefix}LocationLat`}
              component={ValidatedLocationValue}
            />
          </div>
          <div>
            <LocationLabel>Lng</LocationLabel>
            <InputValueFieldMapper
              name={`${prefix}LocationLng`}
              component={ValidatedLocationValue}
            />
          </div>
        </>
      );
    }
  }

  return null;
};

/* -----------------------------------------------------------------------------
 * AddressValidation
 * ---------------------------------------------------------------------------*/

const initialState: AddressValidationState = {
  value: 'idle',
  addressStringToValidate: undefined,
  error: undefined,
};

interface AddressValidationProps {
  suggestions: GroupItemResult[];
  focusHighlight: (id: string) => void;
  name: string;
}

export const AddressValidation = ({
  suggestions,
  focusHighlight,
  name,
}: AddressValidationProps) => {
  const form = useForm();
  const googleMapsClient = useGoogleMapsClient();
  const googleMapsGeocoder = useMemo<google.maps.Geocoder | null>(() => {
    if (googleMapsClient) {
      return new googleMapsClient.maps.Geocoder();
    }

    return null;
  }, [googleMapsClient]);
  const [state, dispatch] = useEffectReducer(
    addressValidationReducer,
    initialState,
    {
      replaceFormValuesWithGeocodeResult: (_, effect) => {
        const updatedFormDataEntry = parseGeoCodingResult(
          effect.geocoderResult,
          name
        );
        form.mutators.setAddressData(name, updatedFormDataEntry);
      },
      validateWithGeocode: (_, effect, dispatch) => {
        if (googleMapsGeocoder === null) {
          dispatch({
            type: 'VALIDATION_FAILED',
            error: 'Google Maps client wurde nicht geladen',
          });
          return;
        }

        googleMapsGeocoder.geocode(
          { address: effect.addressStringToValidate },
          (results, status) => {
            // const status = (_status as unknown) as string;
            if (status !== google.maps.GeocoderStatus.OK) {
              dispatch({
                type: 'VALIDATION_FAILED',
                error: status.toString(),
              });
              return;
            }

            if (results && results.length > 0) {
              dispatch({
                type: 'VALIDATION_SUCCESS',
                geocoderResult: results[0],
              });
            } else {
              dispatch({
                type: 'VALIDATION_FAILED',
                error: 'Es wurden keine Ergebnisse gefunden',
              });
            }
          }
        );
      },
    }
  );
  const { send: changeViewer } = useViewerSwitcher('AddressValidation');
  const isLoading = state.value === 'validating';

  return (
    <Box bg="gray-200" p={2}>
      <Box mb={2}>
        <AddressValidationSelect
          suggestions={suggestions}
          onSelectedItemChange={(addressStringToValidate) => {
            dispatch({
              type: 'START_VALIDATION',
              addressStringToValidate,
            });
          }}
          focusHighlight={focusHighlight}
          placeholder="Adresse auswählen..."
        />
      </Box>

      <Flex>
        <FlexChild flex={1}>
          <InputValueFieldMapper
            component={TextLabelFloat}
            label="Straße"
            name={`${name}StreetName`}
            randomizeInputName
          />
        </FlexChild>

        <FlexChild flexBasis="100px">
          <InputValueFieldMapper
            component={TextLabelFloat}
            label="Nr."
            name={`${name}StreetNumber`}
            randomizeInputName
          />
        </FlexChild>
      </Flex>

      <InputValueFieldMapper
        component={TextLabelFloat}
        label="PLZ"
        name={`${name}PostalCode`}
        randomizeInputName
      />

      <InputValueFieldMapper
        component={TextLabelFloat}
        label="Stadt"
        name={`${name}City`}
        randomizeInputName
      />

      <InputValueFieldMapper
        component={TextLabelFloat}
        label="Land"
        name={`${name}Country`}
        randomizeInputName
      />

      <Box pt={2}>
        <FormLabel>Koordinaten</FormLabel>
        <Flex>
          <FlexChild>
            <ValidationStatus uiState={state} prefix={name} />
          </FlexChild>
          <FlexChild ml="auto">
            <Flex>
              {state.value === 'success' ? (
                <FlexChild mr={1}>
                  <ActionButton
                    icon
                    iconPosition="left"
                    overrides={{
                      Root: {
                        style: mapButtonstyle,
                      },
                      Icon: {
                        props: {
                          name: 'location',
                          fill: 'gray-600',
                        },
                      },
                    }}
                    onClick={() => {
                      const { values } = form.getState();
                      changeViewer({
                        type: 'TOGGLE_MAP',
                        props: {
                          lat: values[`${name}LocationLat`].inputValue,
                          lng: values[`${name}LocationLng`].inputValue,
                        },
                      });
                    }}
                  >
                    Karte
                  </ActionButton>
                </FlexChild>
              ) : null}
              <FlexChild>
                <ActionButtonHighlight
                  loading={isLoading}
                  onClick={() => {
                    const { values } = form.getState();

                    // Send the data from the input to the geocoding API
                    const addressStringToValidate = [
                      values[`${name}StreetName`].inputValue,
                      values[`${name}StreetNumber`].inputValue,
                      values[`${name}PostalCode`].inputValue,
                      values[`${name}City`].inputValue,
                      values[`${name}Country`].inputValue,
                    ].join(' ');

                    dispatch({
                      type: 'START_VALIDATION',
                      addressStringToValidate,
                    });
                  }}
                >
                  Validieren
                </ActionButtonHighlight>
              </FlexChild>
            </Flex>
          </FlexChild>
        </Flex>
      </Box>
    </Box>
  );
};
