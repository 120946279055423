import * as React from 'react';
import { useState, useEffect } from 'react';

import { createContext } from '../react-utils';
import { useScript } from './use-script';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const GOOGLE_API_CLIENT_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&language=de`;

type GoogleMapsClientValue = typeof google | null;

type GoogleMapsContextValue = {
  googleMapsClient: GoogleMapsClientValue;
};

const [GoogleMapsContextProvider, useGoogleMapsContext] = createContext<
  GoogleMapsContextValue
>('GoogleMaps');

const GoogleMapsClient = ({ children }: React.PropsWithChildren) => {
  const [googleMapsClient, setGoogleMapsClient] = useState<
    GoogleMapsClientValue
  >(null);
  const [scriptLoaded] = useScript(GOOGLE_API_CLIENT_URL);
  useEffect(() => {
    setGoogleMapsClient(window.google);
  }, [scriptLoaded]);

  return (
    <GoogleMapsContextProvider googleMapsClient={googleMapsClient}>
      {children}
    </GoogleMapsContextProvider>
  );
};

function useGoogleMapsClient(): GoogleMapsClientValue {
  return useGoogleMapsContext('useGoogleMapsClient').googleMapsClient;
}

export { GoogleMapsClient, useGoogleMapsClient };
