import styled from 'styled-components';

interface StyledContentProps {
  minWidth?: number | string;
}

const AnchoredOverlay = styled.div<StyledContentProps>((props) => ({
  minWidth: props.minWidth,
  backgroundColor: 'white',
  borderRadius: '6px',
  padding: '4px',
  marginTop: '0px',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 16px',
}));

export { AnchoredOverlay };
