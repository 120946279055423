import * as React from 'react';
import styled from 'styled-components';
import produce from 'immer';
import uuid from 'uuid/v1';

import { Fixed } from '../layout';
import Notification from './notification';

// How long the notification should be visible on the screen
const HIDE_NOTIFICATION_AFTER_SEC = 5;

////////////////////////////////////////////////////////////////////////
// React Context
type ActionCreate = {
  type: 'CreateNotification';
  args: { title: string; id: string };
};
type ActionDelete = { type: 'DeleteNotification'; args: { id: string } };
type Action = ActionCreate | ActionDelete;
type Dispatch = (action: Action) => void;
type NotificationContent = {
  id: string;
  title: string;
};
type NotificationState = {
  notifications: NotificationContent[];
  count: number;
};

const NotificationStateContext = React.createContext<
  NotificationState | undefined
>(undefined);
const NotificationDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

// Reducer
function notificationReducer(
  state: NotificationState,
  action: Action
): NotificationState {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'CreateNotification': {
        const newNotification = {
          id: action.args.id,
          title: action.args.title,
        };

        draft.notifications.push(newNotification);
        break;
      }

      case 'DeleteNotification': {
        const { id } = (action as ActionDelete).args;

        const indexToDelete = state.notifications.findIndex(
          (notification) => notification.id === id
        );

        draft.notifications.splice(indexToDelete, 1);
        break;
      }

      default: {
        // @ts-ignore
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  });
}

// Provider
function NotificationProvider({ children }: React.PropsWithChildren) {
  const [state, dispatch] = React.useReducer(notificationReducer, {
    notifications: [],
    count: 0,
  });

  return (
    <NotificationStateContext.Provider value={state}>
      <NotificationDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
}

function useNotification() {
  const context = React.useContext(NotificationStateContext);
  const dispatch = React.useContext(NotificationDispatchContext);
  if (context === undefined || dispatch === undefined) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }

  return {
    createNotification(title: string) {
      const id = uuid();

      dispatch({
        type: 'CreateNotification',
        args: { title, id },
      });

      // After timeout delete the notification from the stack
      setTimeout(() => {
        dispatch({
          type: 'DeleteNotification',
          args: { id },
        });
      }, HIDE_NOTIFICATION_AFTER_SEC * 1000);
    },
  };
}

////////////////////////////////////////////////////////////////////////
// Display Notification
const NotificationContainer = styled(Fixed)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const DisplayNotifications = () => {
  const context = React.useContext(NotificationStateContext);
  if (context === undefined) {
    throw new Error('Notifications must be used within a NotificationProvider');
  }

  return (
    <NotificationContainer bottom={12} left={0} right={0}>
      {context.notifications.map((notification) => (
        <Notification key={notification.id} title={notification.title} />
      ))}
    </NotificationContainer>
  );
};

export { NotificationProvider, useNotification, DisplayNotifications };
