import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Ticket, TicketVariables } from '../../types/apollo/Ticket';

const TICKET_QUERY = gql`
  query Ticket($ticketId: ID!) {
    ticket(ticketId: $ticketId)
    @rest(type: "Ticket", path: "tickets/{args.ticketId}/") {
      id
      title
      status
      attachments @type(name: "Attachment") {
        id
        file
        fileType
        fileName
      }
      createdBy @type(name: "User") {
        id
        username
        firstName
        lastName
      }
    }
  }
`;

class TicketQuery extends Query<Ticket, TicketVariables> {}

export { TICKET_QUERY, TicketQuery };
