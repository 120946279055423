import React from 'react';
import styled from 'styled-components';
import { getColor } from '../layout/styled-helpers';

enum BadgeKind {
  neutral,
  negative,
}

type BadgeKindKeys = keyof typeof BadgeKind;

const Root = styled.button<{
  badgeKind: BadgeKind;
}>`
  background-color: white;
  display: inline-block;
  text-transform: uppercase;
  border: 1px solid ${getColor('red-300')};
  font-size: 10px;
  padding: 2px 4px;
  letter-spacing: 0.08em;
  color: ${getColor('red-500')};
  font-weight: bold;
`;

interface StatusBadgeProps {
  onClick: (e: React.SyntheticEvent) => void;
  kind?: BadgeKindKeys;
  children: string;
  title?: string;
}

export const StatusBadge = ({
  kind = 'neutral',
  title,
  onClick,
  children,
}: StatusBadgeProps) => {
  const badgeKind = BadgeKind[kind];

  return (
    <Root onClick={onClick} title={title} badgeKind={badgeKind}>
      {children}
    </Root>
  );
};
