import * as React from 'react';
import styled from 'styled-components';

import { Flex, FlexChild } from '../layout';
import { getSpace } from '../layout/styled-helpers';
import Icon, { IconSize, IconsSmall } from '../icon';

const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.16);
  padding: ${getSpace(1, 2, 2)};
  border: none;

  &:first-child {
    border-radius: 6px 0 0 6px;
    border-right: 1px solid rgba(255, 255, 255, 0.16);
  }

  &:last-child {
    border-radius: 0 6px 6px 0;
  }
`;

const ItemCounter = styled.div`
  color: #c4c4c7;
  font-size: 14px;
`;

const Bold = styled.span`
  font-weight: bold;
  color: #ffffff;
`;

interface Props {
  left?: React.ReactNode;
  requestNext: () => void;
  requestPrevious: () => void;
  previousActive: boolean;
  nextActive: boolean;
  offset: number;
  limit: number;
  total: number;
}

const PaginationNavigation = ({
  requestNext,
  requestPrevious,
  nextActive,
  previousActive,
  total,
  offset,
  limit,
  left,
}: Props) => (
  <Flex>
    {left ? <FlexChild>{left}</FlexChild> : null}
    <FlexChild ml="auto">
      <Flex alignItems="baseline">
        <FlexChild pr={3}>
          <ItemCounter>
            <Bold>
              {offset} - {limit}
            </Bold>{' '}
            von <Bold>{total}</Bold>
          </ItemCounter>
        </FlexChild>

        <FlexChild>
          <Button
            onClick={requestPrevious}
            disabled={!previousActive}
            title="Vorige"
          >
            <Icon
              size={IconSize.small}
              name={IconsSmall.arrowLeft}
              fill={previousActive ? 'gray-300' : 'gray-600'}
            />
          </Button>
          <Button onClick={requestNext} disabled={!nextActive} title="Nächste">
            <Icon
              size={IconSize.small}
              name={IconsSmall.arrowRight}
              fill={nextActive ? 'gray-300' : 'gray-600'}
            />
          </Button>
        </FlexChild>
      </Flex>
    </FlexChild>
  </Flex>
);

export default PaginationNavigation;
