import * as React from 'react';
import { useMemo } from 'react';

import type { SelectAnchorProps } from '../../design-system/SelectPanel';
import { SelectPanel } from '../../design-system/SelectPanel';

import {
  AREA_DIMENSION_UNIT,
  CURRENCY_UNIT,
} from './form-input-unit-select.constants';
import { useControllableState } from '../react-utils';
import { DropdownButton } from '../dropdown-button/dropdown-button';

import type { UnitType } from './form-input-unit-select.types';

/* -----------------------------------------------------------------------------
 * FormInputUnitSelect
 * ---------------------------------------------------------------------------*/

function getDisplayValue(type: UnitType, value?: string) {
  if (value) {
    if (type === 'AREA_DIMENSION') {
      const result = AREA_DIMENSION_UNIT.find((unit) => unit.id === value);

      if (result !== undefined) {
        return result.label;
      }
    }

    if (type === 'CURRENCY') {
      const result = CURRENCY_UNIT.find((unit) => unit.id === value);

      if (result !== undefined) {
        return result.label;
      }
    }
  }

  return null;
}

interface FormInputUnitSelectProps {
  hasError?: boolean;
  type: UnitType;
  readOnly?: boolean;
  open?: boolean;
  placeholder?: string;
  value: string;
  onChange(value: string): void;
  onOpenChange?(isOpen: boolean): void;
  onFocus?(): void;
}

const FormInputUnitSelect = ({
  hasError,
  type,
  readOnly,
  open: externalOpen,
  placeholder = '--',
  value: fieldValue,
  onChange,
  onFocus,
  onOpenChange,
}: FormInputUnitSelectProps) => {
  const [open, setOpen] = useControllableState({
    prop: externalOpen,
    defaultProp: false,
    onChange: onOpenChange,
  });
  const [value, setValue] = useControllableState<string>({
    prop: fieldValue,
    defaultProp: '',
    onChange,
  });

  const items = useMemo(() => {
    switch (type) {
      case 'AREA_DIMENSION':
        return AREA_DIMENSION_UNIT;

      case 'CURRENCY':
        return CURRENCY_UNIT;
    }

    return [];
  }, [type]);

  const renderMenuAnchor = useMemo(() => {
    const _displayValue = getDisplayValue(type, value);
    const displayValue = _displayValue ?? placeholder;

    return (anchorProps: SelectAnchorProps) => (
      <DropdownButton
        disabled={readOnly}
        hasError={hasError}
        onFocus={anchorProps.onFocus}
      >
        {displayValue}
      </DropdownButton>
    );
  }, [hasError, readOnly, type, value, placeholder]);

  return (
    <SelectPanel
      items={items}
      open={open!}
      onOpenChange={setOpen}
      placeholderText="Filter"
      onFocus={onFocus}
      onSelectedChange={(item) => {
        setValue(item.id);
      }}
      renderAnchor={renderMenuAnchor}
    />
  );
};

export type { FormInputUnitSelectProps };
export { FormInputUnitSelect };
