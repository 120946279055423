import styled from 'styled-components';

import { FlexChild } from '../../layout';

export const ModalBodyWrapper = styled(FlexChild)({
  backgroundColor: '#ffffff',
  borderRadius: 3,
  marginTop: '12px',
  minWidth: '500px',
});
