import * as React from 'react';
import styled from 'styled-components';
import {
  Map,
  IProvidedProps as ProvidedProps,
  Marker,
} from 'google-maps-react';

import { useViewerSwitcher } from '../../../components/viewer-switcher';
import { MediumIconButton } from '../../../components/button/icon';
import { Box } from '../../../components/layout';
import { useGoogleMapsClient } from '../../../components/google-maps-client';

const Header = styled.div(({ theme }) => ({
  height: '52px',
  width: '100%',
  backgroundColor: theme.colors['gray-800'],
  display: 'flex',
}));

const Viewer = styled.div({
  flex: '1',
  position: 'relative',
});

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.colors['gray-800'],
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
}));

type MapContainerProps = ProvidedProps & {
  lat: string;
  lng: string;
};

const MapContainer = ({ lat, lng, google }: MapContainerProps) => {
  const { state } = useViewerSwitcher('MapContainer');

  if (state.type !== 'map') {
    throw new Error(
      `MapContainer is opened with the wrong viewer-type: ${state.type}`
    );
  }

  const parsedLat = parseFloat(lat);
  const parsedLng = parseFloat(lng);

  return (
    // @ts-ignore - React 18 incompatible types
    <Map
      google={google}
      initialCenter={{
        lat: parsedLat,
        lng: parsedLng,
      }}
      zoom={15}
    >
      <Marker />
    </Map>
  );
};

const ViewerMap = () => {
  const { state, send } = useViewerSwitcher('ViewerMap');
  const googleMapsClient = useGoogleMapsClient();

  if (state.type !== 'map') {
    throw new Error(
      `ViewerMap is called with the wrong viewer-type: ${state.type}`
    );
  }

  if (!googleMapsClient) {
    // Google Maps initialization pending
    return null;
  }

  return (
    <Container>
      <Header>
        <Box ml="auto" py={3}>
          <MediumIconButton
            title="Karte schließen"
            iconName="cross"
            fill="gray-100"
            onClick={() => {
              send({ type: 'SHOW_PDF' });
            }}
          />
        </Box>
      </Header>
      <Viewer>
        {googleMapsClient ? (
          <MapContainer
            google={googleMapsClient}
            lat={state.props.lat}
            lng={state.props.lng}
          />
        ) : (
          <Box color="white">Google Maps konnte nicht geladen werden!</Box>
        )}
      </Viewer>
    </Container>
  );
};

export { ViewerMap };
