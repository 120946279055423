import * as React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import { Trigger as SelectMenuTrigger } from '../../design-system/SelectMenu';

/* -----------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------*/

const StyledSelectButton = styled(SelectMenuTrigger)(({ theme }) => ({
  borderRadius: '4px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  border: `1px solid ${theme.colors['gray-300']}`,
  backgroundColor: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  padding: '6px 8px',
  fontSize: '12px',
  fontWeight: 600,
  color: '#000000',
  cursor: 'pointer',

  '&:disabled': {
    pointerEvents: 'none',
  },

  '&.error': {
    backgroundColor: 'rgb(223, 0, 0)',
    color: '#ffffff',
  },
}));

const StyledArrowDown = styled.span(({ theme }) => ({
  marginTop: '0px',
  width: 0,
  height: 0,
  borderLeft: '4px solid transparent',
  borderRight: '4px solid transparent',
  borderTop: `4px solid ${theme.colors['gray-600']}`,
  marginLeft: '8px',

  '.error > &': {
    borderTopColor: '#ffffff',
  },
}));

/* -----------------------------------------------------------------------------
 * DropdownButton
 * ---------------------------------------------------------------------------*/

interface DropdownButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  hasError?: boolean;
}

const DropdownButton = ({
  children,
  disabled,
  hasError,
  ...restProps
}: DropdownButtonProps) => {
  return (
    <StyledSelectButton
      {...restProps}
      disabled={disabled}
      className={cn({ error: hasError })}
    >
      {children}
      {disabled ? null : <StyledArrowDown />}
    </StyledSelectButton>
  );
};

export { DropdownButton };
