/**
 * Root-Router for the project
 *
 * Contains all routes in the appliaction
 */

import * as React from 'react';
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
  RouteComponentProps,
} from 'react-router-dom';

import {
  CurrentUserProvider,
  useCurrentUser,
} from '../components/current-user';
import {
  withBaseLayoutNoPadding,
  withNoHeaderLayout,
  withBaseLayout,
} from '../components/layout/layouts';

// Route-Components
import { DashboardView } from './dashboard';
import { NewTicketsOverview } from './tickets/new';
import { AnalysisTicketsOverview } from './tickets/analysis';
import { ReviewTicketsOverview } from './tickets/review';
import { FinishedTicketsOverview } from './tickets/finished';
import ViewLogin from './login';
import ViewEditor from './tickets/editor';
import { EditTicketView } from './tickets/edit';
import { CallBackView } from './login/callback';

////////////////////////////////////////////////////////////////////////
// High order component which redirects to login when the user is not logged in
const logoutFallback = (Component: React.ComponentType<any>) => (
  props: RouteComponentProps
) => {
  const { status } = useCurrentUser();

  if (status !== 'LoggedIn') {
    return <Redirect to="/login" />;
  }

  return <Component {...props} />;
};

////////////////////////////////////////////////////////////////////////
// Ticket routes
const TicketRoutes = () => (
  <Switch>
    {/* Overview and Dashboard */}
    <Route path="/tickets/new" component={withBaseLayout(NewTicketsOverview)} />
    <Route
      path="/tickets/analysis"
      component={withBaseLayout(AnalysisTicketsOverview)}
    />
    <Route
      path="/tickets/review"
      component={withBaseLayout(ReviewTicketsOverview)}
    />
    <Route
      path="/tickets/finished"
      component={withBaseLayout(FinishedTicketsOverview)}
    />

    {/* Ticket details */}
    <Route
      path="/tickets/:ticketId/review"
      component={withNoHeaderLayout(ViewEditor)}
    />
    <Route
      path="/tickets/:ticketId/edit"
      component={withBaseLayout(EditTicketView)}
    />

    {/* Index route */}
    <Route path="/" component={withBaseLayout(DashboardView)} />
  </Switch>
);

////////////////////////////////////////////////////////////////////////
// Router
const Router = () => (
  <BrowserRouter>
    <CurrentUserProvider>
      <Switch>
        {/* User actions */}
        <Route
          path="/callback"
          component={withBaseLayoutNoPadding<RouteComponentProps>(CallBackView)}
        />
        <Route
          path="/login"
          component={withBaseLayoutNoPadding<RouteComponentProps>(ViewLogin)}
        />

        <Route path="/" component={logoutFallback(TicketRoutes)} />
      </Switch>
    </CurrentUserProvider>
  </BrowserRouter>
);

export default Router;
