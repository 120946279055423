/**
 * Textfield with external label (above the text input)
 */

import * as React from 'react';
import styled from 'styled-components';
import { FieldRenderProps } from 'react-final-form';
import { getColor } from '../layout/styled-helpers';

export const ExternalLabel = styled.label`
  & > .label {
    color: #7d7d7d;
    display: inline-block;
    margin-bottom: 8px;
    font-size: 14px;
    letter-spacing: 0.06em;
  }

  & > .error-message {
    color: ${getColor('red-500')};
    font-size: 12px;
    margin-top: 4px;
  }
`;

const TextField = styled.input`
  width: 100%;
  border: 1px solid #dbdbdb;
  padding: 11px 12px 12px;

  &.error {
    border-color: ${getColor('red-500')};
  }
`;

interface Props extends FieldRenderProps<any, HTMLElement> {
  label?: string;
  placeholder?: string;
  errorMessage?: string;
}

export const TextLabelExternal = (props: Props) => {
  const hasError = props.meta.error || props.meta.submitError;
  return (
    <ExternalLabel>
      {props.label ? <span className="label">{props.label}</span> : null}
      <TextField
        {...props.input}
        className={hasError ? 'error' : ''}
        placeholder={props.placeholder}
        autoComplete="off"
      />
      {hasError ? (
        <span className="error-message">
          {props.meta.error || props.meta.submitError}
        </span>
      ) : null}
    </ExternalLabel>
  );
};
