/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AttachmentType {
  pdf = 'pdf',
}

export enum AttachmentUploadStatus {
  OK = 'OK',
  WARNING = 'WARNING',
}

export enum EstateAttributeProperty {
  address = 'address',
  area = 'area',
  company_contact_name = 'company_contact_name',
  company_name = 'company_name',
  construction_year = 'construction_year',
  email = 'email',
  hotel_rooms = 'hotel_rooms',
  occupancy = 'occupancy',
  parking_spaces = 'parking_spaces',
  phonenumber = 'phonenumber',
  property_use = 'property_use',
  rental_cost = 'rental_cost',
}

export enum TicketActionType {
  cancel_analysis = 'cancel_analysis',
  skip_analysis = 'skip_analysis',
  start_analysis = 'start_analysis',
  start_review = 'start_review',
  validate_result = 'validate_result',
}

export enum TicketStatus {
  analysis = 'analysis',
  archive = 'archive',
  done = 'done',
  draft = 'draft',
  failed = 'failed',
  review = 'review',
}

export interface CreateTicketInput {
  title: string;
}

export interface SubmitTicketInput {
  companyName: SubmitTicketInputStringValue;
  companyStreetName: SubmitTicketInputStringValue;
  companyStreetNumber: SubmitTicketInputStringValue;
  companyCity: SubmitTicketInputStringValue;
  companyPostalCode: SubmitTicketInputStringValue;
  companyCountry: SubmitTicketInputStringValue;
  companyLocationLat: SubmitTicketInputNumberValue;
  companyLocationLng: SubmitTicketInputNumberValue;
  companyEmail: SubmitTicketInputStringValue;
  companyPhonenumber: SubmitTicketInputStringValue;
  companyContactName: SubmitTicketInputStringValue;
  propertyName: SubmitTicketInputStringValue;
  propertyStreetName: SubmitTicketInputStringValue;
  propertyStreetNumber: SubmitTicketInputStringValue;
  propertyCity: SubmitTicketInputStringValue;
  propertyPostalCode: SubmitTicketInputStringValue;
  propertyCountry: SubmitTicketInputStringValue;
  propertyLocationLat: SubmitTicketInputNumberValue;
  propertyLocationLng: SubmitTicketInputNumberValue;
  propertyUse: SubmitTicketInputStringValue;
  propertyConstructionYear: SubmitTicketInputNumberValue;
  propertyLandArea: SubmitTicketInputNumberValue;
  propertyLettableArea: SubmitTicketInputNumberValue;
  propertyParkingSpaces: SubmitTicketInputNumberValue;
  propertyHotelRooms: SubmitTicketInputNumberValue;
  propertyOccupancy: SubmitTicketInputNumberValue;
  propertyNetIncome: SubmitTicketInputNumberValue;
  propertyGrossIncome: SubmitTicketInputNumberValue;
  ticketAreaUnit: string;
  ticketCurrencyUnit: string;
}

export interface SubmitTicketInputNumberValue {
  stringValue: string;
  numberValue: number;
}

export interface SubmitTicketInputStringValue {
  stringValue: string;
}

export interface UserLoginInput {
  username: string;
  password: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
