import styled from 'styled-components';

import { FullHeight } from '../block';
import backgroundImage from '../assets/yuna-bg-main.jpg';

// Placeholder for background-image
export const BackgroundImage = styled(FullHeight)(({ theme }) => ({
  background: theme.colors.bg.main,
  backgroundImage:
    process.env.REACT_APP_THEME === 'yuna'
      ? `url(${backgroundImage})`
      : undefined,
  backgroundSize: 'cover',
}));
