/**
 * Builds a viewer for the images from the pdf files
 */

import * as React from 'react';
import styled from 'styled-components';
import { Document, Viewer, SnipEvent } from '@eoda-intern/react-pdfjs';

import store from '../../redux/store';
import PDFToolbar from './pdf-toolbar';
import AnnotationLayer from './annotation-layer';

import '@eoda-intern/react-pdfjs/style.css';
import { EditorMode } from '../../views/tickets/editor/editor';

// See scripts/copy.js in project root
const pathToPDFWorker = '/assets/pdf.worker.min.js';

const ViewerContainer = styled.div`
  flex: 1;
  position: relative;
  background-color: #333;
`;

type PDFViewerProps = {
  source: string;
  highlights: Array<{
    x: number;
    y: number;
    width: number;
    height: number;
  }>;
  onSnippetCreated: (event: SnipEvent) => void;
  onEditorModeChanged: (mode: EditorMode) => void;
};

const PDFViewer = React.forwardRef<unknown, PDFViewerProps>(
  (props, forwardedRef) => {
    const viewerRef = React.useRef();
    React.useImperativeHandle(forwardedRef, () => ({
      goToPage: (pageNumber: number, rect: any) => {
        // @ts-ignore
        viewerRef.current.scrollPageIntoView(pageNumber, rect);
      },
      setEditorMode(mode: EditorMode) {
        // @ts-ignore
        viewerRef.current.setEditorMode(mode);
      },
    }));

    return (
      <Document
        // @ts-ignore
        annotations={props.highlights}
        // @ts-ignore
        annotationsForPage={(pageNumber: number) =>
          props.highlights[pageNumber]
        }
        workerSrc={pathToPDFWorker}
        file={props.source}
      >
        <PDFToolbar />
        <ViewerContainer>
          <Viewer
            onSnippetCreated={props.onSnippetCreated}
            onEditorModeChanged={props.onEditorModeChanged}
            // @ts-ignore
            ref={viewerRef}
            // @ts-ignore
            renderAnnotation={(props) => (
              <AnnotationLayer {...props} store={store} />
            )}
            snippetMinHeight={16}
            snippetMinWidth={16}
          />
        </ViewerContainer>
      </Document>
    );
  }
);

export default PDFViewer;
