import * as React from 'react';

import { TextLabelFloatComboBox as InputAutocomplete } from '../../../../components/input/text-label-float-combobox';
import { TextLabelFloat } from '../../../../components/input/text-label-float';
import { FormGroup } from './style';
import { GroupedSuggestions } from '../../../../components/form-data-loader';
import { AddressValidation } from '../../../../components/address-validation';
import { formLabelTranslations } from '../../../../translations/form-labels';
import {
  InputValueFieldMapper,
  InputValueFieldMapperWithUnit,
} from '../../../../components/form-data-field-mapper';
import { usePropertyUseSuggestions } from '../../../../components/form-data-loader/use-property-use-suggestions';

interface Props {
  suggestions: GroupedSuggestions;
  focusHighlight: (id: string) => void;
}

const Form = ({ suggestions, focusHighlight }: Props) => {
  const propertyUseSuggestions = usePropertyUseSuggestions(
    suggestions['property_use']
  );

  return (
    <React.Fragment>
      <FormGroup title="Kontakt">
        <InputValueFieldMapper
          component={InputAutocomplete}
          items={suggestions['company_name']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.companyName}
          name="companyName"
        />

        <AddressValidation
          suggestions={suggestions['address']}
          focusHighlight={focusHighlight}
          name="company"
        />

        <InputValueFieldMapper
          component={InputAutocomplete}
          items={suggestions['company_contact_name']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.companyContactName}
          name="companyContactName"
        />

        <InputValueFieldMapper
          component={InputAutocomplete}
          items={suggestions['email']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.companyEmail}
          name="companyEmail"
        />

        <InputValueFieldMapper
          component={InputAutocomplete}
          items={suggestions['phonenumber']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.companyPhonenumber}
          name="companyPhonenumber"
        />
      </FormGroup>

      <FormGroup title="Objekt / Gebäude">
        <InputValueFieldMapper
          component={TextLabelFloat}
          label={formLabelTranslations.propertyName}
          name="propertyName"
          randomizeInputName
        />

        <AddressValidation
          suggestions={suggestions['address']}
          focusHighlight={focusHighlight}
          name="property"
        />

        <InputValueFieldMapperWithUnit
          items={suggestions['rental_cost']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.propertyGrossIncome}
          name="propertyGrossIncome"
          unitType="CURRENCY"
        />

        <InputValueFieldMapperWithUnit
          items={suggestions['rental_cost']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.propertyNetIncome}
          name="propertyNetIncome"
          unitType="CURRENCY"
        />

        <InputValueFieldMapperWithUnit
          items={suggestions['area']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.propertyLandArea}
          name="propertyLandArea"
          unitType="AREA_DIMENSION"
        />

        <InputValueFieldMapperWithUnit
          items={suggestions['area']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.propertyLettableArea}
          name="propertyLettableArea"
          unitType="AREA_DIMENSION"
        />

        <InputValueFieldMapperWithUnit
          items={suggestions['occupancy']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.propertyOccupancy}
          name="propertyOccupancy"
          unitType="PERCENTAGE"
          unitPlaceholder="%"
          unitReadOnly
        />

        <InputValueFieldMapper
          component={InputAutocomplete}
          items={propertyUseSuggestions}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.propertyUse}
          name="propertyUse"
        />

        <InputValueFieldMapper
          component={InputAutocomplete}
          items={suggestions['parking_spaces']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.propertyParkingSpaces}
          name="propertyParkingSpaces"
        />

        <InputValueFieldMapper
          component={InputAutocomplete}
          items={suggestions['hotel_rooms']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.propertyHotelRooms}
          name="propertyHotelRooms"
        />

        <InputValueFieldMapper
          component={InputAutocomplete}
          items={suggestions['construction_year']}
          onHighlightChange={focusHighlight}
          label={formLabelTranslations.propertyConstructionYear}
          name="propertyConstructionYear"
        />
      </FormGroup>
    </React.Fragment>
  );
};

export default Form;
