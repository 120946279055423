/**
 * Custom apollo link to handle the upload of files
 * Most of the code is taken from here but customized for the django-backend:
 * https://github.com/jaydenseric/apollo-upload-client
 */

import {
  ApolloLink,
  Observable,
  Operation,
  NextLink,
  FetchResult,
} from 'apollo-link';
import {
  selectURI,
  selectHttpOptionsAndBody,
  fallbackHttpConfig,
  createSignalIfSupported,
} from 'apollo-link-http-common';
import { snakeCase } from 'lodash-es';
import camelcaseKeys from 'camelcase-keys';
import { GraphQLError } from 'graphql';

export class UploadLink extends ApolloLink {
  private linkConfig: any;
  private fetchUri: string;

  constructor({
    uri: fetchUri = '',
    fetchOptions,
    credentials,
    headers,
    includeExtensions,
  }: any = {}) {
    super();

    this.fetchUri = fetchUri;
    this.linkConfig = {
      http: { includeExtensions },
      options: fetchOptions,
      credentials,
      headers,
    };
  }

  public request(
    operation: Operation,
    forward?: NextLink
  ): Observable<FetchResult> | null {
    let uri = selectURI(operation, this.fetchUri);
    const context = operation.getContext();

    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: context.headers,
    };

    const { options, body } = selectHttpOptionsAndBody(
      operation,
      fallbackHttpConfig,
      this.linkConfig,
      contextConfig
    );

    if (body.variables) {
      // Check for variabable file
      const { file, input, ticketId } = body.variables;

      if (file) {
        // Automatically set by fetch when the body is a FormData instance.
        delete options.headers['content-type'];
        uri += `${ticketId}/add_attachment/`;

        const form = new FormData();
        form.append('file', file);

        // Append all other values from the input variable
        if (input) {
          Object.keys(input).forEach((key) => {
            form.append(snakeCase(key), input[key]);
          });
        }

        options.body = form;
      }
    }

    return new Observable((observer) => {
      // Allow aborting fetch, if supported.
      const { controller, signal } = createSignalIfSupported();
      if (controller) options.signal = signal;

      fetch(uri, options)
        .then((response: any) => {
          if (response.status < 200 || response.status > 300) {
            throw response;
          }

          // Forward the response on the context.
          operation.setContext({ response });
          return response;
        })
        .then((response: any) => response.text())
        .then((response: any) => JSON.parse(response))
        .then((result: any) => {
          // Manually patch the types
          // TODO: Find solution to use the annotation feature from apollo-link-rest
          const data = camelcaseKeys(result, { deep: true });
          data['__typename'] = 'AttachmentPayload';

          if (data['attachment']) {
            data['attachment']['__typename'] = 'Attachment';
          }
          if (data['duplicates'] && Array.isArray(data['duplicates'])) {
            data['duplicates'] = data['duplicates'].map((obj: any) => ({
              ...obj,
              __typename: 'AttachmentsDuplicate',
            }));
          }

          observer.next({
            data: {
              uploadAttachment: data,
            },
          });
          observer.complete();
        })
        .catch((error: any) => {
          if (error.name === 'AbortError') {
            // Fetch was aborted.
            return;
          }

          observer.error(error);
        });

      // Cleanup function.
      return () => {
        // Abort fetch.
        if (controller) controller.abort();
      };
    });
  }
}

const createUploadLink = ({
  uri: fetchUri = '',
  fetch: linkFetch = fetch,
  fetchOptions,
  credentials,
  headers,
  includeExtensions,
}: any = {}) => {
  const linkConfig = {
    http: { includeExtensions },
    options: fetchOptions,
    credentials,
    headers,
  };

  return new ApolloLink((operation) => {
    let uri = selectURI(operation, fetchUri);
    const context = operation.getContext();

    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: context.headers,
    };

    const { options, body } = selectHttpOptionsAndBody(
      operation,
      fallbackHttpConfig,
      linkConfig,
      contextConfig
    );

    if (body.variables) {
      // Check for variabable file
      const { file, input, ticketId } = body.variables;

      if (file) {
        // Automatically set by fetch when the body is a FormData instance.
        delete options.headers['content-type'];
        uri += `${ticketId}/add_attachment/`;

        const form = new FormData();
        form.append('file', file);

        // Append all other values from the input variable
        if (input) {
          Object.keys(input).forEach((key) => {
            form.append(snakeCase(key), input[key]);
          });
        }

        options.body = form;
      }
    }

    return new Observable((observer) => {
      // Allow aborting fetch, if supported.
      const { controller, signal } = createSignalIfSupported();
      if (controller) options.signal = signal;

      linkFetch(uri, options)
        .then((response: any) => {
          if (response.status < 200 || response.status > 300) {
            throw response;
          }

          // Forward the response on the context.
          operation.setContext({ response });
          return response;
        })
        .then((response: any) => response.text())
        .then((response: any) => JSON.parse(response))
        .then((result: any) => {
          observer.next({
            data: {
              uploadAttachment: {
                ...camelcaseKeys(result, { deep: true }),
                __typename: 'Attachment',
              },
            },
          });
          observer.complete();
        })
        .catch((error: any) => {
          if (error.name === 'AbortError')
            // Fetch was aborted.
            return;

          if (error && typeof error.text === 'function') {
            // TODO: Send graphql error here
            error.text().then((data: string) => {
              observer.next({
                data: JSON.parse(data),
                errors: [new GraphQLError('Could not send Attachment')],
              });
            });

            // There is a GraphQL result to forward.
            // return observer.next(JSON.parse(error.text()));
          }

          observer.error('error');
        });

      // Cleanup function.
      return () => {
        // Abort fetch.
        if (controller) controller.abort();
      };
    });
  });
};

export { createUploadLink };
