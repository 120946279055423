import { UnitSelectData, UnitType } from './form-input-unit-select.types';

/* -----------------------------------------------------------------------------
 * Area dimension units
 * ---------------------------------------------------------------------------*/

// Supported area units by the app
const AREA_DIMENSION_UNIT: UnitSelectData[] = [
  {
    id: 'SQUARE_METERS',
    label: 'm²',
  },
  {
    id: 'SQUARE_FOOT',
    label: 'ft²',
  },
  // Japanese unit of areal measure
  {
    id: 'TSUBO',
    label: 'tsubo',
  },
];

// server -> local
const AREA_DIMENSION_SERVER_TO_LOCAL_UNIT = {
  'm²': 'SQUARE_METERS',
  'ft²': 'SQUARE_FOOT',
  Tsubo: 'TSUBO',
};

function getAreaDimensionUnitForServerValue(serverValue: string) {
  if (serverValue in AREA_DIMENSION_SERVER_TO_LOCAL_UNIT) {
    const localIdentifier =
      AREA_DIMENSION_SERVER_TO_LOCAL_UNIT[
        serverValue as keyof typeof AREA_DIMENSION_SERVER_TO_LOCAL_UNIT
      ];

    return AREA_DIMENSION_UNIT.find((unit) => unit.id === localIdentifier);
  }
}

// local -> server
const AREA_DIMENSION_LOCAL_TO_SERVER_UNIT = {
  SQUARE_METERS: 'm²',
  SQUARE_FOOT: 'ft²',
  TSUBO: 'Tsubo',
};

function getServerAreaDimensionUnitForLocalValue(localValue: string) {
  if (localValue in AREA_DIMENSION_LOCAL_TO_SERVER_UNIT) {
    return AREA_DIMENSION_LOCAL_TO_SERVER_UNIT[
      localValue as keyof typeof AREA_DIMENSION_LOCAL_TO_SERVER_UNIT
    ];
  }
}

/* -----------------------------------------------------------------------------
 * Currency units
 * ---------------------------------------------------------------------------*/

// Supported currency units by the app
const CURRENCY_UNIT: UnitSelectData[] = [
  {
    id: 'AUD',
    label: 'AUD',
  },
  {
    id: 'CAD',
    label: 'CAD',
  },
  {
    id: 'CHF',
    label: 'CHF',
  },
  {
    id: 'CZK',
    label: 'CZK',
  },
  {
    id: 'EUR',
    label: 'EUR',
  },
  {
    id: 'GBP',
    label: 'GBP',
  },
  {
    id: 'JPY',
    label: 'JPY',
  },
  {
    id: 'KRW',
    label: 'KRW',
  },
  {
    id: 'PLN',
    label: 'PLN',
  },
  {
    id: 'SEK',
    label: 'SEK',
  },
  {
    id: 'SGD',
    label: 'SGD',
  },
  {
    id: 'USD',
    label: 'USD',
  },
];

// server -> local
const CURRENCY_SERVER_TO_LOCAL_UNIT = {
  AUD: 'AUD',
  CAD: 'CAD',
  CHF: 'CHF',
  CZK: 'CZK',
  EUR: 'EUR',
  GBP: 'GBP',
  JPY: 'JPY',
  KRW: 'KRW',
  PLN: 'PLN',
  SEK: 'SEK',
  SGD: 'SGD',
  USD: 'USD',
};

function getCurrencyUnitForServerValue(serverValue: string) {
  if (serverValue in CURRENCY_SERVER_TO_LOCAL_UNIT) {
    const localIdentifier =
      CURRENCY_SERVER_TO_LOCAL_UNIT[
        serverValue as keyof typeof CURRENCY_SERVER_TO_LOCAL_UNIT
      ];

    return CURRENCY_UNIT.find((unit) => unit.id === localIdentifier);
  }
}

// local -> server
const CURRENCY_LOCAL_TO_SERVER_UNIT = {
  AUD: 'AUD',
  CAD: 'CAD',
  CHF: 'CHF',
  CZK: 'CZK',
  EUR: 'EUR',
  GBP: 'GBP',
  JPY: 'JPY',
  KRW: 'KRW',
  PLN: 'PLN',
  SEK: 'SEK',
  SGD: 'SGD',
  USD: 'USD',
};

function getServerCurrencyUnitForLocalValue(localValue: string) {
  if (localValue in CURRENCY_LOCAL_TO_SERVER_UNIT) {
    return CURRENCY_LOCAL_TO_SERVER_UNIT[
      localValue as keyof typeof CURRENCY_LOCAL_TO_SERVER_UNIT
    ];
  }
}

function getUnitForServerValue(type: UnitType, value: string) {
  switch (type) {
    case 'AREA_DIMENSION':
      return getAreaDimensionUnitForServerValue(value);

    case 'CURRENCY': {
      return getCurrencyUnitForServerValue(value);
    }

    default:
      return value;
  }
}

export {
  AREA_DIMENSION_UNIT,
  CURRENCY_UNIT,
  getAreaDimensionUnitForServerValue,
  getServerAreaDimensionUnitForLocalValue,
  getCurrencyUnitForServerValue,
  getServerCurrencyUnitForLocalValue,
  getUnitForServerValue,
};
