import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { useMutation } from '@apollo/react-hooks';

import { useFormDataLoader } from '../../../components/form-data-loader';
import Editor from './editor';
import { Fixed } from '../../../components/layout';
import { MenuHeaderEditor } from '../../../components/menu/header';
import { TICKET_ACTION } from '../../../mutations/tickets/ticket';
import { TicketActionType } from '../../../types/apollo/globalTypes';
import {
  TicketActionVariables,
  TicketAction,
} from '../../../types/apollo/TicketAction';

interface Props extends RouteComponentProps<{ ticketId: string }> {}

const EditorContainer = (props: Props) => {
  const ticketId = useMemo(() => {
    // Parse the ticketId as number
    return Number(props.match.params.ticketId);
  }, [props.match.params.ticketId]);

  const data = useFormDataLoader(ticketId);
  const [startReview] = useMutation<TicketAction, TicketActionVariables>(
    TICKET_ACTION,
    {
      variables: {
        actionType: TicketActionType.start_review,
        ticketId: ticketId.toString(),
      },
    }
  );

  useEffect(() => {
    if (data?.data.status === 'review') {
      startReview();
    }
    // ACT WITH CARE!
    // We only want to make the request when the ticketId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) {
    return null;
  }

  const {
    attachmentSuggestions,
    data: { attachments, ...remaining },
  } = data;

  return (
    <>
      {/* Since we need another header for the editor view we set it here */}
      <Fixed top="0" right="0" left="0" zIndex={1000}>
        <MenuHeaderEditor title={remaining.title} />
      </Fixed>
      <Editor
        suggestions={attachmentSuggestions}
        ticket={{
          ...remaining,
          attachments: attachments.map((attachment) => {
            // transform URL to relative path
            const url = new URL(attachment.file);
            return { ...attachment, file: `${url.pathname}${url.search}` };
          }),
        }}
      />
    </>
  );
};

export default EditorContainer;
