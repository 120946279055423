import * as React from 'react';
import produce from 'immer';
import styled, { css } from 'styled-components';

import { Backdrop, RightSide } from './style';
import { GlowBoxTitle } from '../glowbox';

import { UpdateTicketTemplate } from './templates/update-ticket';
import { ReviewData } from './templates/review-data';
import { SuccessMessageTemplate } from './templates/success-message';
import { ErrorMessageTemplate } from './templates/error-message';
import { WarningMessageTemplate } from './templates/warning-message';

export enum ModalTemplate {
  TICKET_EDIT,
  TICKET_REVIEW,
  TICKET_SUCCESS,
  TICKET_ERROR,
  TICKET_WARNING,
}

enum ModalWrapper {
  glowbox,
  sidebar,
}

type ModalWrapperType = keyof typeof ModalWrapper;

interface ShowModalProps {
  template: ModalTemplate;
  title?: string;
  props?: any;
  wrapper?: ModalWrapperType;
}

type CloseCallback = (cancel: boolean) => void;

export interface Context {
  closeModal: (cancel: boolean) => void;
  showModal: (props: ShowModalProps, onClose?: CloseCallback) => void;
}

const ModalContext = React.createContext<Context | undefined>(undefined);

const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex: 1;

  ${({ modalOpen, mt }) =>
    modalOpen &&
    css`
      overflow: hidden;
      margin-top: ${mt}px;
    `}
`;

interface Templates {
  [key: string]: React.ComponentType<any>;
}

const templates: Templates = {
  [ModalTemplate.TICKET_EDIT]: UpdateTicketTemplate,
  [ModalTemplate.TICKET_REVIEW]: ReviewData,
  [ModalTemplate.TICKET_SUCCESS]: SuccessMessageTemplate,
  [ModalTemplate.TICKET_ERROR]: ErrorMessageTemplate,
  [ModalTemplate.TICKET_WARNING]: WarningMessageTemplate,
};

interface ContentContainerProps {
  modalOpen: boolean;
  mt: number;
}

interface State {
  modalTemplate: ModalTemplate | null;
  modalTitle: string;
  modalProps: any;
  modalWrapper: ModalWrapper;
}

function ModalProvider(props: React.PropsWithChildren) {
  const scrollPosition = React.useRef(0);
  const onCloseCallback = React.useRef<null | CloseCallback>(null);

  const [state, setState] = React.useState<State>({
    modalTemplate: null,
    modalTitle: '',
    modalProps: {},
    modalWrapper: ModalWrapper.glowbox,
  });

  const showModal = (
    { template, title, props, wrapper }: ShowModalProps,
    onClose?: CloseCallback
  ) => {
    scrollPosition.current = document.documentElement.scrollTop;

    if (onClose) {
      onCloseCallback.current = onClose;
    }

    setState(
      produce((draft) => {
        draft.modalTemplate = template;
        draft.modalTitle = title || '';
        draft.modalProps = props || null;
        draft.modalWrapper = wrapper
          ? ModalWrapper[wrapper]
          : ModalWrapper.glowbox;
      })
    );
  };

  const closeModal = (cancel: boolean) => {
    setState(
      produce((draft) => {
        draft.modalTemplate = null;
        draft.modalTitle = '';
        draft.modalProps = null;
      })
    );

    if (onCloseCallback.current) {
      onCloseCallback.current(cancel);
      onCloseCallback.current = null;
    }
  };

  // Restore the scroll position after the modal is closed
  React.useEffect(() => {
    if (state.modalTemplate === null) {
      // Check for internet explorer
      if (document.documentElement.scrollTo) {
        document.documentElement.scrollTo(0, scrollPosition.current);
      }
    }
  }, [state.modalTemplate]);

  const context = React.useRef<Context>({
    closeModal,
    showModal,
  });

  let Template;
  const modalVisible = state.modalTemplate !== null;

  if (state.modalTemplate !== null) {
    Template = templates[state.modalTemplate];
  }

  let Wrapper: React.ComponentType<{
    title?: string;
    noFlex?: boolean;
    children: React.ReactNode;
  }> = GlowBoxTitle;

  if (state.modalWrapper === ModalWrapper.sidebar) {
    Wrapper = RightSide;
  }

  return (
    <ModalContext.Provider value={context.current}>
      {/* TODO: Preserve scrollPosition */}
      <ContentContainer modalOpen={modalVisible} mt={-scrollPosition.current}>
        {props.children}
      </ContentContainer>

      {modalVisible && Template !== undefined ? (
        <Backdrop onClick={() => closeModal(true)}>
          <div onClick={(e) => e.stopPropagation()}>
            <Wrapper title={state.modalTitle} noFlex>
              <Template {...state.modalProps} />
            </Wrapper>
          </div>
        </Backdrop>
      ) : null}
    </ModalContext.Provider>
  );
}

function useModal() {
  const context = React.useContext(ModalContext);

  if (context === undefined) {
    throw new Error('Cannot use useModal outside of provider!');
  }

  return context;
}

export { ModalProvider as default, useModal };
