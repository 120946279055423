import { Mutator } from 'final-form';

import { GroupItemResult } from '../form-data-loader';
import {
  getAreaDimensionUnitForServerValue,
  getCurrencyUnitForServerValue,
} from '../form-input-unit-select/form-input-unit-select.constants';

import { FormDataEntry, TicketFormData } from './form-data.types';

const setAddressData: Mutator<TicketFormData> = (
  [, data],
  state,
  { changeValue }
) => {
  const updatedData = data as Record<string, FormDataEntry>;
  for (const fieldName in updatedData) {
    const fieldData = updatedData[fieldName];
    changeValue(state, fieldName, () => fieldData);
  }
};

// Remove suggestion from field
const resetSuggestion: Mutator<TicketFormData> = (
  [name],
  state,
  { changeValue }
) => {
  changeValue(state, `${name}.suggestion`, () => undefined);
};

const setSuggestion: Mutator<TicketFormData> = (
  [name, data],
  state,
  { changeValue }
) => {
  const suggestion = data as GroupItemResult;
  const { value: suggestionValue } = suggestion;

  // Set suggestion
  changeValue(state, `${name}.suggestion`, () => suggestionValue);

  if (suggestionValue.unit) {
    const unit = getAreaDimensionUnitForServerValue(suggestionValue.unit);

    if (unit) {
      changeValue(state, `${name}.unit`, () => unit.id);
    } else {
      // TODO: based on the input parameters it should be clear which unit
      // type should be used here
      const currencyUnit = getCurrencyUnitForServerValue(suggestionValue.unit);

      if (currencyUnit) {
        changeValue(state, `${name}.unit`, () => currencyUnit.id);
      }
    }
  }
};

export { setAddressData, resetSuggestion, setSuggestion };
