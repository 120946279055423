import { TicketFormData, FormDataEntry } from './form-data.types';

/**
 * Helper to select the value that should bne displayed in the input field
 */
function formatFormDataEntry(formDataEntry: FormDataEntry) {
  return formDataEntry ? formDataEntry.inputValue : 'test';
}

/**
 * The initial data the form gets initialized with
 */
const initialFormData: TicketFormData = {
  companyName: {
    inputValue: '',
    type: 'STRING',
  },
  companyStreetName: {
    inputValue: '',
    type: 'STRING',
  },
  companyStreetNumber: {
    inputValue: '',
    type: 'STRING',
  },
  companyCity: {
    inputValue: '',
    type: 'STRING',
  },
  companyPostalCode: {
    inputValue: '',
    type: 'STRING',
  },
  companyCountry: {
    inputValue: '',
    type: 'STRING',
  },
  companyLocationLat: {
    inputValue: '',
    type: 'NUMERIC',
  },
  companyLocationLng: {
    inputValue: '',
    type: 'NUMERIC',
  },
  companyEmail: {
    inputValue: '',
    type: 'STRING',
  },
  companyPhonenumber: {
    inputValue: '',
    type: 'STRING',
  },
  companyContactName: {
    inputValue: '',
    type: 'STRING',
  },
  propertyName: {
    inputValue: '',
    type: 'STRING',
  },
  propertyStreetName: {
    inputValue: '',
    type: 'STRING',
  },
  propertyStreetNumber: {
    inputValue: '',
    type: 'STRING',
  },
  propertyCity: {
    inputValue: '',
    type: 'STRING',
  },
  propertyPostalCode: {
    inputValue: '',
    type: 'STRING',
  },
  propertyCountry: {
    inputValue: '',
    type: 'STRING',
  },
  propertyLocationLat: {
    inputValue: '',
    type: 'NUMERIC',
  },
  propertyLocationLng: {
    inputValue: '',
    type: 'NUMERIC',
  },
  propertyUse: {
    inputValue: '',
    type: 'STRING',
  },
  propertyConstructionYear: {
    inputValue: '',
    type: 'NUMERIC',
  },
  propertyLandArea: {
    inputValue: '',
    type: 'NUMERIC',
  },
  propertyLettableArea: {
    inputValue: '',
    type: 'NUMERIC',
  },
  propertyParkingSpaces: {
    inputValue: '',
    type: 'NUMERIC',
  },
  propertyHotelRooms: {
    inputValue: '',
    type: 'NUMERIC',
  },
  propertyOccupancy: {
    inputValue: '',
    type: 'NUMERIC',
  },
  propertyNetIncome: {
    inputValue: '',
    type: 'NUMERIC',
  },
  propertyGrossIncome: {
    inputValue: '',
    type: 'NUMERIC',
  },
  ticketAreaUnit: '',
  ticketCurrencyUnit: '',
};

export { initialFormData, formatFormDataEntry };
