import styled from 'styled-components';

/**
 * Replacement of the primary button, because rewriting without typescript would be too complicated
 */
const PrimaryBase = styled.button(({ theme }) => ({
  backgroundColor: theme.colors.bg.buttonPrimary,
  display: 'block',
  color: theme.colors.text.buttonPrimary,
  borderRadius: '6px',
  width: '100%',
  border: 'none',
  padding: '10px',
}));

export { PrimaryBase as PrimaryButton };
