import * as React from 'react';

interface State {
  openIndexes: string[];
  type?: 'opening' | 'closing';
}

interface Props {
  openIndexes?: string[];
  initialOpenIndexes?: string[];
  children: (renderProps: {
    openIndexes: string[];
    handleItemClick: (arg0: string) => void;
  }) => React.ReactNode;
  stateReducer: (...args: any[]) => State;
  onStateChange: (arg0: any) => void;
}

export default class Accordion extends React.Component<Props, State> {
  static defaultProps = {
    // eslint-disable-next-line no-empty-pattern
    stateReducer: ({}, changes: any) => changes,
    onStateChange: () => {},
  };

  state: State = {
    openIndexes: this.props.initialOpenIndexes
      ? this.props.initialOpenIndexes
      : [],
  };

  getState(state: State = this.state) {
    return {
      openIndexes:
        this.props.openIndexes === undefined
          ? state.openIndexes
          : this.props.openIndexes,
    };
  }

  internalSetState(changes: any, callback = () => {}) {
    let allChanges: any;
    this.setState(
      (state) => {
        const actualState = this.getState(state);
        const changesObject =
          typeof changes === 'function' ? changes(actualState) : changes;
        allChanges = this.props.stateReducer(actualState, changesObject);
        return allChanges;
      },
      () => {
        this.props.onStateChange(allChanges);
        callback();
      }
    );
  }

  handleItemClick = (index: string) => {
    this.internalSetState((state: State) => {
      const closing = state.openIndexes.includes(index);
      return {
        type: closing ? 'closing' : 'opening',
        openIndexes: closing
          ? state.openIndexes.filter((i) => i !== index)
          : [...state.openIndexes, index],
      };
    });
  };

  render() {
    return this.props.children({
      openIndexes: this.getState().openIndexes,
      handleItemClick: this.handleItemClick,
    });
  }
}
