import { useMemo } from 'react';

import { PROPERTY_USE_ALLOWED_SUGGESTION_VALUES } from './form-data-loader.constants';
import { UnitSuggestion, GroupItemResult } from './form-data-loader.types';

function generatePropertyUseSuggestions(
  incomingSuggestions: GroupItemResult[]
): GroupItemResult[] {
  // Get all options that don't occur in the suggestions from the document
  const unmatchedPropertyUseSuggestions = PROPERTY_USE_ALLOWED_SUGGESTION_VALUES.filter(
    (suggestionUnitValue) => {
      return (
        incomingSuggestions.findIndex((suggestion) => {
          return suggestion.value.unit === suggestionUnitValue;
        }) === -1
      );
    }
  );

  const suggestionsNotInDocument = unmatchedPropertyUseSuggestions.map(
    (suggestionValue) => {
      return {
        id: suggestionValue,
        value: {
          originalValue: suggestionValue,
          unit: suggestionValue,
        },
        kpi: 'property_use',
        type: 'STATIC_SUGGESTION',
      } as UnitSuggestion;
    }
  );

  return [...incomingSuggestions, ...suggestionsNotInDocument];
}

/**
 * For property use the user should see all available property use options and
 * not only the options that are available in the document.
 * Therefore the incomingSuggestions are checked for the options they contain,
 * options that are not available in the suggestions are then appended
 * to the original suggestions.
 */
function usePropertyUseSuggestions(incomingSuggestions: GroupItemResult[]) {
  return useMemo(() => {
    return generatePropertyUseSuggestions(incomingSuggestions);
  }, [incomingSuggestions]);
}

export { usePropertyUseSuggestions, generatePropertyUseSuggestions };
