import * as React from 'react';
import { useEffect } from 'react';
import { useSelect } from 'downshift';
import styled from 'styled-components';
import cn from 'classnames';

import { StyledContent } from '../dropdown/style';
import { GroupItemResult } from '../form-data-loader';
import { ComboboxItem } from '../combobox/combobox';
import Icon, { IconsSmall, IconSize } from '../icon';

/* -----------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------*/

const StyledSelectButton = styled.button(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  border: 'none',
  backgroundColor: theme.colors['gray-300'],
  padding: '10px 12px',
  fontSize: '14px',
  lineHeight: '20px',
  transitionDuration: '0.25s',
  transitionTimingFunction: 'cubic-bezier(0.2, 0.8, 0.4, 1)',
  color: theme.colors['gray-600'],

  '&.is-open': {
    boxShadow: 'rgba(39, 110, 241, 0.32) 0px 2px 6px',
  },

  '&.selected': {
    color: theme.colors['gray-800'],
  },
}));

const StyledRoot = styled.div({
  position: 'relative',
});

const StyledMenuContentWrapper = styled.div({
  position: 'absolute',
  zIndex: 10000,
  left: 0,
  right: 0,
});

/* -----------------------------------------------------------------------------
 * Utils
 * ---------------------------------------------------------------------------*/

function itemToString(item: GroupItemResult | null) {
  if (item) {
    return item.value.originalValue;
  }

  return '';
}

/* -----------------------------------------------------------------------------
 * AddressValidationSelect
 * ---------------------------------------------------------------------------*/

interface AddressValidationSelectProps {
  suggestions: GroupItemResult[];
  placeholder: string;
  onSelectedItemChange(suggestionValue: string): void;
  focusHighlight(suggestionKey: string): void;
}

const AddressValidationSelect = ({
  suggestions,
  placeholder,
  onSelectedItemChange,
  focusHighlight,
}: AddressValidationSelectProps) => {
  const {
    selectedItem,
    isOpen,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    initialHighlightedIndex: 0,
    items: suggestions,
    itemToString,
    onStateChange: (event) => {
      if (event.type === useSelect.stateChangeTypes.ItemClick) {
        // Check if selectedItem was changed otherwise reuse previously
        // selected item
        const _selectedItem = event.selectedItem ?? selectedItem;
        onSelectedItemChange(itemToString(_selectedItem));
      }
    },
  });
  useEffect(() => {
    if (
      isOpen &&
      highlightedIndex !== undefined &&
      highlightedIndex >= 0 &&
      suggestions[highlightedIndex]
    ) {
      focusHighlight(suggestions[highlightedIndex].id);
    }
  }, [isOpen, suggestions, focusHighlight, highlightedIndex]);

  const isInitialState = selectedItem === null;

  return (
    <StyledRoot>
      <StyledSelectButton
        {...getToggleButtonProps({
          className: cn({
            selected: !isInitialState,
            'is-open': isOpen,
          }),
        })}
      >
        {isInitialState ? placeholder : itemToString(selectedItem)}

        <div style={{ float: 'right' }}>
          <Icon name={IconsSmall.arrowDown} size={IconSize.small} />
        </div>
      </StyledSelectButton>
      <StyledMenuContentWrapper {...getMenuProps()}>
        {isOpen ? (
          <StyledContent minWidth="100%">
            {suggestions.map((item, index) => {
              const { value } = item;

              return (
                <ComboboxItem
                  key={item.id}
                  isHighlighted={highlightedIndex === index}
                  originalValue={value.originalValue}
                  subItems={'items' in item ? item.items : undefined}
                  focusHighlight={focusHighlight}
                  {...getItemProps({ item, index })}
                ></ComboboxItem>
              );
            })}
          </StyledContent>
        ) : null}
      </StyledMenuContentWrapper>
    </StyledRoot>
  );
};

export { AddressValidationSelect };
