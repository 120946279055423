/**
 * Contains the styling / submenu logic of the dropdown field that is displayed
 * below the autocomplete fields in the form
 */
import * as React from 'react';

import { ComboboxSubMenu } from './submenu';
import { Suggestion } from '../form-data-loader';
import { SuggestionNumberSystem } from './combobox.types';
import { StyledComboboxItem } from './combobox.style';

/* -----------------------------------------------------------------------------
 * Utils
 * ---------------------------------------------------------------------------*/

const currencyFormat = new Intl.NumberFormat('de-DE', {
  minimumFractionDigits: 2,
});

function formatAsCurrency(input: number) {
  return currencyFormat.format(input);
}

/* -----------------------------------------------------------------------------
 * ComboBoxItemPrimitive
 * ---------------------------------------------------------------------------*/

interface ComboboxItemPrimitiveProps {
  isHighlighted?: boolean;
  onMouseMove?(event: React.MouseEvent<HTMLDivElement>): void;
  onClick?(event: React.MouseEvent<HTMLDivElement>): void;
  originalValue: string;
  unit?: string | null;
  numberSystem?: SuggestionNumberSystem;
  number?: number | null;
  children?: React.ReactNode;
}

const ComboBoxItemPrimitive = React.forwardRef<any, ComboboxItemPrimitiveProps>(
  (props, forwardedRef) => {
    const {
      isHighlighted,
      onMouseMove,
      onClick,
      originalValue,
      number,
      unit,
      children,
    } = props;
    const hasUnit = unit !== null && unit !== undefined;
    const unitType =
      number !== null && number !== undefined ? 'NUMBER' : 'TEXT';

    return (
      <StyledComboboxItem.Root
        ref={forwardedRef}
        className={isHighlighted ? 'focus' : ''}
        onMouseMove={onMouseMove}
        onClick={onClick}
      >
        <StyledComboboxItem.ValueColumn>
          <div>{originalValue}</div>

          {/* Show additional info for number and unit */}
          {hasUnit ? (
            <StyledComboboxItem.NumberRow>
              {unitType === 'NUMBER' ? (
                <StyledComboboxItem.NumberValue>
                  {/* TODO: Add check if we have currency */}
                  {formatAsCurrency(number!)}
                </StyledComboboxItem.NumberValue>
              ) : null}
              <StyledComboboxItem.UnitValue>
                {unit}
              </StyledComboboxItem.UnitValue>
            </StyledComboboxItem.NumberRow>
          ) : null}
        </StyledComboboxItem.ValueColumn>

        {/* Show number subitems and menu */}
        <div>{children}</div>
      </StyledComboboxItem.Root>
    );
  }
);

/* -----------------------------------------------------------------------------
 * ComboboxItem
 * ---------------------------------------------------------------------------*/

interface ComboboxItemProps extends ComboboxItemPrimitiveProps {
  subItems?: Suggestion[];
  focusHighlight(suggestionKey: string): void;
  type: 'SUGGESTION' | 'STATIC_SUGGESTION' | 'GROUP';
}

const ComboboxItem = React.forwardRef<any, ComboboxItemProps>(
  (props, forwardedRef) => {
    const { subItems, type, ...restProps } = props;

    if (subItems) {
      return (
        <ComboBoxItemWithSubMenu
          {...restProps}
          subItems={subItems}
          ref={forwardedRef}
        ></ComboBoxItemWithSubMenu>
      );
    }

    return (
      <ComboBoxItemPrimitive {...restProps} ref={forwardedRef}>
        <StyledComboboxItem.ItemsCounter>
          {type === 'STATIC_SUGGESTION' ? 0 : 1}
        </StyledComboboxItem.ItemsCounter>
      </ComboBoxItemPrimitive>
    );
  }
);

interface ComboBoxItemWithSubMenuProps extends ComboboxItemPrimitiveProps {
  subItems: Suggestion[];
  focusHighlight(suggestionKey: string): void;
}

const ComboBoxItemWithSubMenu = React.forwardRef<
  any,
  ComboBoxItemWithSubMenuProps
>((props, innerRef) => {
  const { subItems, focusHighlight, ...restProps } = props;
  const [isSubMenuOpen, setSubMenuOpen] = React.useState(false);

  return (
    <>
      <ComboBoxItemPrimitive {...restProps} ref={innerRef}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            return;
          }}
        >
          <StyledComboboxItem.ItemsCounter
            onMouseEnter={() => setSubMenuOpen(true)}
          >
            {subItems.length}
          </StyledComboboxItem.ItemsCounter>
        </div>
      </ComboBoxItemPrimitive>

      <ComboboxSubMenu
        isOpen={isSubMenuOpen}
        onClose={() => {
          setSubMenuOpen(false);
        }}
        onChange={(item) => {
          focusHighlight(item.id);
        }}
        items={subItems}
      />
    </>
  );
});

export { ComboboxItem };
