import * as React from 'react';
import { useField } from 'react-final-form';

import { GroupItemResult } from '../form-data-loader';
import type { UnitType } from '../form-input-unit-select';
import { FormInputComboboxWithUnitSelect } from '../form-input-combobox-with-unit-select';

import {
  INPUT_VALUE_PROPERTY_NAME,
  INPUT_UNIT_PROPERTY_NAME,
} from './form-data-field-mapper.constants';

type InputValueFieldMapperProps = {
  name: string;
  label: string;
  unitType: UnitType;
  unitReadOnly?: boolean;
  unitPlaceholder?: string;
  items: GroupItemResult[];
  onHighlightChange(id: string): void;
};

const useFieldOptions = {
  subscription: {
    value: true,
    active: true,
  },
};

function InputValueFieldMapperWithUnit(props: InputValueFieldMapperProps) {
  const {
    name,
    unitType,
    unitReadOnly,
    unitPlaceholder,
    label,
    items,
    onHighlightChange,
  } = props;
  const fieldGroup = useField(name, {
    subscription: { data: true, error: true },
  });
  const field = useField<string, HTMLElement>(
    `${name}.${INPUT_VALUE_PROPERTY_NAME}`,
    useFieldOptions
  );
  const unitField = useField<string, HTMLSelectElement>(
    `${name}.${INPUT_UNIT_PROPERTY_NAME}`,
    useFieldOptions
  );

  const error = fieldGroup.meta.error?.inputValue ?? undefined;
  const unitSelectHasError = error === 'INVALID_UNIT';

  return (
    <FormInputComboboxWithUnitSelect
      name={name}
      input={field.input}
      meta={field.meta}
      label={label}
      items={items}
      error={error}
      unitSelect={{
        hasError: unitSelectHasError,
        type: unitType,
        value: unitField.input.value,
        readOnly: unitReadOnly,
        placeholder: unitPlaceholder,
        onChange: unitField.input.onChange,
        onFocus: unitField.input.onFocus,
      }}
      onHighlightChange={onHighlightChange}
      // onSelectSuggestion={(data: any) => {
      //   // const suggestion = data as GroupItemResult;
      //   // form.mutators.setSuggestion(name, suggestion);
      // }}
    />
  );
}

export { InputValueFieldMapperWithUnit };
