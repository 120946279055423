import styled from 'styled-components';

export const DropDownMenu = styled.div`
  background-color: white;
  border-radius: 3px;
  position: absolute;
  right: 0;
  padding: 12px;
  margin-top: 4px;
`;

// TODO: This has moved to design-system/AnchoredOverlay
interface StyledContentProps {
  minWidth?: number | string;
}

// TODO: Add shouldForwardProp
// https://github.com/styled-components/styled-components/releases/tag/v5.1.0
export const StyledContent = styled.div<StyledContentProps>((props) => ({
  minWidth: props.minWidth,
  backgroundColor: 'white',
  borderRadius: '6px',
  padding: '4px',
  marginTop: '0px',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 16px',
}));
