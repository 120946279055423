import React from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';

import { SubActionButtonCover } from '../button/sub-action';
import { Box } from '../layout';

const Footer = styled(Box)`
  border-top: 1px solid #dbdbdb;
`;

const Page = ({ children }: React.PropsWithChildren) => <>{children}</>;

type FormError = {
  [propertyKey: string]: string;
};

type FormValues = {
  [key: string]: string;
};

interface Props {
  initialValues?: FormValues;
  children: () => React.ReactNodeArray;
  onSubmit: (values?: any, step?: number) => Promise<undefined | FormError>;
  startAtPage?: number;
}

interface State {
  page: number;
  values: FormValues;
}

/**
 * Wizard for a multistep form like the one we need to crate the new tickets
 */
class Wizard extends React.Component<Props, State> {
  static Page = Page;

  // Better types here need an update of react-final-form
  submitForm: any = null;

  state: State = {
    page: this.props.startAtPage || 0,
    values: this.props.initialValues || {},
  };

  next = async (values: FormValues, nextPage: number) => {
    this.setState(() => ({
      page: nextPage,
      values,
    }));
  };

  render() {
    const children = this.props.children();

    const { page, values } = this.state;
    const activePages = React.Children.toArray(children).filter(
      (_, index) => index <= page
    );
    const isLastPage = page === React.Children.count(children) - 1;

    return (
      <Form
        initialValues={values}
        onSubmit={async (values: any) => {
          const { onSubmit } = this.props;
          const { page } = this.state;
          const isLastPage = page === React.Children.count(children) - 1;

          if (isLastPage) {
            return await onSubmit(values, page);
          } else {
            const result = await onSubmit(values, page);
            if (!result) {
              this.next(
                values,
                Math.min(this.state.page + 1, children.length - 1)
              );
            }
            return result;
          }
        }}
      >
        {({ handleSubmit }) => {
          this.submitForm = handleSubmit;

          return (
            <form onSubmit={handleSubmit}>
              <>{activePages}</>
              <Footer px="5" pt="4" mt="5">
                <SubActionButtonCover type="submit" height="42px">
                  {!isLastPage ? 'Weiter' : 'Exposé anlegen'}
                </SubActionButtonCover>
              </Footer>
            </form>
          );
        }}
      </Form>
    );
  }
}

export { Wizard };
