import styled from 'styled-components';

import { getSpace, getColor } from '../layout/styled-helpers';

const Backdrop = styled.div({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  // Needs > 1001 to blend over pdf toolbar
  zIndex: 1100,
  alignItems: 'center',
  justifyContent: 'center',
});

const ModalFooter = styled.div({
  paddingTop: (getSpace(2) as unknown) as string,
  borderTop: `1px solid ${getColor('gray-200')}`,
  display: 'flex',
  alignContent: 'flex-end',
  justifyContent: 'flex-end',
});

export const RightSide = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  width: '450px',
  backgroundColor: '#ffffff',
  overflowY: 'scroll',
  overflowX: 'hidden',
});

export { Backdrop, ModalFooter };
