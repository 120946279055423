import * as React from 'react';
import styled from 'styled-components';

import { FlexChild, Flex } from '../layout';
import { getColor } from '../layout/styled-helpers';
import { Dropdown } from '../dropdown';
import { DropDownMenu } from '../dropdown/style';
import { useCurrentUser } from '../current-user';

const AvatarContainer = styled(FlexChild)`
  width: 34px;
  height: 34px;
  border-radius: 17px;
  overflow: hidden;
  margin-left: 3px;
  margin-top: 3px;
`;

const Container = styled(Flex)`
  background-color: rgba(0, 0, 0, 0.16);
  cursor: pointer;
  height: 40px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #ffffff;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
`;

const InitialContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg.highlight,
  height: '28px',
  width: '28px',
  borderRadius: '14px',
  fontWeight: 600,
  fontSize: '11px',
  letterSpacing: '0.06em',
  textTransform: 'uppercase',
  textAlign: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  display: 'flex',
  paddingBottom: '1px',
  color: theme.colors.text.highlight,
}));

const DropDownList = styled.ul`
  margin: 0;
  padding: 0;

  & > li {
    list-style: none;
    margin: 0;
    padding: 0;

    > a,
    button {
      cursor: pointer;
      display: block;
      padding: 8px 6px;
      text-decoration: none;
    }
  }
`;

const TransparentButton = styled.button({
  padding: 0,
  backgroundColor: 'transparent',
  border: 'none',
});

const getCapitals = (string1: string | null, string2: string | null) => {
  const getLetter = (input: string | null, fallback: string) =>
    input ? input[0].toUpperCase() : 'C';
  return `${getLetter(string1, 'C')}${getLetter(string2, 'R')}`;
};

interface UserMenuProps {
  firstName: string | null;
  lastName: string | null;
}

export const UserMenu = ({ firstName, lastName }: UserMenuProps) => {
  const { removeToken } = useCurrentUser();

  const initials = getCapitals(firstName, lastName);

  return (
    <Dropdown
      dropdown={() => (
        <DropDownMenu>
          <DropDownList>
            <li>
              <TransparentButton
                title="Aus der Datenextraktion abmelden"
                onClick={() => removeToken()}
              >
                Abmelden
              </TransparentButton>
            </li>
          </DropDownList>
        </DropDownMenu>
      )}
    >
      {({ toggle }) => (
        <TransparentButton onClick={toggle}>
          <Container>
            <AvatarContainer>
              <InitialContainer style={{ width: '34px', height: '34px' }}>
                {initials}
              </InitialContainer>
            </AvatarContainer>
            <FlexChild pt="11px" pb="12px" px="3">
              {firstName} {lastName}
            </FlexChild>
          </Container>
        </TransparentButton>
      )}
    </Dropdown>
  );
};

const UserMenuSmallContainer = styled.div`
  height: 28px;
  background-color: ${getColor('gray-200')};
  font-size: 12px;
  color: #5b5b5b;
  border-radius: 14px;
  display: inline-flex;
  align-items: center;
`;

export const UserMenuSmall = ({ firstName, lastName }: UserMenuProps) => {
  const initials = getCapitals(firstName, lastName);

  return (
    <UserMenuSmallContainer>
      <FlexChild>
        <InitialContainer>{initials}</InitialContainer>
      </FlexChild>
      <FlexChild px="3">
        {firstName} {lastName}
      </FlexChild>
    </UserMenuSmallContainer>
  );
};
