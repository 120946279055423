/**
 * The Actions for the tickets
 */

import * as React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { FlexChild, Flex } from '../../../components/layout';
import { ModalTemplate, useModal } from '../../../components/modal';
import { Ticket } from './ticket-list-Item';
import {
  TicketAction,
  TICKET_ACTION,
  TicketDelete,
  TICKET_DELETE,
} from '../../../mutations/tickets/ticket';
import { TicketActionType } from '../../../types/apollo/globalTypes';
import { withStyledOverride } from '../../../helpers/override-wrapper';
import { getColor } from '../../../components/layout/styled-helpers';
import {
  ActionButton,
  actionButtonStyle,
} from '../../../lib/buttons/action-button';
import { MutationUpdaterFn } from 'react-apollo';
import { TicketAction as TicketActionResponseType } from '../../../types/apollo/TicketAction';
import { TicketDelete as TicketDeleteResponseType } from '../../../types/apollo/TicketDelete';
import { TicketSubActionButton } from '../overview/style';

const SActionButtonLink = styled(withStyledOverride(Link))`
  ${actionButtonStyle}
`;

export const FloatedFlex = styled(Flex)`
  float: right;
`;

interface Props {
  ticket: Ticket;
}

////////////////////////////////////////////////////////////////////////
// Delete Ticket
interface TicketSubActionDeleteProps extends Props {
  onDelete: MutationUpdaterFn<TicketDeleteResponseType>;
}

export const TicketSubActionDelete = ({
  onDelete,
  ticket,
}: TicketSubActionDeleteProps) => (
  <TicketDelete mutation={TICKET_DELETE} update={onDelete}>
    {(deleteTicket, { loading }) => (
      <TicketSubActionButton
        loading={loading}
        onClick={() =>
          deleteTicket({
            variables: {
              ticketId: ticket.id,
            },
          })
        }
      >
        Exposé Löschen
      </TicketSubActionButton>
    )}
  </TicketDelete>
);

////////////////////////////////////////////////////////////////////////
// Start analysis
interface TicketActionAnalysisProps extends Props {
  onUpdate: MutationUpdaterFn<TicketActionResponseType>;
  onDelete: MutationUpdaterFn<TicketDeleteResponseType>;
}

export const TicketActionAnalysis = ({
  ticket,
  onUpdate,
  onDelete,
}: TicketActionAnalysisProps) => (
  <FloatedFlex>
    <FlexChild mr={2}>
      <TicketSubActionDelete ticket={ticket} onDelete={onDelete} />
    </FlexChild>

    <FlexChild>
      <TicketAction
        mutation={TICKET_ACTION}
        variables={{
          ticketId: ticket.id,
          actionType: TicketActionType.start_analysis,
        }}
        update={onUpdate}
      >
        {(startAnalysis, { loading }) => (
          <ActionButton
            disabled={loading}
            loading={loading}
            icon
            onClick={() => startAnalysis()}
          >
            Analyse Starten
          </ActionButton>
        )}
      </TicketAction>
    </FlexChild>
  </FloatedFlex>
);

////////////////////////////////////////////////////////////////////////
// Edit ticket
interface TicketActionDraftProps extends Props {
  onDelete: MutationUpdaterFn<TicketDeleteResponseType>;
}

export const TicketActionDraft = ({
  ticket,
  onDelete,
}: TicketActionDraftProps) => {
  const { showModal } = useModal();

  return (
    <FloatedFlex>
      <FlexChild>
        <TicketSubActionDelete ticket={ticket} onDelete={onDelete} />
      </FlexChild>
      <FlexChild>
        <ActionButton
          icon
          onClick={() =>
            showModal({
              template: ModalTemplate.TICKET_EDIT,
              title: `„${ticket.title}“ bearbeiten`,
              props: {
                ticketId: ticket.id,
              },
            })
          }
        >
          Anhang hinzufügen
        </ActionButton>
      </FlexChild>
    </FloatedFlex>
  );
};

////////////////////////////////////////////////////////////////////////
// Show results
export const TicketActionResults = ({ ticket }: Props) => {
  const { showModal } = useModal();

  return (
    <FloatedFlex>
      <FlexChild>
        <ActionButton
          icon
          onClick={() =>
            showModal({
              template: ModalTemplate.TICKET_REVIEW,
              wrapper: 'sidebar',
              props: {
                ticketId: ticket.id,
              },
            })
          }
        >
          Ergebnisse anzeigen
        </ActionButton>
      </FlexChild>
    </FloatedFlex>
  );
};

////////////////////////////////////////////////////////////////////////
// Validate results
export const TicketActionReview = ({ ticket }: Props) => (
  <FloatedFlex>
    <FlexChild>
      <ActionButton
        icon
        overrides={{
          Root: {
            component: SActionButtonLink,
            props: {
              to: {
                pathname: `/tickets/${ticket.id}/review`,
                state: {
                  fromDashboard: true,
                },
              },
            },
          },
        }}
      >
        Überprüfen
      </ActionButton>
    </FlexChild>
  </FloatedFlex>
);

////////////////////////////////////////////////////////////////////////
// Locked ticket
const ticketActionButtonInReview = css`
  border: 1px solid ${getColor('gray-300')};
  color: ${getColor('gray-700')};

  &:hover {
    color: ${getColor('gray-800')};
  }
`;

export const TicketActionInReview = ({ ticket }: Props) => (
  <FloatedFlex>
    <FlexChild>
      <ActionButton
        icon
        iconPosition="left"
        overrides={{
          Root: {
            component: SActionButtonLink,
            props: {
              to: `/tickets/${ticket.id}/review`,
            },
            style: ticketActionButtonInReview,
          },
          Icon: {
            props: {
              name: 'timer',
              fill: 'gray-600',
            },
          },
        }}
      >
        In Bearbeitung
      </ActionButton>
    </FlexChild>
  </FloatedFlex>
);

////////////////////////////////////////////////////////////////////////
// Failed ticket
interface TicketActionFailedProps extends Props {
  onUpdate: MutationUpdaterFn<TicketActionResponseType>;
  onDelete: MutationUpdaterFn<TicketDeleteResponseType>;
}

export const TicketActionFailed = ({
  ticket,
  onDelete,
  onUpdate,
}: TicketActionFailedProps) => (
  <FloatedFlex>
    <FlexChild>
      <TicketSubActionDelete ticket={ticket} onDelete={onDelete} />
    </FlexChild>

    <FlexChild>
      <TicketAction
        mutation={TICKET_ACTION}
        variables={{
          ticketId: ticket.id,
          actionType: TicketActionType.skip_analysis,
        }}
        update={onUpdate}
      >
        {(startAnalysis, { loading }) => (
          <ActionButton
            disabled={loading}
            loading={loading}
            icon
            onClick={() => startAnalysis()}
          >
            Manuell Überprüfen
          </ActionButton>
        )}
      </TicketAction>
    </FlexChild>
  </FloatedFlex>
);
