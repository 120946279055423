import React from 'react';

import { BackgroundImage } from './style';
import { FullHeight } from '../block';

const NoHeader = (props: React.PropsWithChildren) => (
  <BackgroundImage>
    <FullHeight pt={60}>{props.children}</FullHeight>
  </BackgroundImage>
);

export function withNoHeaderLayout<T extends {}>(
  Component: React.ComponentType<any>
): React.FC<T> {
  return (props) => (
    <NoHeader>
      <Component {...props} />
    </NoHeader>
  );
}
