import { useMutation, useQueryClient } from 'react-query';

import { getBaseUrl } from '../config';
import { useFetch } from '../fetch';
import {
  AttachmentType,
  ProcessedAttachmentItemResponse,
  TicketItemReview,
} from './get-ticket';
import { camelcaseKeys } from '../utils';
import { getTicketAnalysisResultQueryKey } from './get-ticket-analysis-result';

type PatchAttachmentRequestParams =
  | { type: AttachmentType }
  | { description: string; file_name: string };

const usePatchAttachment = () => {
  const { patch } = useFetch();
  const baseUrl = getBaseUrl();

  return async (attachmentId: number, params: PatchAttachmentRequestParams) => {
    const { data } = await patch<ProcessedAttachmentItemResponse>(
      `${baseUrl}attachments/${attachmentId}/`,
      params
    );

    return camelcaseKeys(data);
  };
};

type UsePatchAttachmentMutationParams = {
  attachmentId: number;
  ticketId?: number;
  data: { type: AttachmentType } | { description: string; fileName: string };
};

function usePatchAttachmentMutation() {
  const patchAttachment = usePatchAttachment();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ attachmentId, data }: UsePatchAttachmentMutationParams) => {
      const params =
        'description' in data
          ? {
              description: data.description,
              file_name: data.fileName,
            }
          : {
              type: data.type,
            };

      return patchAttachment(attachmentId, params);
    },
    {
      onSuccess(data, { attachmentId, ticketId }) {
        if (ticketId === undefined) {
          return;
        }

        queryClient.setQueryData<TicketItemReview>(
          getTicketAnalysisResultQueryKey(ticketId),
          (oldTicketData) => {
            if (oldTicketData === undefined) {
              throw new Error(`No queryData for ticket '${ticketId}'`);
            }

            return {
              ...oldTicketData,
              attachments: oldTicketData.attachments.map((attachment) => {
                if (attachment.id === attachmentId) {
                  return data;
                }
                return attachment;
              }),
            };
          }
        );
      },
    }
  );
}

export { usePatchAttachmentMutation };
