import * as React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { Box, Flex, FlexChild } from '../../layout';
import { LinkBack } from '../../link/back';
import Icon, { IconsDefault } from '../../icon';

import { ReactComponent as LogoDefault } from './logo-default.svg';
import { ReactComponent as LogoYuna } from './logo-yuna.svg';

const Container = styled(Box)(({ theme }) => ({
  boxShadow: `0 1px 3px ${rgba(theme.colors['gray-800'], 0.08)}`,
  borderBottom:
    theme.colors.header.borderColor !== 'none'
      ? `2px solid ${theme.colors.header.borderColor}`
      : undefined,
}));

const Title = styled.h3(({ theme }) => ({
  color: theme.colors.header.title,
  fontWeight: 'normal',
  fontSize: '16px',
}));

const Logo = process.env.REACT_APP_THEME === 'yuna' ? LogoYuna : LogoDefault;

const MenuHeader = () => {
  return (
    <Container bg="bg.header" px={16}>
      <Flex flexDirection="column" justifyContent="center" height={58}>
        <FlexChild alignSelf="flex-start">
          <Logo />
        </FlexChild>
      </Flex>
    </Container>
  );
};

export const MenuHeaderEditor = ({ title }: { title?: string }) => {
  return (
    <Container bg="bg.header" px={16}>
      <Flex flexDirection="row" alignItems="center" height={58}>
        <FlexChild>
          <LinkBack title="Zurück">
            <Icon
              name={IconsDefault.navigationBack}
              fill={
                process.env.REACT_APP_THEME === 'yuna' ? 'white' : 'gray-800'
              }
            />
          </LinkBack>
        </FlexChild>
        <FlexChild ml={2} pb="1px">
          <Title>{title}</Title>
        </FlexChild>

        <FlexChild ml="auto">
          <Logo />
        </FlexChild>
      </Flex>
    </Container>
  );
};

export default MenuHeader;
