import { PROPERTY_USE_ALLOWED_SUGGESTION_VALUES } from '../form-data-loader/form-data-loader.constants';
import { FormDataEntry, TicketFormData } from '../form-data/form-data.types';
import { getIn } from 'final-form';

type ValidationError = 'NOT_A_NUMBER' | 'INVALID_PROPERTY_USE';

type ValidationErrors = Partial<Record<keyof TicketFormData, ValidationError>>;

const currencyUnitFields: ReadonlyArray<keyof TicketFormData> = [
  'propertyGrossIncome',
  'propertyNetIncome',
] as const;

const areaUnitFields: ReadonlyArray<keyof TicketFormData> = [
  'propertyLandArea',
  'propertyLettableArea',
] as const;

function runUnitValidation(
  fieldKeysToValidate: ReadonlyArray<keyof TicketFormData>,
  values: TicketFormData
) {
  let errors: ValidationErrors = {};

  const possibleInvalidUnitFields: Array<keyof TicketFormData> = [];
  let unitFieldsValid = true;
  let previousUnitFieldValue: string | undefined;

  for (let index = 0; index < fieldKeysToValidate.length; index++) {
    const unitFieldsKey = fieldKeysToValidate[index];
    const unitFieldValue = getIn(values, `${unitFieldsKey}.unit`);
    const fieldValue = getIn(values, `${unitFieldsKey}.inputValue`);

    // Only run the validation against dirty fields
    if (unitFieldValue !== undefined) {
      // We can only run the validation check if we visited another field before
      if (previousUnitFieldValue !== undefined) {
        if (unitFieldValue !== previousUnitFieldValue) {
          // We found at least one mismatch
          unitFieldsValid = false;
        }
      }
      possibleInvalidUnitFields.push(unitFieldsKey);
      previousUnitFieldValue = unitFieldValue;
    } else if (fieldValue) {
      unitFieldsValid = false;
      possibleInvalidUnitFields.push(unitFieldsKey);
    }
  }

  const unitValidationResult = possibleInvalidUnitFields.map((fieldKey) => {
    return {
      key: fieldKey,
      error: unitFieldsValid ? undefined : 'INVALID_UNIT',
    };
  });

  unitValidationResult.forEach(({ key, error }) => {
    if (!!error) {
      errors = { ...errors, [key]: { inputValue: error } };
    }
  });

  return errors;
}

/**
 * Validates the inputs in the form field
 *
 * @param values input values of the form.
 * @returns Object that contains the current error message for each field where
 *     the validation has failed.
 */
function validateFormInputs(values: TicketFormData): ValidationErrors {
  let errors: ValidationErrors = {};

  for (const [_key, _value] of Object.entries(values)) {
    // Do an explicit cast here since... um yeah TypeScript things
    const key = _key as keyof TicketFormData;
    const value = _value as FormDataEntry | string;

    // We can skip the validation of values that are of type string since these
    // are computed properties that are not set by users:
    // - ticketAreaUnit
    // - ticketCurrencyUnit
    if (typeof value === 'string') {
      continue;
    }

    // Special validation for property use
    if (key === 'propertyUse') {
      // Property use must match (case-sensitive) the suggestion values
      if (
        value.inputValue &&
        PROPERTY_USE_ALLOWED_SUGGESTION_VALUES.indexOf(
          value.inputValue as any
        ) === -1
      ) {
        // @ts-ignore
        errors[key] = { inputValue: 'INVALID_PROPERTY_USE' };
      }

      continue;
    }

    if ('type' in value) {
      // if (value.type === '')

      if (value.type === 'NUMERIC') {
        // Should only contain numbers
        if (value.inputValue && !value.inputValue.match(/^[0-9.,]*$/)) {
          // @ts-ignore
          errors[key] = {
            inputValue: 'NOT_A_NUMBER',
          };
        }
      }
    }
  }

  errors = {
    ...runUnitValidation(currencyUnitFields, values),
    ...runUnitValidation(areaUnitFields, values),
    ...errors,
  };

  return errors;
}

export type { ValidationError, ValidationErrors };
export { validateFormInputs, currencyUnitFields, areaUnitFields };
