import * as React from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { TextLabelFloat } from '../../input/text-label-float';
import { Box } from '../../layout';
import { FieldLabels } from '../../../translations/form-labels';
import { useGetOffersQuery } from '../../../api/ticket/get-validated-result';

type NormalizedData =
  | string
  | {
      stringValue: string;
    }
  | { stringValue: string; numericValue: string };

type ValidatedInformationData = NonNullable<
  ReturnType<typeof useGetOffersQuery>['data']
>['validatedInformation'];

/* -----------------------------------------------------------------------------
 * Utils
 * ---------------------------------------------------------------------------*/

/**
 * Converts the array of fields that we get from the API to an object where each
 * KPI can be accessed by it's camelCased key
 */
function normalizeData(input: ValidatedInformationData) {
  const result: Record<string, NormalizedData> = {};

  for (const entry of input) {
    const camelCasedKpiKey = camelcase(entry.kpi);
    result[camelCasedKpiKey] = entry.value;
  }

  return result;
}

/* -----------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------*/

const StyledSection = styled.h3({
  margin: '0 0 6px',
  paddingLeft: '6px',
  fontSize: '14px',
});

/* -----------------------------------------------------------------------------
 * CopyField
 * ---------------------------------------------------------------------------*/

interface CopyFieldProps {
  name: keyof typeof FieldLabels;
  data: Record<string, NormalizedData>;
}

const CopyField = ({ name, data }: CopyFieldProps) => {
  const label = FieldLabels[name];
  const rawValue = data[name as string];

  const value: string = React.useMemo(() => {
    if (typeof rawValue === 'string') {
      return rawValue;
    }

    return rawValue.stringValue;
  }, [rawValue]);

  const onFocus = (event?: React.FocusEvent<HTMLInputElement>) => {
    event?.target.setSelectionRange(0, value.length);
  };

  const emptyCallback = () => {};

  return (
    <TextLabelFloat
      meta={{}}
      input={{
        name,
        value,
        onFocus,
        onBlur: emptyCallback,
        onChange: emptyCallback,
        readOnly: true,
      }}
      label={label}
    />
  );
};

/* -----------------------------------------------------------------------------
 * ModalContent
 * ---------------------------------------------------------------------------*/
interface Props {
  ticketId: string;
}

export const ReviewData = ({ ticketId }: Props) => {
  const { data, isLoading } = useGetOffersQuery({ ticketId });

  const normalizedValues = React.useMemo<Record<string, NormalizedData>>(() => {
    if (!data) {
      return {};
    }

    return normalizeData(data.validatedInformation);
  }, [data]);

  if (isLoading || !normalizedValues) {
    return null;
  }

  return (
    <>
      <Box bg="gray-200" pt={2}>
        <StyledSection>Kontakt</StyledSection>
        <CopyField name="companyName" data={normalizedValues} />
        <CopyField name="companyStreetName" data={normalizedValues} />
        <CopyField name="companyStreetNumber" data={normalizedValues} />
        <CopyField name="companyPostalCode" data={normalizedValues} />
        <CopyField name="companyCity" data={normalizedValues} />
        <CopyField name="companyCountry" data={normalizedValues} />
        <CopyField name="companyContactName" data={normalizedValues} />
        <CopyField name="companyEmail" data={normalizedValues} />
        <CopyField name="companyPhonenumber" data={normalizedValues} />
      </Box>

      <Box bg="gray-200" pt={6}>
        <StyledSection>Objekt / Gebäude</StyledSection>
        <CopyField name="propertyName" data={normalizedValues} />
        <CopyField name="propertyStreetName" data={normalizedValues} />
        <CopyField name="propertyStreetNumber" data={normalizedValues} />
        <CopyField name="propertyPostalCode" data={normalizedValues} />
        <CopyField name="propertyCity" data={normalizedValues} />
        <CopyField name="propertyCountry" data={normalizedValues} />
        <CopyField name="propertyGrossIncome" data={normalizedValues} />
        <CopyField name="propertyNetIncome" data={normalizedValues} />
        <CopyField name="propertyLandArea" data={normalizedValues} />
        <CopyField name="propertyLettableArea" data={normalizedValues} />
        <CopyField name="propertyOccupancy" data={normalizedValues} />
        <CopyField name="propertyUse" data={normalizedValues} />
        <CopyField name="propertyParkingSpaces" data={normalizedValues} />
        <CopyField name="propertyHotelRooms" data={normalizedValues} />
        <CopyField name="propertyConstructionYear" data={normalizedValues} />
      </Box>

      <Box bg="gray-200" pt={6}>
        <StyledSection>Einheiten</StyledSection>
        <CopyField name="ticketAreaUnit" data={normalizedValues} />
        <CopyField name="ticketCurrencyUnit" data={normalizedValues} />
      </Box>
    </>
  );
};
