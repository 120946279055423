import React from 'react';

import { Ticket_ticket_attachments } from '../../types/apollo/Ticket';
import styled from 'styled-components';
import { getColor } from '../layout/styled-helpers';
import SmallIconButton from '../button/icon';
import { FlexChild, Flex, StackView } from '../layout';
import { Spinner } from '../spinner';
import { DeleteAttachment } from '../../mutations/attachment';
import { UploadAttachment_uploadAttachment_duplicates } from '../../types/apollo/UploadAttachment';

export enum UploadStatus {
  uploading,
  finished,
  duplicate,
  failed,
}

export interface Attachment extends Ticket_ticket_attachments {
  uploadStatus?: UploadStatus;
  fileToUpload?: File;
  duplicateTicketIds?: UploadAttachment_uploadAttachment_duplicates[];
}

interface Props {
  files: Attachment[];
  deleteFile(fileId: string): void;
}

const FileContainer = styled.div`
  border: 1px solid ${getColor('gray-400')};
  background-color: ${getColor('gray-100')};

  width: 100%;
  position: relative;
`;

const WarningMessage = styled.div<{ error?: boolean }>`
  background-color: ${({ error }) => (error ? '#FFE1DF' : '#fff9db')};
  border: 1px solid ${({ error }) => (error ? '#EC736B' : '#ffe066')};
  color: ${({ error }) => (error ? '#DF2800' : '#5f5a42')};
  padding: 6px 12px;
  margin-top: 4px;
  font-weight: normal;
`;

const FileList = styled.ul`
  padding: 0;
  margin: 0 0 12px;
`;

const FileListItem = styled.li`
  list-style: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.06em;
  color: #000000;
  padding: 8px 12px 7px;
  margin: 0;
  border-bottom: 1px solid ${getColor('gray-300')};
  display: flex;
`;

export const UploadFileList = ({
  children,
  files,
  deleteFile,
}: React.PropsWithChildren<Props>) => {
  // Show initial upload dialog when no files are present
  if (files.length === 0) {
    return <>{children}</>;
  }

  return (
    <FileContainer>
      <FileList>
        {files.map((file) => (
          <FileListItem key={file.id}>
            <StackView>
              <Flex>
                {file.uploadStatus === UploadStatus.uploading ? (
                  <FlexChild mr={2}>
                    <Spinner />
                  </FlexChild>
                ) : null}

                {file.fileName}
                {file.uploadStatus !== UploadStatus.uploading ? (
                  <FlexChild ml="auto">
                    {file.uploadStatus === UploadStatus.failed ? (
                      <SmallIconButton
                        iconName="cross"
                        title="Datei entfernen"
                        fill="gray-500"
                        onClick={(e) => {
                          deleteFile(file.id);
                        }}
                      />
                    ) : (
                      <DeleteAttachment
                        variables={{ attachmentId: file.id }}
                        onCompleted={() => {
                          deleteFile(file.id);
                        }}
                      >
                        {(deleteAttachment) => (
                          <SmallIconButton
                            iconName="cross"
                            title="Datei entfernen"
                            fill="gray-500"
                            onClick={(e) => {
                              e.preventDefault();
                              deleteAttachment();
                            }}
                          />
                        )}
                      </DeleteAttachment>
                    )}
                  </FlexChild>
                ) : null}
              </Flex>

              {file.uploadStatus === UploadStatus.failed ? (
                <WarningMessage error>
                  Die Datei konnte nicht hochgeladen werden.
                </WarningMessage>
              ) : null}

              {file.uploadStatus === UploadStatus.duplicate ? (
                <WarningMessage>
                  Diese Datei wurde bereits hochgeladen und befindet sich in
                  folgenden Exposés:
                  <ul>
                    {file.duplicateTicketIds &&
                      file.duplicateTicketIds.map((duplicate) => (
                        <li key={duplicate.ticketId}>#{duplicate.ticketId}</li>
                      ))}
                  </ul>
                </WarningMessage>
              ) : null}
            </StackView>
          </FileListItem>
        ))}
      </FileList>

      {children}
    </FileContainer>
  );
};
