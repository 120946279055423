import gql from 'graphql-tag';

const USER_LOGIN_MUTATION = gql`
  mutation UserLogin($input: UserLoginInput!) {
    userLogin(input: $input)
    @rest(type: "UserLoginPayload", path: "auth/token", method: "POST") {
      token
    }
  }
`;

export { USER_LOGIN_MUTATION };
