import { useMutation } from 'react-query';

import { getBaseUrl } from '../../../config';
import { useFetch } from '../../../fetch';
import { camelcaseKeys } from '../../../utils';

type TokenRefreshResponse = {
  /**
   * New token that gets returned in exchange for the authorization token.
   */
  token: string;
};

const useGetTokenRefresh = () => {
  const { get } = useFetch();
  const baseUrl = getBaseUrl();

  return async (token: string) => {
    const { data } = await get<TokenRefreshResponse>(
      `${baseUrl}auth/token/refresh`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return camelcaseKeys(data);
  };
};

function useGetTokenRefreshMutation() {
  const getTokenRefresh = useGetTokenRefresh();

  return useMutation((token: string) => {
    return getTokenRefresh(token);
  });
}

export { useGetTokenRefreshMutation };
