import styled from 'styled-components';
import {
  space,
  width,
  fontSize,
  color,
  flex,
  order,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  SpaceProps,
  AlignItemsProps,
} from 'styled-system';

interface BlockPropsT extends SpaceProps {}

export const Block = styled('div')<BlockPropsT>(
  {
    boxSizing: 'border-box',
  },
  space,
  width,
  fontSize,
  color,
  flex,
  order,
  alignSelf
);

interface FlexPropsT extends AlignItemsProps {}

export const Flex = styled(Block)<FlexPropsT>(
  {
    display: 'flex',
  },
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent
);
