import * as React from 'react';
import { useReducer, useState } from 'react';
import styled from 'styled-components';

import type { FormInputUnitSelectProps } from '../form-input-unit-select';
import { getUnitForServerValue } from '../form-input-unit-select';
import { FormInputUnitSelect } from '../form-input-unit-select/form-input-unit-select';
import type { TextLabelFloatComboBoxProps } from '../input/text-label-float-combobox';
import { TextLabelFloatComboBox } from '../input/text-label-float-combobox';
import { useControllableState } from '../react-utils';

/* -----------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------*/

const StyledExtensionWrapper = styled.div({
  position: 'absolute',
  right: '12px',
  top: '10px',
});

/* -----------------------------------------------------------------------------
 * FormInputComboboxWithUnitSelect
 * ---------------------------------------------------------------------------*/

type DropdownEvent =
  | {
      type: 'SET_UNIT';
      value: boolean;
    }
  | {
      type: 'SET_COMBOBOX';
      value: boolean;
    };

type DropdownState = {
  /**
   * Indicated whether the dropdown of the unit selection is open or not
   */
  unit: boolean;
  /**
   * Indicated whether the dropdown of the combobox is open or not
   */
  combobox: boolean;
};

/**
 * Reducer that ensures that only one dropdown is open at a time
 */
function dropdownReducer(
  state: DropdownState,
  event: DropdownEvent
): DropdownState {
  switch (event.type) {
    case 'SET_UNIT': {
      return {
        combobox: false,
        unit: event.value,
      };
    }

    case 'SET_COMBOBOX': {
      return {
        combobox: event.value,
        unit: false,
      };
    }

    default: {
      return state;
    }
  }
}

const initialDropdownState: DropdownState = {
  combobox: false,
  unit: false,
};

type FormInputComboboxWithUnitSelectProps = TextLabelFloatComboBoxProps & {
  unitSelect: FormInputUnitSelectProps;
  error?: string;
};

const FormInputComboboxWithUnitSelect = ({
  unitSelect: unitSelectProps,
  error,
  ...textLabelProps
}: FormInputComboboxWithUnitSelectProps) => {
  const [unitValue, setUnitValue] = useControllableState({
    prop: unitSelectProps.value,
    defaultProp: '',
    onChange: unitSelectProps.onChange,
  });
  const [state, dispatch] = useReducer(dropdownReducer, initialDropdownState);
  const [canCall, setCanCall] = useState(true);

  // Override error here to pass the error from the fieldGroup to the field
  const meta = { ...textLabelProps.meta, error };

  return (
    <TextLabelFloatComboBox
      {...textLabelProps}
      meta={meta}
      open={state.combobox}
      onOpenChange={(value) => {
        dispatch({ type: 'SET_COMBOBOX', value });
      }}
      onSelectSuggestion={(suggestion) => {
        // Set the unit to the suggestion
        if (suggestion.value.unit) {
          // Convert the unit to internal datamodel first
          const unitId = getUnitForServerValue(
            unitSelectProps.type,
            suggestion.value.unit
          );

          if (unitId) {
            if (typeof unitId === 'string') {
              setUnitValue(unitId);
            } else {
              setUnitValue(unitId.id);
            }
          }
        }
      }}
      renderExtension={
        <StyledExtensionWrapper>
          <FormInputUnitSelect
            {...unitSelectProps}
            value={unitValue!}
            open={state.unit}
            onChange={setUnitValue}
            onOpenChange={(value) => {
              // do net set unit (= combobx is open) for 100ms after it has been set
              // to prevent dropbox closing immediatly (Bug: CRANA-352)
              if (canCall) {
                dispatch({ type: 'SET_UNIT', value });
              }
              setCanCall(false);
              setTimeout(() => {
                setCanCall(true);
              }, 200);
            }}
          />
        </StyledExtensionWrapper>
      }
    />
  );
};

export type { FormInputComboboxWithUnitSelectProps };
export { FormInputComboboxWithUnitSelect };
