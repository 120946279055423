import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FlexChild } from '../layout';
import Icon, { IconsSmall, IconSize } from '../icon';
import { getColor } from '../layout/styled-helpers';

interface Props {
  to: string;
  icon?: IconsSmall;
}

const SLink = styled(Link)`
  text-decoration: none;
  color: ${getColor('white-800')};
  font-size: 14px;
  display: flex;
  flex: 1;

  &:hover {
    text-decoration: underline;
  }
`;

// Link buttons
const ActionLink = (props: React.PropsWithChildren<Props>) => (
  <SLink to={props.to} title="Alle Exposés aus diesem Bereich anzeigen">
    <FlexChild>{props.children}</FlexChild>

    {props.icon ? (
      <FlexChild pl={1}>
        <Icon name={props.icon} size={IconSize.small} fill="white-800" />
      </FlexChild>
    ) : null}
  </SLink>
);

export { ActionLink };
