import * as React from 'react';
import { useReducer } from 'react';

import { createContext } from '../react-utils';

type ViewerSwitcherEvent =
  | {
      type: 'SHOW_PDF';
    }
  | {
      type: 'SHOW_MAP';
      props: { lat: string; lng: string };
    }
  | {
      type: 'TOGGLE_MAP';
      props: { lat: string; lng: string };
    };

type ViewerSwitcherState =
  | {
      type: 'pdf';
    }
  | {
      type: 'map';
      props: {
        lat: string;
        lng: string;
      };
    };

function viewerSwitcherReducer(
  state: ViewerSwitcherState,
  event: ViewerSwitcherEvent
): ViewerSwitcherState {
  switch (event.type) {
    case 'SHOW_MAP': {
      return {
        type: 'map',
        props: event.props,
      };
    }

    case 'TOGGLE_MAP': {
      if (state.type === 'map') {
        return {
          type: 'pdf',
        };
      }

      return {
        type: 'map',
        props: event.props,
      };
    }

    case 'SHOW_PDF': {
      return { type: 'pdf' };
    }
  }

  return state;
}

type ViewerSwitcherContextValue = {
  send(event: ViewerSwitcherEvent): void;
  state: ViewerSwitcherState;
};

const [ViewerSwitcherProvider, useViewerSwitcherContext] = createContext<
  ViewerSwitcherContextValue
>('ViewerSwitcher');

const initialViewerSwitcherState: ViewerSwitcherState = {
  type: 'pdf',
};

const ViewerSwitcher = ({ children }: React.PropsWithChildren) => {
  const [state, send] = useReducer(
    viewerSwitcherReducer,
    initialViewerSwitcherState
  );

  return (
    <ViewerSwitcherProvider send={send} state={state}>
      {children}
    </ViewerSwitcherProvider>
  );
};

type ViewerSwitcherConsumerProps = {
  children({ state }: { state: ViewerSwitcherState }): React.ReactElement;
};

const ViewerSwitcherConsumer = ({ children }: ViewerSwitcherConsumerProps) => {
  const { state } = useViewerSwitcherContext('ViewerSwitcherConsumer');
  return children({ state });
};

export {
  ViewerSwitcher,
  ViewerSwitcherConsumer,
  useViewerSwitcherContext as useViewerSwitcher,
};
