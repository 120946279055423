import styled from 'styled-components';

const StyledComboboxItem = {
  Root: styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 10px',
    borderRadius: 3,
    userSelect: 'none',
    fontWeight: 600,
    cursor: 'pointer',

    '&.focus': {
      color: '#ffffff',
      backgroundColor: '#2860E1',
    },
  }),

  ValueColumn: styled.div({
    flex: 1,
    fontSize: '14px',
  }),

  NumberRow: styled.div({
    paddingTop: '1px',
  }),

  NumberValue: styled.span(({ theme }) => ({
    color: theme.colors['gray-700'],
    fontSize: '12px',

    '.focus &': {
      color: 'rgba(255,255,255,0.8)',
    },
  })),

  UnitValue: styled.span(({ theme }) => ({
    display: 'inline-block',
    color: theme.colors['gray-700'],
    backgroundColor: theme.colors['gray-100'],
    marginLeft: '4px',
    fontSize: '10px',
    padding: '1px 3px',
    borderRadius: '3px',

    '.focus &': {
      backgroundColor: 'rgba(255,255,255,0.2)',
      color: '#ffffff',
    },
  })),

  ItemsCounter: styled.div(({ theme }) => ({
    display: 'inline-block',
    fontSize: '12px',
    color: theme.colors['gray-700'],
    backgroundColor: theme.colors['gray-100'],
    textAlign: 'center',
    padding: '6px 0',
    borderRadius: '3px',
    minWidth: '30px',
    alignSelf: 'center',

    '.focus &': {
      backgroundColor: 'rgba(255,255,255,0.2)',
      color: '#ffffff',
    },
  })),
};

export { StyledComboboxItem };
