import React from 'react';

import { FlexChild } from '../../components/layout';
import SiteTitle from '../../components/site-title';
import { TicketOverview } from '../components/overview';
import { filterTicketReview, ReviewTicketAction } from './tickets-shared';
import { emptyMessageReview } from '../../translations/empty-messages';

const siteTitle = 'Überprüfung';

const ReviewTicketsOverview = () => (
  <FlexChild mx={70} flex={1}>
    <SiteTitle title={siteTitle} />

    <TicketOverview
      filterBy={filterTicketReview}
      overrides={{ TicketAction: { component: ReviewTicketAction } }}
      emptyMessage={emptyMessageReview}
      title={siteTitle}
      hideActionBar
    />
  </FlexChild>
);

export { ReviewTicketsOverview };
