import * as React from 'react';
import styled from 'styled-components';
import { useModal } from '..';

import { FieldLabels } from '../../../translations/form-labels';
import { SubmitTicketInput } from '../../../types/apollo/globalTypes';
import { SubActionButtonCover } from '../../button/sub-action';
import { Box } from '../../layout';
import { ModalBodyWrapper } from './styles';

const MessageBox = styled.div({
  backgroundColor: '#FFE1DF',
  border: '1px solid #EC736B',
  padding: '12px',
  margin: '24px',
  color: '#DF2800',
  lineHeight: '140%',
  fontWeight: 600,
});

const Footer = styled(Box)`
  border-top: 1px solid #dbdbdb;
`;

interface ErrorMessageProps {
  formError?: { [key in keyof SubmitTicketInput]?: string };
}

export const ErrorMessageTemplate = ({
  formError,
  ...props
}: ErrorMessageProps) => {
  const { closeModal } = useModal();
  const formattedError = React.useMemo(() => {
    if (!formError) {
      return null;
    }

    return Object.keys(formError).map((key) => {
      const _key = key as keyof SubmitTicketInput;
      return (
        <div key={_key}>
          {FieldLabels[_key]}:{' '}
          <span style={{ fontWeight: 500 }}>{formError[_key]}</span>
        </div>
      );
    });
  }, [formError]);

  return (
    <ModalBodyWrapper>
      <MessageBox>
        Die Erfassung der Daten ist fehlgeschlagen.
        {formattedError ? <Box pt="5">{formattedError}</Box> : null}
      </MessageBox>

      <Footer px="5" py="4" mt="5">
        <SubActionButtonCover height="42px" onClick={() => closeModal(false)}>
          Meldung schließen
        </SubActionButtonCover>
      </Footer>
    </ModalBodyWrapper>
  );
};
