import { RestLink } from 'apollo-link-rest';

// Custom pathbuilder for filtering
const pathBuilder = (props: RestLink.PathBuilderProps) => {
  const { input } = props.args;

  const path = `${props['@rest'].path}?limit=${
    input.limit
  }&filter=${encodeURIComponent(JSON.stringify(input.filter))}`;

  return path;
};

export { pathBuilder };
