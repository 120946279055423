import * as React from 'react';
import produce from 'immer';

interface PaginationContextT {
  offset: number;
  limit: number;
}

const PaginationContext = React.createContext<PaginationContextT>({
  offset: 0,
  limit: 0,
});
const PaginationConsumer = PaginationContext.Consumer;

const DEFAULT_LIMIT = 10;

interface PaginationRenderProps {
  requestNext: () => void;
  requestPrevious: () => void;
  previousActive: boolean;
  nextActive: boolean;
  offset: number;
  limit: number;
  total: number;
  refresh: () => void;
}

interface Props {
  children: (arg: PaginationRenderProps) => React.ReactNode;
  request: (arg?: any) => any;
  maxCount: number;
  limit?: number;
}

interface State {
  offset: number;
  isLoading: boolean;
  context: PaginationContextT;
}

class Pagination extends React.Component<Props, State> {
  limit = this.props.limit || DEFAULT_LIMIT;
  state: State = {
    offset: 0,
    isLoading: false,
    context: {
      offset: 0,
      limit: this.limit,
    },
  };

  updateState = ({ offset }: { offset: number }) => {
    this.setState(
      produce((draft) => {
        draft.offset = offset;
        draft.context = {
          offset,
          limit: this.limit,
        };
      }),
      () => this.sendrequest()
    );
  };

  requestNext = () => {
    if (this.state.offset > this.props.maxCount) {
      return;
    }

    this.updateState({ offset: this.state.offset + this.limit });
  };

  requestPrevious = () => {
    if (this.state.offset === 0) {
      return;
    }

    let newOffset = this.state.offset - this.limit;
    if (this.state.offset - this.limit < 0) {
      newOffset = 0;
    }

    this.updateState({ offset: newOffset });
  };

  sendrequest = () => {
    const params = {
      offset: this.state.offset,
      limit: this.limit,
    };

    this.props.request(params);
  };

  componentDidMount() {
    this.sendrequest();
  }

  render() {
    const nextActive = this.state.offset + this.limit < this.props.maxCount;

    const renderProps: PaginationRenderProps = {
      requestNext: this.requestNext,
      requestPrevious: this.requestPrevious,
      previousActive: this.state.offset > 0,
      nextActive,
      offset: this.props.maxCount ? this.state.offset + 1 : this.props.maxCount,
      limit: nextActive ? this.state.offset + this.limit : this.props.maxCount,
      total: this.props.maxCount,
      refresh: this.sendrequest,
    };

    return (
      <PaginationContext.Provider value={this.state.context}>
        {this.props.children(renderProps)}
      </PaginationContext.Provider>
    );
  }
}

export { Pagination as default, PaginationConsumer };
