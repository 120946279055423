import * as React from 'react';

import SiteTitle from '../../components/site-title';
import { TicketOverview } from '../components/overview';
import { NewTicketaction, filterTicketNew } from './tickets-shared';
import { emptyMessageNew } from '../../translations/empty-messages';
import { Block } from '../../lib/block';

const PageTitle = 'Neue Exposés';

export const NewTicketsOverview = () => (
  <Block mx={70}>
    <SiteTitle title={PageTitle} />

    <TicketOverview
      filterBy={filterTicketNew}
      overrides={{
        TicketAction: {
          component: NewTicketaction,
        },
      }}
      emptyMessage={emptyMessageNew}
      title={PageTitle}
    />
  </Block>
);
