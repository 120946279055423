import * as React from 'react';
import styled from 'styled-components';

import { FlexChild } from '../../components/layout';
import SiteTitle from '../../components/site-title';
import { TicketOverview } from '../components/overview';
import { TicketStatus } from '../../types/apollo/globalTypes';
import { FinishedTicketAction } from './tickets-shared';
import { getColor } from '../../components/layout/styled-helpers';
import { getAccessToken } from '../../utils/access-token';
import { emptyMessageFinished } from '../../translations/empty-messages';
import { getBaseUrl } from '../../api/config';

const filterByStatus = [TicketStatus.done];

const DownloadButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${getColor('white-800')};
  padding: 6px 0;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.06em;

  &:hover {
    text-decoration: underline;
  }
`;

const ActionBar = () => {
  const baseURL = getBaseUrl();

  return (
    <DownloadButton
      onClick={() => {
        const token = getAccessToken();

        const oReq = new XMLHttpRequest();
        oReq.open('GET', `${baseURL}results/?format=csv`, true);
        oReq.setRequestHeader(
          'Authorization',
          `Token ${token ? token.token : ''}`
        );
        oReq.responseType = 'blob';

        oReq.onload = function () {
          let filename = 'download.csv';
          const disposition = oReq.getResponseHeader('Content-Disposition')!;

          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '').trim();
          }

          const file = oReq.response;
          const localFileUrl = window.URL.createObjectURL(file);
          const downloadLink = document.createElement('a');

          document.body.appendChild(downloadLink);
          downloadLink.setAttribute('style', 'display: none');

          downloadLink.href = localFileUrl;
          downloadLink.download = filename;
          downloadLink.click();

          // Cleanup
          window.URL.revokeObjectURL(localFileUrl);
          document.removeChild(downloadLink);
        };

        oReq.send();
      }}
    >
      Alle Ergebnisse herunterladen
    </DownloadButton>
  );
};

const siteTitle = 'Abgeschlossen';

const FinishedTicketsOverview = () => (
  <FlexChild mx={70} flex={1}>
    <SiteTitle title={siteTitle} />

    <TicketOverview
      filterBy={filterByStatus}
      overrides={{
        TicketAction: { component: FinishedTicketAction },
        ActionBar: { component: ActionBar },
      }}
      emptyMessage={emptyMessageFinished}
      title={siteTitle}
      hideActionBar
    />
  </FlexChild>
);

export { FinishedTicketsOverview };
