const colorPrimary = '#FFCC33';
const colorGray = {
  '000': '#FCFCFC',
  '100': '#F7F7F7',
  '200': '#F0F0F0',
  '300': '#E5E5E5',
  '400': '#CCCCCC',
  '500': '#B3B3B3',
  '600': '#999999',
  '700': '#666666',
  '800': '#333333',
};

const theme = {
  colors: {
    button: {
      backgroundActive: colorPrimary,
      background: '#989898',
    },

    bg: {
      header: '#ffffff',
      main:
        'linear-gradient(180deg, #586873 5.76%, #748ea3 74.82%, #726a55 99.04%)',
      glowBox: 'rgba(255, 255, 255, 0.05)',
      buttonPrimary: colorPrimary,
      buttonAction: '#00a9e0',
      highlight: colorPrimary,
    },

    error: {
      text: '#DF0000',
      border: '#DF0000',
      bg: '#DF0000',
    },

    // Components
    header: {
      title: colorGray['800'],
      borderColor: colorPrimary,
    },

    text: {
      heading: '#ffffff',
      headingSecondary: 'rgba(255, 255, 255, 0.8)',
      main: colorGray['200'],
      buttonPrimary: '#635017',
      highlight: '#5b5b5b',
    },

    // Legacy

    // White
    'white-500': 'rgba(255,255,255, 0.5)',
    'white-800': 'rgba(255,255,255, 0.8)',

    // Grays
    'gray-000': '#FCFCFC',
    'gray-100': '#F7F7F7',
    'gray-200': '#F0F0F0',
    'gray-300': '#E5E5E5',
    'gray-400': '#CCCCCC',
    'gray-500': '#B3B3B3',
    'gray-600': '#999999',
    'gray-700': '#666666',
    'gray-800': '#333333',

    // Blue
    'blue-500': '#059ACB',
    'blue-400': '#00a9e0',

    // Reds
    'red-300': '#EC736B',
    'red-500': '#DF2800',

    // Yellow
    'yellow-500': colorPrimary,

    // Basic colors
    white: '#ffffff',
    black: '#000000',
  },

  // margins & paddings
  space: {
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '24px',
    6: '32px',
  },
};

export type BaseTheme = typeof theme;

export default theme;
