import * as React from 'react';

import { Tickets_tickets_results } from '../../types/apollo/Tickets';
import { TicketStatus } from '../../types/apollo/globalTypes';
import {
  TicketActionAnalysis,
  TicketActionDraft,
  TicketActionReview,
  TicketActionResults,
  TicketActionInReview,
  TicketActionFailed,
  FloatedFlex,
} from '../components/ticket/ticket-list-actions';
import { useCurrentUser } from '../../components/current-user';
import { Spinner } from '../../components/spinner';

const TICKET_LOCK_DURATION_IN_MINUTES = 15;

////////////////////////////////////////////////////////////////////////
// New tickets

export const filterTicketNew = [TicketStatus.draft];

interface ActionProps {
  ticket: Tickets_tickets_results;
}

interface NewActionProps
  extends React.ComponentProps<typeof TicketActionAnalysis> {
  ticket: Tickets_tickets_results;
}

export const NewTicketaction = ({
  ticket,
  onUpdate,
  onDelete,
}: NewActionProps) => {
  const isComplete =
    ticket.status === TicketStatus.draft && ticket.attachments.length > 0;

  if (isComplete) {
    return (
      <TicketActionAnalysis
        ticket={ticket}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
    );
  } else {
    return <TicketActionDraft ticket={ticket} onDelete={onDelete} />;
  }
};

////////////////////////////////////////////////////////////////////////
// Analysis tickets

export const filterTicketAnalyis = [TicketStatus.analysis, TicketStatus.failed];

export const AnalysisTicketAction = ({
  ticket,
  onUpdate,
  onDelete,
}: NewActionProps) => {
  if (ticket.status === TicketStatus.failed) {
    return (
      <TicketActionFailed
        ticket={ticket}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
    );
  }

  // Show loading spinner to indicate that task is running
  return (
    <FloatedFlex>
      <Spinner />
    </FloatedFlex>
  );
};

////////////////////////////////////////////////////////////////////////
// Review tickets

export const filterTicketReview = [TicketStatus.review];

export const ReviewTicketAction = ({ ticket }: ActionProps) => {
  const { user: currentUser } = useCurrentUser();

  // Check if ticket is under review
  if (ticket.inReviewSince && ticket.inReviewBy && currentUser) {
    const inReviewSince = new Date(ticket.inReviewSince);
    // Check if time has already run out
    if (
      new Date() <
      new Date(
        inReviewSince.getTime() + TICKET_LOCK_DURATION_IN_MINUTES * 60000
      )
    ) {
      // Check if the user which has locked the ticket is the same as the current user
      if (ticket.inReviewBy.id !== currentUser.id) {
        return <TicketActionInReview ticket={ticket} />;
      }
    }
  }

  return <TicketActionReview ticket={ticket} />;
};

export const FinishedTicketAction = ({ ticket }: ActionProps) => (
  <TicketActionResults ticket={ticket} />
);
