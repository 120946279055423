import styled, { css } from 'styled-components';

export const TicketRow = styled.tr`
  & > td {
    background-color: #ffffff;
  }

  *:first-child > &:first-child > td:first-child {
    border-top-left-radius: 6px;
  }

  *:first-child > &:first-child > td:last-child {
    border-top-right-radius: 6px;
  }

  *:last-child > tbody > &:last-child > td:first-child {
    border-bottom-left-radius: 6px;
  }

  *:last-child > tbody > &:last-child > td:last-child {
    border-bottom-right-radius: 6px;
  }
`;

interface TicketColProps {
  width?: string;
}

export const TicketCol = styled.td<TicketColProps>`
  padding: 11px 24px 12px;

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;
