import * as React from 'react';
import { useCallback, useEffect } from 'react';

import { useControllableState } from '../../hooks/use-controllable-state';

import { composeEventHandlers, createContext } from '../react-utils';

/* -----------------------------------------------------------------------------
 * SelectMenu
 * ---------------------------------------------------------------------------*/

const SELECT_MENU_NAME = 'SelectMenu';

type SelectMenuRootContextValue = {
  open: boolean;
  triggerRef: React.RefObject<HTMLButtonElement>;
  modalRef: React.RefObject<HTMLDivElement>;
  onOpenChange(open: boolean): void;
  onOpenToggle(): void;
};

const [MenuProvider, useMenuContext] = createContext<
  SelectMenuRootContextValue
>(SELECT_MENU_NAME);

type SelectMenuProps = {
  open?: boolean;
  onOpenChange?(isOpen: boolean): void;
};

const SelectMenu = ({
  children,
  open: externalOpen,
  onOpenChange,
}: React.PropsWithChildren<SelectMenuProps>) => {
  const [open, setOpen] = useControllableState({
    prop: externalOpen,
    defaultProp: false,
    onChange: onOpenChange,
  });
  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const handleOpenToggle = useCallback(
    () =>
      setOpen((prevOpen) => {
        return !prevOpen;
      }),
    [setOpen]
  );

  return (
    <MenuProvider
      open={open!}
      triggerRef={triggerRef}
      modalRef={modalRef}
      onOpenChange={setOpen}
      onOpenToggle={handleOpenToggle}
    >
      {children}
    </MenuProvider>
  );
};

SelectMenu.displayName = SELECT_MENU_NAME;

/* -----------------------------------------------------------------------------
 * Trigger
 * ---------------------------------------------------------------------------*/

const TRIGGER_NAME = 'SelectMenuTrigger';
const TRIGGER_OPEN_KEYS = [' ', 'Enter', 'ArrowDown'];

interface TriggerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Trigger = (props: TriggerProps) => {
  const context = useMenuContext(TRIGGER_NAME);

  return (
    <button
      {...props}
      ref={context.triggerRef}
      onMouseDown={composeEventHandlers(props.onMouseDown, (event) => {
        context.onOpenToggle();
      })}
      onKeyDown={composeEventHandlers(
        props.onKeyDown,
        (event: React.KeyboardEvent) => {
          if (TRIGGER_OPEN_KEYS.includes(event.key)) {
            event.preventDefault();
            context.onOpenChange(true);
          }
        }
      )}
    ></button>
  );
};

Trigger.displayName = TRIGGER_NAME;

/* -----------------------------------------------------------------------------
 * Modal
 * ---------------------------------------------------------------------------*/

const MODAL_NAME = 'SelectMenuModal';

interface ModalProps extends React.HTMLAttributes<HTMLElement> {
  onMouseDownInside?(event: MouseEvent): void;
}

const Modal = ({ onMouseDownInside, ...restProps }: ModalProps) => {
  const { open, triggerRef, modalRef, onOpenChange } = useMenuContext(
    MODAL_NAME
  );

  useEffect(
    () => {
      const handleMouseDownInside = composeEventHandlers(
        onMouseDownInside,
        () => {
          // Per default close the modal when a click inside is detected
          onOpenChange(false);
        }
      );

      function handleMouseDown(event: MouseEvent) {
        const { target } = event;
        // Prevent the close when it is fired by the trigger button
        // Otherwise it would close and reopen immediately
        if (target !== triggerRef.current) {
          // Check if MouseDown event was inside of the modal
          // https://stackoverflow.com/a/48475806/831465
          if (target instanceof Node && modalRef.current?.contains(target)) {
            handleMouseDownInside(event);
          } else {
            onOpenChange(false);
          }
        }
      }

      if (open) {
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
          document.removeEventListener('mousedown', handleMouseDown);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, onOpenChange]
  );

  if (!open) {
    return null;
  }

  return <div ref={modalRef} {...restProps}></div>;
};

Modal.displayName = MODAL_NAME;

export { SelectMenu as Root, Trigger, Modal };
