/**
 * Override of the action button component with custom styling
 */

import React from 'react';
import { css } from 'styled-components';

import { ActionButton } from '../../lib/buttons/action-button';
import { getColor } from '../layout/styled-helpers';
import { Spinner } from '../spinner';
import { ActionButtonProps } from '../../lib/buttons/action-button/action-button';

const highlightButtonStyle = css`
  background-color: ${getColor('blue-400')};
  color: #ffffff;
  text-transform: none;

  &:hover {
    background-color: ${getColor('blue-500')};
  }
`;

interface Props extends ActionButtonProps {
  loading?: boolean;
}

export const ActionButtonHighlight = ({
  children,
  loading,
  ...props
}: Props) => (
  <ActionButton
    {...props}
    icon={loading}
    iconPosition="left"
    overrides={{
      Root: {
        style: highlightButtonStyle,
      },
      Icon: {
        component: loading ? Spinner : undefined,
      },
    }}
  >
    {children}
  </ActionButton>
);

export default ActionButtonHighlight;
