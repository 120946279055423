export interface AccessToken {
  token: string;
  expiresAt: Date;
  hostName: string;
}

export const removeAccessToken = () => {
  localStorage.removeItem('accessToken');
};

export const setAccessToken = (token: string, expireDate: Date) => {
  const accessToken: AccessToken = {
    token,
    expiresAt: expireDate,
    hostName: process.env.REACT_APP_HOSTNAME || '',
  };

  localStorage.setItem('accessToken', JSON.stringify(accessToken));
};

export const getAccessToken = (): AccessToken | null => {
  const store = localStorage.getItem('accessToken');

  if (store) {
    const accessToken: AccessToken = JSON.parse(store);

    // Check if token is expired
    if (new Date() > new Date(accessToken.expiresAt)) {
      return null;
    }

    // Check if hostname has changed
    if (accessToken.hostName !== process.env.REACT_APP_HOSTNAME) {
      return null;
    }

    return accessToken;
  }

  return null;
};
