import { useEffect, useRef } from 'react';
import { EffectReducer, useEffectReducer } from 'use-effect-reducer';

type AutocompleteState =
  | {
      value: 'initial';
    }
  | {
      value: 'showSuggestions';
    }
  | {
      value: 'validSuggestionSelected';
    }
  | {
      value: 'showSuggestionsValid';
    }
  | {
      value: 'manualEntry';
    }
  | {
      value: 'validating';
    }
  | {
      value: 'validManualEntry';
    }
  | {
      value: 'invalidManualEntry';
    };

type AutocompleteEvent =
  | { type: 'EXTERNAL_CLOSE' }
  | {
      type: 'FIELD_ON_FOCUS';
    }
  | {
      type: 'SELECT_SUGGESTION';
    }
  | {
      type: 'FIELD_MANUAL_CHANGE';
    }
  | {
      type: 'FIELD_ON_CLICK';
    }
  | {
      type: 'FIELD_ON_BLUR';
    }
  | {
      type: 'FIELD_ON_ESCAPE_KEY';
    }
  | {
      type: 'FIELD_ON_ARROW_DOWN_KEY';
    }
  | {
      type: 'FIELD_IS_VALID';
    }
  | {
      type: 'FIELD_IS_INVALID';
    };

type AutocompleteEffect = {
  type: 'checkOpenChange';
};

/**
 * States where the combobox should be open
 */
const openStates = ['showSuggestions', 'showSuggestionsValid'] as const;

/**
 * Autocomplete reducer which handles the open / close state of the combobox
 */
const autocompleteReducer: EffectReducer<
  AutocompleteState,
  AutocompleteEvent,
  AutocompleteEffect
> = (state, event, exec) => {
  switch (state.value) {
    case 'initial': {
      switch (event.type) {
        case 'FIELD_ON_FOCUS':
        case 'FIELD_ON_CLICK':
        case 'FIELD_ON_ARROW_DOWN_KEY':
          exec({ type: 'checkOpenChange' });
          return {
            value: 'showSuggestions',
          };
      }

      break;
    }

    case 'showSuggestions': {
      switch (event.type) {
        case 'SELECT_SUGGESTION':
          exec({ type: 'checkOpenChange' });
          return {
            value: 'validSuggestionSelected',
          };

        case 'FIELD_MANUAL_CHANGE':
          exec({ type: 'checkOpenChange' });
          return {
            value: 'manualEntry',
          };

        case 'FIELD_ON_BLUR':
        case 'FIELD_ON_ESCAPE_KEY':
        case 'FIELD_ON_CLICK':
          exec({ type: 'checkOpenChange' });
          return {
            value: 'initial',
          };

        case 'EXTERNAL_CLOSE':
          // Intentionally not calling `checkOpenChange` effect here
          // because the change should not be propagated back to the controlled
          // prop change
          return {
            value: 'initial',
          };
      }

      break;
    }

    case 'validSuggestionSelected': {
      switch (event.type) {
        case 'FIELD_MANUAL_CHANGE':
          exec({ type: 'checkOpenChange' });
          return {
            value: 'manualEntry',
          };

        case 'FIELD_ON_CLICK':
        case 'FIELD_ON_ARROW_DOWN_KEY':
          exec({ type: 'checkOpenChange' });
          return {
            value: 'showSuggestionsValid',
          };
      }

      break;
    }

    case 'showSuggestionsValid': {
      switch (event.type) {
        case 'SELECT_SUGGESTION':
        case 'FIELD_ON_CLICK':
        case 'FIELD_ON_BLUR':
        case 'FIELD_ON_ESCAPE_KEY':
          exec({ type: 'checkOpenChange' });
          return {
            value: 'validSuggestionSelected',
          };

        case 'EXTERNAL_CLOSE':
          // Intentionally not calling `checkOpenChange` effect here
          // because the change should not be propagated back to the controlled
          // prop change
          return {
            value: 'validSuggestionSelected',
          };

        case 'FIELD_MANUAL_CHANGE': {
          exec({ type: 'checkOpenChange' });
          return {
            value: 'manualEntry',
          };
        }
      }

      break;
    }

    case 'manualEntry': {
      switch (event.type) {
        case 'FIELD_ON_CLICK':
        case 'FIELD_MANUAL_CHANGE':
          // TODO: Set to initial if textField is empty on manual change
          exec({ type: 'checkOpenChange' });
          return {
            value: 'showSuggestions',
          };

        case 'FIELD_ON_BLUR':
          exec({ type: 'checkOpenChange' });
          return { value: 'validating' };
      }

      break;
    }

    case 'validating': {
      switch (event.type) {
        case 'FIELD_IS_VALID':
          exec({ type: 'checkOpenChange' });
          return { value: 'validManualEntry' };

        case 'FIELD_IS_INVALID':
          exec({ type: 'checkOpenChange' });
          return {
            value: 'invalidManualEntry',
          };
      }

      break;
    }

    default:
      return state;
  }

  return state;
};

function checkIsOpen(openState: AutocompleteState) {
  return openStates.some((state) => state === openState.value);
}

const initialState: AutocompleteState = {
  value: 'initial',
};

function useComboboxState(
  controlledOpen?: boolean,
  onOpenChange?: (isOpen: boolean) => void
) {
  const previousControlledOpen = useRef<boolean>();
  const [openState, send] = useEffectReducer(
    autocompleteReducer,
    initialState,
    {
      checkOpenChange: (state) => {
        const isOpen = checkIsOpen(state);

        if (onOpenChange && isOpen !== controlledOpen) {
          onOpenChange(isOpen);
        }
      },
    }
  );
  const isOpen = checkIsOpen(openState);

  /**
   * Detect when the controlled prop changes from true -> false
   */
  useEffect(() => {
    if (previousControlledOpen.current === true && controlledOpen === false) {
      send({
        type: 'EXTERNAL_CLOSE',
      });
    }

    previousControlledOpen.current = controlledOpen;
  }, [controlledOpen, send]);

  if (controlledOpen !== undefined) {
    return {
      open: controlledOpen,
      send,
    };
  }

  return {
    open: isOpen,
    send,
  };
}

export { useComboboxState };
