import React from 'react';
import { Field } from 'react-final-form';

import { Box } from '../../layout';
import { Wizard } from '../../form/wizard';
import {
  CreateTicket,
  UpdateTicket,
  TICKET_CREATE,
  TICKET_UPDATE,
} from '../../../mutations/tickets/ticket';
import { UploadForm } from '../../form/upload';
import { TicketQuery, TICKET_QUERY } from '../../../queries/tickets/ticket';
import { Ticket_ticket } from '../../../types/apollo/Ticket';
import {
  TextLabelExternal,
  ExternalLabel,
} from '../../input/text-label-external';
import { useModal } from '..';
import { ModalBodyWrapper } from './styles';

interface UpdateTicketTemplateProps {
  ticketId?: string;
}

const UpdateTicketTemplate = (props: UpdateTicketTemplateProps) => {
  const isEditor = typeof props.ticketId !== 'undefined';

  const { closeModal } = useModal();

  return (
    <ModalBodyWrapper>
      <Box my="5">
        {/* When the ticketId is given in props we need to fetch the ticket first  */}
        <TicketQuery
          query={TICKET_QUERY}
          skip={!isEditor}
          variables={{ ticketId: props.ticketId || '' }}
        >
          {({ data, loading }) => {
            let initialValues = {
              title: '',
            };

            if (loading) {
              return null;
            }

            if (isEditor && data) {
              initialValues.title = data.ticket.title;
            }

            return (
              <CreateTicket mutation={TICKET_CREATE}>
                {(createTicket, { data: createTicketResponse }) => (
                  <UpdateTicket mutation={TICKET_UPDATE}>
                    {(updateTicket) => {
                      let ticket: Ticket_ticket | undefined;

                      if (isEditor && data) {
                        ticket = data.ticket;
                      }

                      // @TODO: Set the ticketId to props when ticket is not created
                      let ticketId = ticket ? ticket.id : '';

                      if (createTicketResponse) {
                        ticketId =
                          createTicketResponse &&
                          createTicketResponse.createTicket.id;
                      }

                      return (
                        <Wizard
                          initialValues={initialValues}
                          startAtPage={isEditor ? 1 : 0}
                          onSubmit={async (values, step) => {
                            switch (step) {
                              case 0: {
                                // Create new ticket
                                try {
                                  await createTicket({
                                    variables: {
                                      input: { title: values.title },
                                    },
                                  });
                                } catch (err) {
                                  return {
                                    title:
                                      'Die Eingabe für Titel ist nicht korrekt.',
                                  };
                                }

                                break;
                              }

                              case 1: {
                                // Update the ticket title
                                try {
                                  await updateTicket({
                                    variables: {
                                      ticketId,
                                      input: {
                                        title: values.title,
                                      },
                                    },
                                  });
                                } catch (err) {
                                  return {
                                    title:
                                      'Die Eingabe für Titel ist nicht korrekt.',
                                  };
                                }

                                // Close the modal
                                closeModal(false);
                              }
                            }
                          }}
                        >
                          {() => [
                            <Wizard.Page>
                              <Box mx="5">
                                <Field
                                  name="title"
                                  label="Titel"
                                  component={TextLabelExternal}
                                  placeholder="Titel des Exposés angeben"
                                />
                              </Box>
                            </Wizard.Page>,
                            <Wizard.Page>
                              <Box mx="5" mt="3">
                                <ExternalLabel>
                                  <span className="label">
                                    Dateien für die Extraktion
                                  </span>
                                </ExternalLabel>

                                <UploadForm
                                  ticketId={ticketId}
                                  attachments={
                                    ticket ? ticket.attachments : undefined
                                  }
                                />
                              </Box>
                            </Wizard.Page>,
                          ]}
                        </Wizard>
                      );
                    }}
                  </UpdateTicket>
                )}
              </CreateTicket>
            );
          }}
        </TicketQuery>
      </Box>
    </ModalBodyWrapper>
  );
};

export { UpdateTicketTemplate };
