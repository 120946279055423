/**
 * Handles the annotations on the pdf pages
 */

import produce from 'immer';

const initialState = {
  visible: false,
  annotations: {},
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'ANNOTATIONS_CHANGE': {
        draft.annotations = action.annotations;

        break;
      }

      // No default
      default:
    }
  });

const getAnnotationsByPage = (state, pageNumber) =>
  state.annotations[pageNumber];

export { reducer as default, getAnnotationsByPage };
