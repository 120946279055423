/**
 * Defines the groups of suggestions
 */
const ESTATE_ATTRIBUTE_PROPERTIES = [
  'company_name',
  'company_contact_name',
  'address',
  'area',
  'construction_year',
  'email',
  'hotel_rooms',
  'occupancy',
  'parking_spaces',
  'phonenumber',
  'property_use',
  'rental_cost',
] as const;

type PropertyUseAllowedSuggestionValues =
  | 'Büro'
  | 'Gewerbe'
  | 'Handel'
  | 'Hotel'
  | 'Logistik'
  | 'Wohnen'
  | 'Sonstiges';

/**
 * Sorted unit values for displaying the suggestions for the `property_use` KPI.
 * For a full list of available values from the Api see:
 * https://gitlab.local.eoda.de/exposee-scanner/analyse/-/blob/master/eoda_exposee_scanner/units.py#L79
 */
const PROPERTY_USE_ALLOWED_SUGGESTION_VALUES = [
  'Büro',
  'Gewerbe',
  'Handel',
  'Hotel',
  'Logistik',
  'Wohnen',
  // `Sonstiges` is intentionally not part of the alphabetical sort, and should
  // be positioned at the end of the list.
  'Sonstiges',
] as const;

export type { PropertyUseAllowedSuggestionValues };
export { ESTATE_ATTRIBUTE_PROPERTIES, PROPERTY_USE_ALLOWED_SUGGESTION_VALUES };
