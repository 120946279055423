/**
 * Wrapper component which is used for to prevent of passing down styledOverride props to
 * the child component
 */

import React from 'react';

interface OverrideWrapperProps {
  styledOverrides?: string;
  // Other override props, could be anything
  [key: string]: any;
}

type OverrideWrapperT = (
  Component: React.ComponentType<any>
) => React.FC<OverrideWrapperProps>;

export const withStyledOverride: OverrideWrapperT = (Component) => ({
  styledOverrides,
  ...props
}) => <Component {...props} />;
