import {
  getServerAreaDimensionUnitForLocalValue,
  getServerCurrencyUnitForLocalValue,
} from '../form-input-unit-select/form-input-unit-select.constants';
import { TicketFormData } from './form-data.types';

interface PostValidateResultPayloadStringValue {
  stringValue: string;
}

interface PostValidateResultPayloadNumberValue {
  stringValue: string;
  numberValue: number;
}

interface PostValidateResultPayload {
  companyName: PostValidateResultPayloadStringValue;
  companyStreetName: PostValidateResultPayloadStringValue;
  companyStreetNumber: PostValidateResultPayloadStringValue;
  companyCity: PostValidateResultPayloadStringValue;
  companyPostalCode: PostValidateResultPayloadStringValue;
  companyCountry: PostValidateResultPayloadStringValue;
  companyLocationLat: PostValidateResultPayloadNumberValue;
  companyLocationLng: PostValidateResultPayloadNumberValue;
  companyEmail: PostValidateResultPayloadStringValue;
  companyPhonenumber: PostValidateResultPayloadStringValue;
  companyContactName: PostValidateResultPayloadStringValue;
  propertyName: PostValidateResultPayloadStringValue;
  propertyStreetName: PostValidateResultPayloadStringValue;
  propertyStreetNumber: PostValidateResultPayloadStringValue;
  propertyCity: PostValidateResultPayloadStringValue;
  propertyPostalCode: PostValidateResultPayloadStringValue;
  propertyCountry: PostValidateResultPayloadStringValue;
  propertyLocationLat: PostValidateResultPayloadNumberValue;
  propertyLocationLng: PostValidateResultPayloadNumberValue;
  propertyUse: PostValidateResultPayloadStringValue;
  propertyConstructionYear: PostValidateResultPayloadNumberValue;
  propertyLandArea: PostValidateResultPayloadNumberValue;
  propertyLettableArea: PostValidateResultPayloadNumberValue;
  propertyParkingSpaces: PostValidateResultPayloadNumberValue;
  propertyHotelRooms: PostValidateResultPayloadNumberValue;
  propertyOccupancy: PostValidateResultPayloadNumberValue;
  propertyNetIncome: PostValidateResultPayloadNumberValue;
  propertyGrossIncome: PostValidateResultPayloadNumberValue;
  ticketAreaUnit: string;
  ticketCurrencyUnit: string;
}

const currencyUnitFieldNames = {
  propertyNetIncome: true,
  propertyGrossIncome: true,
} as const;

const areaUnitFieldNames = {
  propertyLettableArea: true,
  propertyLandArea: true,
} as const;

function normalizeFormDataToValidateResultPayload(
  input: TicketFormData
): PostValidateResultPayload {
  const result: any = {};
  let ticketAreaUnit = '';
  let ticketCurrencyUnit = '';

  const inputKeys = Object.keys(input) as Array<keyof TicketFormData>;
  for (const inputKey of inputKeys) {
    const inputValue = input[inputKey];

    if (typeof inputValue === 'string') {
      result[inputKey] = inputValue;
      continue;
    }

    // Extract units from fields

    // Area unit
    if (inputKey in areaUnitFieldNames && inputValue.unit) {
      const serverAreaUnitMaybe = getServerAreaDimensionUnitForLocalValue(
        inputValue.unit
      );

      if (serverAreaUnitMaybe) {
        ticketAreaUnit = serverAreaUnitMaybe;
      }
    }

    // Currency unit
    if (inputKey in currencyUnitFieldNames && inputValue.unit) {
      const serverCurrencyUnitMaybe = getServerCurrencyUnitForLocalValue(
        inputValue.unit
      );

      if (serverCurrencyUnitMaybe) {
        ticketCurrencyUnit = serverCurrencyUnitMaybe;
      }
    }

    // Check if a suggestion was selected
    if (inputValue.suggestion) {
      if (inputValue.type === 'NUMERIC') {
        result[inputKey] = {
          stringValue: inputValue.suggestion.originalValue,
          numberValue: inputValue.suggestion.number ?? 0,
        } as PostValidateResultPayloadNumberValue;
        continue;
      }

      if (inputValue.type === 'STRING') {
        result[inputKey] = {
          stringValue: inputValue.suggestion.originalValue,
        } as PostValidateResultPayloadStringValue;
      }
    }

    if (inputValue.type === 'NUMERIC') {
      let numberValue = 0;

      try {
        // Try to parse the number value from the input field
        const numberValueMaybe = parseFloat(inputValue.inputValue);
        if (!isNaN(numberValueMaybe)) {
          numberValue = numberValueMaybe;
        }
      } catch (err) {}

      result[inputKey] = {
        stringValue: inputValue.inputValue,
        numberValue,
      } as PostValidateResultPayloadNumberValue;
      continue;
    }

    if (inputValue.type === 'STRING') {
      result[inputKey] = {
        stringValue: inputValue.inputValue,
      } as PostValidateResultPayloadStringValue;
    }
  }

  // Set the document units
  result['ticketAreaUnit'] = ticketAreaUnit;
  result['ticketCurrencyUnit'] = ticketCurrencyUnit;

  return result as PostValidateResultPayload;
}

export { normalizeFormDataToValidateResultPayload };
export type { PostValidateResultPayload };
