import { useQuery } from 'react-query';
import { TicketItemReview, useGetTicket } from './get-ticket';

const QUERY_KEY = 'analysisResult';

function getTicketAnalysisResultQueryKey(ticketId: number) {
  return [QUERY_KEY, ticketId];
}

type UseGetTicketQueryProps = {
  ticketId: number;
};

/**
 * Get a ticket by its id.
 * Also ensures that the status of the ticket is 'review'.
 *
 * @param options
 * @returns
 */
function useGetTicketAnalysisResultQuery({ ticketId }: UseGetTicketQueryProps) {
  const getTicket = useGetTicket();

  // Using custom data type here since the response contains placeholder items
  // that are added by react-query mutation
  return useQuery<unknown, unknown, TicketItemReview>(
    getTicketAnalysisResultQueryKey(ticketId),
    async () => {
      const ticket = await getTicket({ ticketId });

      if (ticket.status !== 'review') {
        throw new Error(
          `Requested ticket (${ticketId}) is not in status 'review'.`
        );
      }

      return {
        ...ticket,
        // Attachments are returned from the backend ASC (createDate), but we
        // want to display new snippets at the top, so we reverse the order here
        // TODO: Change order in response from backend.
        attachments: ticket.attachments.reverse(),
      };
    }
  );
}

export { useGetTicketAnalysisResultQuery, getTicketAnalysisResultQueryKey };
