import { createStore, combineReducers } from 'redux';

// Import all reducers
import pdfAnnotations from './pdf-annotations/reducer';

const rootReducer = combineReducers({
  pdfAnnotations,
});

const store = createStore(rootReducer);

export default store;
