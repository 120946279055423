import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { TicketsVariables, Tickets } from '../../types/apollo/Tickets';
import { DashboardTicketFragment } from '../dashboard/tickets';

const TICKETS_FILTERED_BY_STATUS = gql`
  query Tickets(
    $limit: Int = 15
    $offset: Int = 0
    $filter: [TicketStatus]!
    $pathBuilder: Any!
  ) {
    tickets: tickets(
      input: { limit: $limit, offset: $offset, filter: { status: $filter } }
    )
    @rest(type: "TicketsPayload", path: "tickets/", pathBuilder: $pathBuilder) {
      count

      results @type(name: "Ticket") {
        ...DashboardTicket
      }
    }
  }

  ${DashboardTicketFragment}
`;

class TicketsQuery extends Query<Tickets, TicketsVariables> {}

export { TICKETS_FILTERED_BY_STATUS, TicketsQuery };
