import * as React from 'react';
import styled from 'styled-components';

import Accordion from '../../../../components/accordion';
import { single } from '../../../../components/accordion/shared';
import { TransparentButton } from '../../../../components/button/transparent';
import Icon, { IconSize, IconsSmall } from '../../../../components/icon';

const Header = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  & > li > button {
    background-color: rgba(0, 0, 0, 0.15);
    color: #ffffff;
    display: block;
    width: 100%;
    padding: 18px 12px 17px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.06em;
    cursor: pointer;
    text-align: left;
  }
`;

const Files = styled.ul(({ theme }) => ({
  margin: 0,
  padding: 0,
  listStyle: 'none',

  '& > li > button': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    display: 'block',
    padding: '18px 12px 17px',
    color: '#dadada',
    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: '0.06em',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'left',

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },

    '&.selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      borderLeft: `3px solid ${theme.colors.bg.buttonPrimary}`,
    },
  },
}));

const Counter = styled.span`
  float: right;
  font-size: 12px;
  color: #dadada;
`;

interface File {
  title: string;
  id: number;
}

interface Props {
  files: File[];
  selectedIndex: number;
  onChange: (fileId: number) => void;
}

const accordionIndex = '0';

const FileList = (props: Props) => {
  // Collapse file menu when we have only one file to show
  const initalOpenIndexes =
    props.files.length > 1 ? [accordionIndex] : undefined;

  return (
    <Accordion stateReducer={single} initialOpenIndexes={initalOpenIndexes}>
      {({ openIndexes, handleItemClick }) => {
        const isOpen = openIndexes.includes(accordionIndex);
        const currentFile = props.files[props.selectedIndex];
        const title = isOpen
          ? `${props.files.length} Anhänge`
          : currentFile.title;

        return (
          <Header>
            <li>
              <TransparentButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleItemClick(accordionIndex);
                }}
              >
                <Icon
                  name={isOpen ? IconsSmall.arrowDown : IconsSmall.arrowRight}
                  size={IconSize.small}
                  fill="white"
                />
                <span style={{ marginLeft: '6px' }}>{title}</span>
                {!isOpen ? (
                  <Counter>{`${props.selectedIndex + 1} von ${
                    props.files.length
                  }`}</Counter>
                ) : null}
              </TransparentButton>
            </li>
            {isOpen ? (
              <Files>
                {props.files.map((file, index) => (
                  <li key={index}>
                    <TransparentButton
                      onClick={() => props.onChange(file.id)}
                      className={
                        index === props.selectedIndex ? 'selected' : ''
                      }
                    >
                      {file.title}
                    </TransparentButton>
                  </li>
                ))}
              </Files>
            ) : null}
          </Header>
        );
      }}
    </Accordion>
  );
};

export { FileList };
