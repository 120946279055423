import axios, { AxiosRequestConfig } from 'axios';

import { getAccessToken } from '../utils/access-token';
import { defaultHeaders } from './config';

function useFetch() {
  const token = getAccessToken();
  const headers = token
    ? {
        ...defaultHeaders,
        // Include the token in the request if we have one
        Authorization: `Token ${token.token}`,
      }
    : defaultHeaders;

  const defaultAxiosConfig = {
    headers,
    withCredentials: true,
  };

  return {
    post<TResponseData = any>(
      url: string,
      data: any,
      config: AxiosRequestConfig = {}
    ) {
      const axiosConfig = {
        ...defaultAxiosConfig,
        ...config,
        headers: {
          ...defaultAxiosConfig.headers,
          ...config.headers,
        },
      };

      return axios.post<TResponseData>(url, data, axiosConfig);
    },
    get<TResponseData = any>(url: string, config: AxiosRequestConfig = {}) {
      const axiosConfig = {
        ...defaultAxiosConfig,
        ...config,
        headers: {
          ...defaultAxiosConfig.headers,
          ...config.headers,
        },
      };
      return axios.get<TResponseData>(url, axiosConfig);
    },
    delete<TResponseData = any>(url: string) {
      return axios.delete<TResponseData>(url, defaultAxiosConfig);
    },
    patch<TResponseData = any>(url: string, data: any) {
      return axios.patch<TResponseData>(url, data, defaultAxiosConfig);
    },
  };
}

export { useFetch };
