/**
 * Queries the tickets for the dashboard
 */
import gql from 'graphql-tag';

export const DashboardTicketFragment = gql`
  fragment DashboardTicket on Ticket {
    id
    title
    status
    failureReason
    inReviewSince
    inReviewBy @type(name: "User") {
      id
    }
    attachments @type(name: "Attachment") {
      id
      file
      fileType
      fileName
    }
    createdBy @type(name: "User") {
      id
      username
      firstName
      lastName
    }
  }
`;

const TICKETS_DASHBOARD_QUERY = gql`
  query DashboardTickets(
    $maxTickets: Int!
    $filterNew: [TicketStatus]!
    $filterAnalysis: [TicketStatus]!
    $filterReview: [TicketStatus]!
    $filterFinished: [TicketStatus]!
    $pathBuilder: Any!
  ) {
    newTickets: tickets(
      input: { limit: $maxTickets, filter: { status: $filterNew } }
    )
    @rest(type: "TicketsPayload", path: "tickets/", pathBuilder: $pathBuilder) {
      count

      results @type(name: "Ticket") {
        ...DashboardTicket
      }
    }

    analysisTickets: tickets(
      input: { limit: $maxTickets, filter: { status: $filterAnalysis } }
    )
    @rest(type: "TicketsPayload", path: "tickets/", pathBuilder: $pathBuilder) {
      count

      results @type(name: "Ticket") {
        ...DashboardTicket
      }
    }

    reviewTickets: tickets(
      input: { limit: $maxTickets, filter: { status: $filterReview } }
    )
    @rest(type: "TicketsPayload", path: "tickets/", pathBuilder: $pathBuilder) {
      count

      results @type(name: "Ticket") {
        ...DashboardTicket
      }
    }

    finishedTickets: tickets(
      input: { limit: $maxTickets, filter: { status: $filterFinished } }
    )
    @rest(type: "TicketsPayload", path: "tickets/", pathBuilder: $pathBuilder) {
      count

      results @type(name: "Ticket") {
        ...DashboardTicket
      }
    }
  }

  ${DashboardTicketFragment}
`;

export { TICKETS_DASHBOARD_QUERY };
