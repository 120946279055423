import React from 'react';
import styled from 'styled-components';

import { SmallIcon } from '../../../components/icon';
import { Spinner } from '../../../components/spinner';
import { getColor } from '../../../components/layout/styled-helpers';

export const TableHeader = styled.th`
  color: #545454;
  background-color: #f5f5f5;
  text-align: left;
  padding: 17px 24px 18px;
  font-size: 14px;
  border-bottom: 2px solid #f1f1f1;

  &:first-child {
    border-top-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
  }
`;

const Root = styled.button`
  background-color: ${getColor('gray-100')};

  border-radius: 6px;
  padding: 6px 7px 7px;
  border: none;
  margin-right: 6px;

  &:hover {
    background-color: ${getColor('gray-300')};
  }
`;

interface TicketSubActionButtonProps {
  onClick: () => void;
  loading?: boolean;
  children?: string;
}

export const TicketSubActionButton = ({
  onClick,
  loading,
  children,
}: TicketSubActionButtonProps) => (
  <Root onClick={onClick} disabled={loading} title={children}>
    {loading ? <Spinner /> : <SmallIcon name="trash" fill="gray-600" />}
  </Root>
);
