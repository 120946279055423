/**
 * Helper which uses the suggestions provided by the API and converts them
 * so that the autocomplete component can handle them
 */

import { normalize, schema } from 'normalizr';
import { groupBy } from 'lodash-es';

import {
  Suggestion,
  SuggestionGroup,
  NormalizedSuggestions,
  GroupItemResult,
  GroupedSuggestion,
  GroupedSuggestions,
  EstateAttributeProperty,
} from './form-data-loader.types';
import { ESTATE_ATTRIBUTE_PROPERTIES } from './form-data-loader.constants';

/* -----------------------------------------------------------------------------
 * normalizeSuggestions
 * ---------------------------------------------------------------------------*/

function normalizeSuggestions(suggestions: Suggestion[]) {
  const attributeSchema = new schema.Entity('suggestions');
  const normalizedData = normalize(suggestions, [attributeSchema]);

  return normalizedData.entities.suggestions as NormalizedSuggestions;
}

/* -----------------------------------------------------------------------------
 * generateSuggestions
 * ---------------------------------------------------------------------------*/

function generateSuggestions(suggestions: Suggestion[]) {
  const suggestionGroup: SuggestionGroup = {};

  // Generate Set from possible keys
  // Follows the supported API from IE11
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set#Browser_compatibility
  const keySet = new Set();
  ESTATE_ATTRIBUTE_PROPERTIES.forEach((key) => {
    keySet.add(key);

    // Initialize all keys with empty array
    suggestionGroup[key] = [];
  });

  suggestions.forEach((suggestion) => {
    const { kpi } = suggestion;
    if (keySet.has(kpi)) {
      suggestionGroup[kpi]!.push(suggestion);
    }
  });

  return suggestionGroup;
}

/* -----------------------------------------------------------------------------
 * groupItems
 * ---------------------------------------------------------------------------*/

/**
 * Sorts the suggestions by length of value from
 * 1. number: high -> low OR
 * 2. number of occurrences in document : high -> low
 *
 * @param resultA
 * @param resultB
 */
function sortSuggestions(
  resultA: GroupItemResult,
  resultB: GroupItemResult
): number {
  // If both have a number value use that for comparison
  if (
    typeof resultA.value.number === 'number' &&
    typeof resultB.value.number === 'number'
  ) {
    // Sort by numberValue DESC
    return resultB.value.number - resultA.value.number;
  }

  // Sort by number of found results DESC
  if (resultA.type === 'GROUP' && resultB.type === 'GROUP') {
    return resultB.items.length - resultA.items.length;
  }

  // If we have group and suggestion then group weights more, since it has at
  // least 2 suggestions (vs 1 suggestion)
  if (resultA.type === 'GROUP') {
    return -1;
  }

  if (resultB.type === 'GROUP') {
    return 1;
  }

  // Otherwise even
  return 0;
}

// The KPIs where the suggestions should be sorted
const sortSuggestionsOnKPIs = new Set<EstateAttributeProperty>([
  'parking_spaces',
  'rental_cost',
  'hotel_rooms',
  'occupancy',
  'area',
  'property_use',
]);

function groupItems(
  suggestions: Suggestion[],
  groupKey: EstateAttributeProperty
) {
  const results: GroupItemResult[] = [];

  // First group all results for same values
  const groupedSuggestions = groupBy(suggestions, (item) => {
    // Special case for `property_use`, here all suggestions are grouped by unit
    if (groupKey === 'property_use') {
      // Suggestions in 'property_use' always have a unit
      return item.value.unit!;
    }

    if (item.value.number) {
      // Group by numberValue + unit
      if (item.value.unit) {
        return `${item.value.unit}${item.value.number.toString()}`;
      }

      // Group by numberValue
      return item.value.number.toString();
    }

    // Fallback: Group By originalValue
    return item.value.originalValue
      ? item.value.originalValue.toLowerCase()
      : '';
  });

  Object.keys(groupedSuggestions).forEach((key) => {
    const group = sortSuggestionsOnKPIs.has(groupKey)
      ? groupedSuggestions[key].sort(sortSuggestions)
      : groupedSuggestions[key];

    // Results which only contain a single result, will bubble up
    if (group.length === 1) {
      results.push(group[0]);
      return;
    }

    const resultItem: GroupedSuggestion = {
      type: 'GROUP',
      items: group,
      itemCount: group.length,
      value: group[0].value,
      id: group[0].id,
    };

    results.push(resultItem);
  });

  // Sort results
  if (sortSuggestionsOnKPIs.has(groupKey)) {
    return results.sort(sortSuggestions);
  }

  return results;
}

/* -----------------------------------------------------------------------------
 * groupSuggestions
 * ---------------------------------------------------------------------------*/

// Groups the suggestions by same value
function groupSuggestions(suggestions: SuggestionGroup) {
  const groupedSuggestions: GroupedSuggestions = {};

  Object.keys(suggestions).forEach((groupKey) => {
    const estateAttributeProperty = groupKey as EstateAttributeProperty;
    groupedSuggestions[estateAttributeProperty] = groupItems(
      suggestions[estateAttributeProperty]!,
      estateAttributeProperty
    );
  });

  return groupedSuggestions;
}

export { normalizeSuggestions, generateSuggestions, groupSuggestions };
