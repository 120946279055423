/**
 * Helper functions for the accordion menu
 */

function single(state, changes) {
  if (changes.type === 'opening') {
    return { openIndexes: changes.openIndexes.slice(-1) };
  }
  return changes;
}

export { single };
