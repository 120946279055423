import * as React from 'react';
import styled, { css } from 'styled-components';

interface MessageContainerProps {
  noBorderRadius?: boolean;
}

const MessageContainer = styled.td<MessageContainerProps>`
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.16);
  text-align: center;
  padding: 12px;
  font-size: 14px;

  ${({ noBorderRadius }) =>
    noBorderRadius
      ? ''
      : css`
          border-radius: 6px;
        `}
`;

interface Props {
  colSpan?: number;
  noBorderRadius?: boolean;
}

const TicketListEmptyMessage = (props: React.PropsWithChildren<Props>) => (
  <tr>
    <MessageContainer
      colSpan={props.colSpan}
      noBorderRadius={props.noBorderRadius}
    >
      {props.children}
    </MessageContainer>
  </tr>
);

export default TicketListEmptyMessage;
