import * as React from 'react';
import styled from 'styled-components';

import { GlowBoxBase } from '../../../components/glowbox';
import Pagination from '../../../components/pagination';
import {
  TicketsQuery,
  TICKETS_FILTERED_BY_STATUS,
} from '../../../queries/tickets/tickets';
import { TicketListItem } from '../ticket/ticket-list-Item';
import { TicketList } from '../../dashboard';
import { TicketStatus } from '../../../types/apollo/globalTypes';
import { pathBuilder } from './pathbuilder';
import PaginationNavigation from '../../../components/pagination/navigation';
import { Flex, FlexChild } from '../../../components/layout';
import { getSpace, getColor } from '../../../components/layout/styled-helpers';
import { TableHeader } from './style';
import { getOverrides } from '../../../helpers/overrides';
import TicketListEmptyMessage from '../ticket/ticket-list-empty-message';
import { Header } from '../style';

interface Overrides {
  TicketAction?: {
    component?: React.ComponentType<any>;
    props?: {};
  };
  ActionBar?: {
    component?: React.ComponentType<any>;
  };
}

const EmptyActionBar = () => null;

interface Props {
  filterBy: TicketStatus[];
  overrides?: Overrides;
  emptyMessage: string;
  title: string;
  hideActionBar?: boolean;
  pollInterval?: number;
}

const TICKETS_PER_PAGE = 15;

const GlowContainer = styled(GlowBoxBase)`
  padding: ${getSpace(3)} 0;
`;

const OverviewTicketList = styled(TicketList)`
  border-spacing: 0;
  table-layout: auto;

  & > tbody > tr:nth-child(even) > td {
    background-color: ${getColor('gray-100')};
  }

  & > tbody > tr:not(:last-child) > td {
    border-bottom: 1pt solid #eeeeee;
  }
`;

const TicketOverview = ({
  filterBy,
  overrides = {},
  emptyMessage,
  title,
  hideActionBar,
  pollInterval,
}: Props) => {
  const [ActionBar, actionBarProps] = getOverrides(
    overrides.ActionBar,
    EmptyActionBar
  );

  return (
    <TicketsQuery
      query={TICKETS_FILTERED_BY_STATUS}
      variables={{ filter: filterBy, pathBuilder, limit: TICKETS_PER_PAGE }}
      pollInterval={pollInterval}
    >
      {({ data, loading, refetch }) => {
        const maxCount =
          data && data.tickets && data.tickets.count ? data.tickets.count : 0;

        return (
          <>
            <Header
              title={title}
              hideActionBar={hideActionBar}
              onClose={() => refetch()}
            />

            <GlowContainer>
              <Pagination
                limit={TICKETS_PER_PAGE}
                request={({ limit, offset }) =>
                  refetch({ filter: filterBy, pathBuilder, limit, offset })
                }
                maxCount={maxCount}
              >
                {({ refresh, ...paginationProps }) => {
                  if (!data || loading) {
                    return null;
                  }

                  return (
                    <Flex flexDirection="column" flex="1">
                      <FlexChild pb="3" px="3">
                        <Flex>
                          <FlexChild>
                            <ActionBar {...actionBarProps} />
                          </FlexChild>
                          <FlexChild ml="auto">
                            <PaginationNavigation {...paginationProps} />
                          </FlexChild>
                        </Flex>
                      </FlexChild>

                      <FlexChild>
                        <OverviewTicketList>
                          <thead>
                            <tr>
                              <TableHeader>Titel</TableHeader>
                              <TableHeader>Erstellt von</TableHeader>
                              <TableHeader />
                            </tr>
                          </thead>
                          <tbody>
                            {data.tickets.results.map((ticket) => {
                              return (
                                <TicketListItem
                                  key={ticket.id}
                                  ticket={ticket}
                                  overrides={{
                                    ActionCol: {
                                      ...overrides.TicketAction,
                                      props: {
                                        onUpdate: () => refetch(),
                                        onDelete: () => refetch(),
                                        ...(overrides.TicketAction
                                          ? overrides.TicketAction.props
                                          : {}),
                                      },
                                    },
                                  }}
                                />
                              );
                            })}

                            {data.tickets.results.length === 0 ? (
                              <TicketListEmptyMessage
                                colSpan={3}
                                noBorderRadius
                              >
                                {emptyMessage}
                              </TicketListEmptyMessage>
                            ) : null}
                          </tbody>
                        </OverviewTicketList>
                        {/* Hacky trick to prevent rounding of the rows */}
                        <span />
                      </FlexChild>

                      <FlexChild pt="3" px="3">
                        <PaginationNavigation {...paginationProps} />
                      </FlexChild>
                    </Flex>
                  );
                }}
              </Pagination>
            </GlowContainer>
          </>
        );
      }}
    </TicketsQuery>
  );
};

export { TicketOverview };
