/**
 * This view is used for programmatic login with an hash appended to the URL
 * A valid hash has the following schema:
 * #redirect_path=<RELATIVE_PATH_TO_REDIRECT>&access_token=<SOME_TOKEN>
 */

import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useCurrentUser } from '../../components/current-user';

const CallBackView = () => {
  const location = useLocation();
  const history = useHistory();
  const { setToken } = useCurrentUser();

  React.useEffect(() => {
    async function tokenExchange() {
      // Redirect to login when no accessToken is present
      let redirectTarget = '/login';

      const params = new URLSearchParams(
        // Remove leading `#`
        location.hash.charAt(0) === '#'
          ? location.hash.substring(1)
          : location.hash
      );

      const redirectPath = params.get('redirect_path');
      const accessToken = params.get('access_token');

      if (accessToken) {
        // Wait until token exchange with the backend is complete
        // The token is authorized in the backend and then returned to the
        // client if valid
        await setToken(accessToken);
        redirectTarget = redirectPath || '/';
      }

      history.replace(redirectTarget);
    }

    tokenExchange();
  }, [history, location.hash, setToken]);

  return null;
};

export { CallBackView };
