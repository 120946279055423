import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import {
  CreateTicket,
  CreateTicketVariables,
} from '../../types/apollo/CreateTicket';
import {
  UpdateTicket,
  UpdateTicketVariables,
} from '../../types/apollo/UpdateTicket';
import {
  TicketAction,
  TicketActionVariables,
} from '../../types/apollo/TicketAction';
import {
  TicketDelete,
  TicketDeleteVariables,
} from '../../types/apollo/TicketDelete';

// Create a new ticket
const TICKET_CREATE = gql`
  mutation CreateTicket($input: CreateTicketInput!) {
    createTicket(input: $input)
    @rest(type: "Ticket", path: "tickets/", method: "POST") {
      id
      title
      status
      attachments @type(name: "Attachment") {
        id
        file
        fileType
        fileName
      }
    }
  }
`;

// Update an existing ticket
const TICKET_UPDATE = gql`
  mutation UpdateTicket($ticketId: ID!, $input: CreateTicketInput!) {
    updateTicket(ticketId: $ticketId, input: $input)
    @rest(type: "Ticket", path: "tickets/{args.ticketId}/", method: "PUT") {
      id
      title
      status
      attachments @type(name: "Attachment") {
        id
        file
        fileType
        fileName
      }
    }
  }
`;

const TICKET_ACTION = gql`
  mutation TicketAction(
    $actionType: TicketActionType!
    $ticketId: ID!
    $input: Any = {}
  ) {
    ticketAction(actionType: $actionType, ticketId: $ticketId, input: $input)
    @rest(
      type: "Void"
      path: "tickets/{args.ticketId}/{args.actionType}/"
      method: "POST"
    ) {
      detail
      ticket @type(name: "Ticket") {
        id
        status
      }
    }
  }
`;

const TICKET_SUBMIT = gql`
  mutation TicketSubmit($ticketId: ID!, $input: SubmitTicketInput!) {
    submitTicket(ticketId: $ticketId, input: $input)
    @rest(
      type: "Void"
      path: "tickets/{args.ticketId}/validate_result/"
      method: "POST"
    ) {
      NoResponse
    }
  }
`;

const TICKET_DELETE = gql`
  mutation TicketDelete($ticketId: ID!) {
    deleteTicket(ticketId: $ticketId)
    @rest(type: "Void", path: "tickets/{args.ticketId}/", method: "DELETE") {
      NoResponse
    }
  }
`;

class CreateTicketMutation extends Mutation<
  CreateTicket,
  CreateTicketVariables
> {}
class UpdateTicketMutation extends Mutation<
  UpdateTicket,
  UpdateTicketVariables
> {}
class TicketActionMutation extends Mutation<
  TicketAction,
  TicketActionVariables
> {}

class TicketDeleteMutation extends Mutation<
  TicketDelete,
  TicketDeleteVariables
> {}

export {
  TICKET_CREATE,
  TICKET_UPDATE,
  TICKET_ACTION,
  TICKET_SUBMIT,
  TICKET_DELETE,
  CreateTicketMutation as CreateTicket,
  UpdateTicketMutation as UpdateTicket,
  TicketActionMutation as TicketAction,
  TicketDeleteMutation as TicketDelete,
};
