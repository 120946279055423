import { useState, useEffect, forwardRef, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Emitter } from 'mitt';
import * as Checkbox from '@radix-ui/react-checkbox';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Popover from '@radix-ui/react-popover';
import * as Select from '@radix-ui/react-select';

import * as Icon from '../iconsv2';
import { useAddAttachmentMutation } from '../../api/ticket/add-attachment';
import {
  AttachmentType,
  ProcessedAttachmentItem,
  ProcessedAttachmentItemPlaceholder,
} from '../../api/ticket/get-ticket';
import { useDeleteAttachmentMutation } from '../../api/ticket/delete-attachments';
import { useViewerSwitcher } from '../viewer-switcher';
import { usePatchAttachmentMutation } from '../../api/ticket/edit-attachment';
import { EmitterEvents, EditorMode } from '../../views/tickets/editor/editor';
import { usePatchTicketMutation } from '../../api/ticket/edit-ticket';
import { Spinner } from '../spinner';

/* -----------------------------------------------------------------------------
 * Utils
 * ---------------------------------------------------------------------------*/

/**
 * Adds leading `0` to an integer number, e.g. 1 -> 001
 * @see {@link https://stackoverflow.com/a/2998874/831465}
 * @param num
 * @param size
 * @returns
 */
function pad(input: number, size: number): string {
  let num = input.toString();
  while (num.length < size) num = '0' + num;
  return num;
}

/**
 * Returns the file extension for the following mime-types:
 * - image/png => png
 * - image/jpeg => jpeg
 */
function getFileExtension(mimeType: string): string {
  switch (mimeType) {
    case 'image/png':
      return 'png';

    case 'image/jpeg':
      return 'jpeg';
  }

  // Throw when mimeType is not supported
  throw new Error(`Unsupported mimeType '${mimeType}' for getFileExtension.`);
}

/**
 * Converts a dataURI (e.g. generated from a canvas element) to a Blob that can
 * be uploaded.
 *
 * @see {@link https://stackoverflow.com/a/36297034/831465}
 * @param dataURI
 * @returns
 */
function dataURItoBlob(dataURI: string): { blob: Blob; mimeType: string } {
  // DataURI begins with: data:image/png;base64,XXX...
  const [dataURIHeader, dataURIBase64Data] = dataURI.split(',');
  // MimeType matcher from: https://stackoverflow.com/a/49046568/831465
  const mimeTypeLookup = dataURIHeader.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/);

  if (mimeTypeLookup === null) {
    throw Error('Could not determine mimeType from dataURI.');
  }

  const [mimeType] = mimeTypeLookup;
  const binary = atob(dataURIBase64Data);
  const array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return {
    blob: new Blob([new Uint8Array(array)], {
      type: mimeType,
    }),
    mimeType,
  };
}

const dropDownContentStyle = {
  backgroundColor: '#ffffff',
  boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
  padding: '4px 0',
  borderRadius: '3px',
  zIndex: 10000,
};

const dropDownItemStyle = {
  minWidth: '120px',
  padding: '8px',
  color: '#000000',

  '&[data-highlighted]': {
    backgroundColor: '#F0F0F0',
  },
};

/* -----------------------------------------------------------------------------
 * Radix: Popover
 * ---------------------------------------------------------------------------*/

const PopoverRoot = Popover.Root;
const PopoverTrigger = Popover.Trigger;
const PopoverContent = styled(Popover.Content)({
  backgroundColor: '#232323',
  borderRadius: '3px',
  padding: '12px',
  color: '#ffffff',
  zIndex: 10000,
});
const PopoverClose = styled(Popover.Close)({
  backgroundColor: '#4B4B4B',
  color: '#989898',
  height: '24px',
  width: '24px',
  borderRadius: '3px',
  border: 'none',
  padding: '4px 0 0',
});

/* -----------------------------------------------------------------------------
 * Radix: Select
 * ---------------------------------------------------------------------------*/
const SelectRoot = Select.Root;
const SelectTrigger = styled(Select.Trigger)({
  backgroundColor: 'transparent',
  padding: '4px 8px',
  color: '#989899',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
});
const SelectContent = styled(Select.Content)(dropDownContentStyle);
const SelectItem = styled(Select.Item)(dropDownItemStyle);

/* -----------------------------------------------------------------------------
 * Radix: Checkbox
 * ---------------------------------------------------------------------------*/
const CheckboxRoot = styled(Checkbox.Root)({
  background: 'transparent',
  border: 'none',
});
const CheckboxIndicator = styled(Checkbox.Indicator)(({ theme }) => ({
  color: theme.colors.button.background,

  '&[data-state=checked]': {
    color: theme.colors.button.backgroundActive,
  },

  '&[data-disabled]': {
    color: '#4B4B4B',
  },
}));

/* -----------------------------------------------------------------------------
 * Radix: DropdownMenu
 * ---------------------------------------------------------------------------*/
const DropdownMenuRoot = DropdownMenu.Root;
const DropdownMenuTrigger = styled(DropdownMenu.Trigger)(({ theme }) => ({
  background: 'transparent',
  border: 'none',
  color: theme.colors.button.background,
  padding: '0 2px 0 6px',
  display: 'flex',
  alignItems: 'center',
}));
const DropdownMenuContent = styled(DropdownMenu.Content)(dropDownContentStyle);
const DropdownMenuItem = styled(DropdownMenu.Item)(dropDownItemStyle);

const DropDownTrigger = styled(DropdownMenu.Trigger)({
  backgroundColor: '#232323',
  borderRadius: '3px',
  position: 'relative',
  padding: '2px 4px 2px 8px',
  color: '#989899',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 600,

  '& span': {
    marginRight: '4px',
  },
});

const Container = styled.div({
  backgroundColor: '#4B4B4B',
  display: 'flex',
  flexDirection: 'column',
  padding: '6px',
});

const Button = styled.button<{ active?: boolean }>(({ theme, active }) => ({
  color: active
    ? theme.colors.button.backgroundActive
    : theme.colors.button.background,
  background: 'transparent',
  border: 'none',
  display: 'block',
  padding: '8px',
  fontSize: '10px',
  fontWeight: 700,
  letterSpacing: '0.06em',
  position: 'relative',
}));

const SnippetBar = styled(Popover.Content)({
  backgroundColor: '#333333',
  borderLeft: '1px solid #989898',
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${58}px)`,
  width: '285px',
  // Needs to be very high to go over the text layer from the PDF.js viewer
  zIndex: 10000,
});

const SnippetBarImageList = styled.div({
  flex: 1,
  overflowY: 'auto',
});

const SnippetBarHeader = styled.div({
  display: 'flex',
  padding: '12px 8px 16px',
});

const NewSnippetButton = styled.button(({ theme }) => ({
  backgroundColor: theme.colors.button.backgroundActive,
  color: '#000000',
  height: '24px',
  borderRadius: '3px',
  border: 'none',
  padding: '0 6px 0 10px',
  margin: '0 6px 0 auto',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  fontSize: '12px',

  '& > span': { margin: '0 8px 0 0' },
}));

const SnippetImg = styled.img({
  maxWidth: '100%',
  display: 'block',
});

const SnippetImgContainer = styled.div({
  position: 'relative',
  marginBottom: '4px',
});

const SnippetImgFileName = styled.div({
  color: 'rgba(255,255,255, 0.6)',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background:
    'linear-gradient(180deg, rgba(152, 152, 152, 0.1) 0%, rgba(99, 99, 99, 0.83) 27.62%, rgba(0, 0, 0, 0.81) 100%);',
  padding: '8px 4px',
});

const SidebarButtonGroup = styled.div<{
  active?: boolean;
}>(({ active }) => ({
  backgroundColor: active ? '#363636' : undefined,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
}));

const SidebarButtonExtension = styled.div<{
  active?: boolean;
}>(({ active, theme }) => ({
  backgroundColor: active ? theme.colors.button.backgroundActive : '#E5E5E5',
  color: '#363636',
  top: 0,
  right: '6px',
  position: 'absolute',
  padding: '1px 4px 1px',
  borderRadius: '12px',
  fontSize: '12px',
  fontWeight: 900,
}));

interface SidebarButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'active'> {
  active?: boolean;
  icon: React.ReactElement;
  label: string;
  bubbleExtension?: string;
}

const SidebarButton = forwardRef<HTMLButtonElement, SidebarButtonProps>(
  ({ icon, label, active, bubbleExtension, ...rest }, forwardedRef) => {
    return (
      <Button {...rest} active={active} ref={forwardedRef}>
        <div>{icon}</div>
        <div>{label}</div>

        {typeof bubbleExtension === 'string' ? (
          <SidebarButtonExtension active={active}>
            {bubbleExtension}
          </SidebarButtonExtension>
        ) : null}
      </Button>
    );
  }
);

/* -----------------------------------------------------------------------------
 * Snippet
 * ---------------------------------------------------------------------------*/

const FilenamePopoverHeadline = styled.label({
  color: '#ffffff',
  fontSize: '12px',
  fontWeight: 600,

  '& > span': {
    display: 'block',
    marginBottom: '4px',
  },
});

const FileNamePopoverContainer = styled.div({
  display: 'flex',
});

const FileNamePopoverExtension = styled.div({});

const SnippetToolbarRoot = styled.div({
  display: 'flex',
});

const SnippetToolbarTextInput = styled.input({
  flex: 1,
  width: 0,
  backgroundColor: '#232323',
  borderRadius: '3px',
  color: '#989898',
  border: 'none',
  padding: '0 4px',
  marginRight: '4px',

  '&:focus': {
    backgroundColor: '#232323',
  },
});

const FileNamePopoverTextInput = styled(SnippetToolbarTextInput)({
  width: 'auto',
  fontSize: '16px',
});

type SnippetToolbarProps = {
  attachmentId: number;
  ticketId: number;
  type: string;
  onTypeChange(type: string): void;
  description: string;
  onDescriptionChange(description: string): void;
  onFileNameChange(fileName: string): void;
  onEditDescriptionDone(): void;
  fileName: string;
  fileExtension: string;
  isMainPicture: boolean;
};

/**
 * Toolbar below the snippet preview that allows to change the type, set a
 * description etc.
 */
function SnippetToolbar({
  attachmentId,
  ticketId,
  type,
  onTypeChange,
  description,
  onDescriptionChange,
  onEditDescriptionDone,
  fileName,
  onFileNameChange,
  fileExtension,
  isMainPicture,
}: SnippetToolbarProps) {
  const { mutate: deleteAttachment } = useDeleteAttachmentMutation();
  const {
    mutate: patchTicket,
    mutateAsync: patchTicketAsync,
    isLoading: patchTicketIsLoading,
  } = usePatchTicketMutation();
  // Only attachments of type `picture` are allowed as mainPicture
  const isAllowedAsMainPicture = type === 'picture';

  async function onChangeType(newType: string) {
    if (newType === type) {
      return;
    }

    if (isMainPicture && newType === 'plan') {
      await patchTicketAsync({
        ticketId,
        mainPictureId: null,
      });
    }

    onTypeChange(newType);
  }

  return (
    <SnippetToolbarRoot>
      <CheckboxRoot
        disabled={!isAllowedAsMainPicture}
        checked={isMainPicture}
        onCheckedChange={() => {
          if (!patchTicketIsLoading && isAllowedAsMainPicture) {
            patchTicket({
              ticketId,
              mainPictureId: isMainPicture ? null : attachmentId,
            });
          }
        }}
      >
        <CheckboxIndicator forceMount>
          {patchTicketIsLoading ? (
            <Spinner />
          ) : (
            <Icon.Star variant={isMainPicture ? 'filled' : 'outline'} />
          )}
        </CheckboxIndicator>
      </CheckboxRoot>

      <PopoverRoot
        onOpenChange={(state) => {
          if (!state) {
            onEditDescriptionDone();
          }
        }}
      >
        <PopoverTrigger asChild>
          <SnippetToolbarTextInput
            type="text"
            value={description}
            onChange={(event) => onDescriptionChange(event.target.value)}
            onBlur={() => {
              onEditDescriptionDone();
            }}
          />
        </PopoverTrigger>

        <PopoverContent
          onOpenAutoFocus={(event) => {
            // Prevents focus shift inside the popover, focus should stay on the
            // textfield
            event.preventDefault();
          }}
          onCloseAutoFocus={() => {
            onEditDescriptionDone();
          }}
        >
          <FilenamePopoverHeadline>
            <span>Dateiname:</span>
          </FilenamePopoverHeadline>
          <FileNamePopoverContainer>
            <FileNamePopoverTextInput
              type="text"
              value={fileName}
              onChange={(event) => onFileNameChange(event.target.value)}
              onBlur={() => {
                onEditDescriptionDone();
              }}
            />
            <FileNamePopoverExtension>
              .{fileExtension}
            </FileNamePopoverExtension>
          </FileNamePopoverContainer>
        </PopoverContent>
      </PopoverRoot>

      {/* Cannot reuse radix/Select here because the position of the dropdown
          menu could not be fixed, using radix/Dropdown instead.
          See: https://github.com/radix-ui/primitives/issues/1247 */}
      <DropdownMenuRoot>
        <DropDownTrigger>
          <span>{type === 'plan' ? 'Plan' : 'Bild'}</span>
          <Icon.ArrowDown />
        </DropDownTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={() => {
              onChangeType('plan');
            }}
          >
            Plan
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              onChangeType('picture');
            }}
          >
            Bild
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuRoot>

      <DropdownMenuRoot>
        <DropdownMenuTrigger>
          <Icon.Dots />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={() => {
              deleteAttachment({ attachmentId, ticketId });
            }}
          >
            Löschen
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuRoot>
    </SnippetToolbarRoot>
  );
}

const SnippetContainer = styled.div({
  padding: '0 8px 4px',
  marginBottom: '8px',
  borderBottom: '1px solid #4B4B4B',
});

interface SnippetProps {
  attachmentId: number;
  attachmentType: AttachmentType;
  src: string;
  ticketId: number;
  description: string | null;
  fileName: string;
  isMainPicture: boolean;
}

function Snippet({
  attachmentId,
  src,
  ticketId,
  attachmentType,
  description,
  fileName,
  isMainPicture,
}: SnippetProps) {
  const { mutate: patchAttachment } = usePatchAttachmentMutation();
  const [internalDescription, setInternalDescription] = useState(
    description ? description : ''
  );
  const { extension: fileExtension, fileNameWithoutExtension } = useMemo(() => {
    // Get the fileName without the extension since the extension should not be
    // editable by the user.
    const positionOfLastDot = fileName.lastIndexOf('.');
    const extension = fileName.substring(positionOfLastDot + 1);
    const fileNameWithoutExtension = fileName.substring(0, positionOfLastDot);

    return { extension, fileNameWithoutExtension };
  }, [fileName]);
  const [internalFileName, setInternalFileName] = useState(
    fileNameWithoutExtension
  );
  const [isFileNameDirty, setIsFileNameDirty] = useState(false);

  function updateDescription() {
    patchAttachment({
      attachmentId,
      data: {
        description: internalDescription,
        fileName: `${internalFileName}.${fileExtension}`,
      },
      ticketId,
    });
  }

  return (
    <SnippetContainer>
      <SnippetImgContainer>
        <SnippetImg src={src} />
        <SnippetImgFileName>
          {internalFileName}.{fileExtension}
        </SnippetImgFileName>
      </SnippetImgContainer>
      <SnippetToolbar
        attachmentId={attachmentId}
        ticketId={ticketId}
        description={internalDescription}
        onEditDescriptionDone={updateDescription}
        onDescriptionChange={(description: string) => {
          // When the fileName was not changed, use the description also as
          // FileName
          if (!isFileNameDirty) {
            setInternalFileName(description);
          }

          setInternalDescription(description);
        }}
        onFileNameChange={(fileName) => {
          if (!isFileNameDirty) {
            setIsFileNameDirty(true);
          }

          setInternalFileName(fileName);
        }}
        type={attachmentType}
        onTypeChange={(type) => {
          patchAttachment({
            attachmentId,
            data: { type: type as AttachmentType },
            ticketId,
          });
        }}
        fileName={internalFileName}
        fileExtension={fileExtension}
        isMainPicture={isMainPicture}
      />
    </SnippetContainer>
  );
}

interface EditorSidebarProps {
  /**
   * Attachments from the ticket model.
   */
  attachments: Array<
    ProcessedAttachmentItem | ProcessedAttachmentItemPlaceholder
  >;
  emitterRef: React.MutableRefObject<Emitter<EmitterEvents>>;
  /**
   * Id of the associated ticket.
   */
  ticketId: number;
  mainPictureId: number | null;
}

function EditorSidebar({
  attachments,
  emitterRef,
  ticketId,
  mainPictureId,
}: EditorSidebarProps) {
  // Counter that keeps track how many snippets were added (not uploaded)
  // to show a filename when the user creates a new snippet.
  const addedSnippetsCounter = useRef(1);
  const [editorMode, setEditorMode] = useState<EditorMode>('view');
  const [filterSnippetBy, setFilterSnippetBy] = useState('all');
  const { snippets, totalNumberOfSnippets } = useMemo(() => {
    // Only show pictures & plans in the sidebar
    const snippets = attachments.filter(
      (attachment) =>
        attachment.type === 'picture' || attachment.type === 'plan'
    );
    const totalNumberOfSnippets = snippets.length;
    const snippetsFiltered =
      filterSnippetBy === 'all'
        ? snippets
        : attachments.filter(
            (attachment) => attachment.type === filterSnippetBy
          );

    return { snippets: snippetsFiltered, totalNumberOfSnippets };
  }, [attachments, filterSnippetBy]);
  const { mutate: addAttachment } = useAddAttachmentMutation();
  const [snippetBarState, setSnippetBarState] = useState<'open' | 'closed'>(
    'closed'
  );
  const { state: viewerSwitcherState } = useViewerSwitcher('EditorSidebar');
  const pdfViewerActive = viewerSwitcherState.type === 'pdf';

  function startSnipping() {
    // Close toolbar when open
    setSnippetBarState('closed');

    emitterRef.current.emit('changeMode', {
      mode: editorMode === 'view' ? 'snip' : 'view',
    });
  }

  useEffect(() => {
    function snipHandler({ url: dataURI }: EmitterEvents['snip']) {
      // Document type is picture by default
      const documentType = 'picture';
      const attachmentCounter = pad(addedSnippetsCounter.current, 2);
      addedSnippetsCounter.current = addedSnippetsCounter.current + 1;
      const description = `${
        documentType === 'picture' ? 'bild' : 'plan'
      }_${attachmentCounter}`;

      // Convert dataUrl to blob
      const { blob, mimeType } = dataURItoBlob(dataURI);
      const fileName = `${description}.${getFileExtension(mimeType)}`;

      addAttachment({
        ticketId: ticketId,
        dataURI,
        dataBlob: blob,
        documentType,
        description,
        fileName,
      });

      // Open the sidebar automatically after a snip was created
      setSnippetBarState('open');
    }

    function editorModeChangeHandler(event: EmitterEvents['modeChanged']) {
      setEditorMode(event.mode);
    }

    const currentEmitter = emitterRef.current;
    currentEmitter.on('snip', snipHandler);
    currentEmitter.on('modeChanged', editorModeChangeHandler);

    return () => {
      currentEmitter.off('snip', snipHandler);
      currentEmitter.off('modeChanged', editorModeChangeHandler);
    };
  }, []);

  return (
    <PopoverRoot
      open={snippetBarState === 'open'}
      onOpenChange={(open) => {
        setSnippetBarState(open ? 'open' : 'closed');

        // Ensure that the editor mode is set to 'view' when the sidebar is
        // opened
        if (open) {
          emitterRef.current.emit('changeMode', { mode: 'view' });
        }
      }}
    >
      <Container>
        <SidebarButtonGroup active={pdfViewerActive}>
          <SidebarButton
            active={pdfViewerActive}
            icon={<Icon.File />}
            label="PDF"
          ></SidebarButton>
          <PopoverTrigger asChild>
            <SidebarButton
              active={snippetBarState === 'open'}
              bubbleExtension={totalNumberOfSnippets.toString()}
              icon={<Icon.Snippets />}
              label="Snippets"
            ></SidebarButton>
          </PopoverTrigger>

          <SidebarButton
            icon={<Icon.NewSnip size="24" />}
            label="Neu"
            onClick={startSnipping}
            active={editorMode === 'snip'}
          ></SidebarButton>
        </SidebarButtonGroup>
      </Container>

      <SnippetBar side="left" align="start" sideOffset={6}>
        <SnippetBarHeader>
          <SelectRoot
            value={filterSnippetBy}
            onValueChange={setFilterSnippetBy}
          >
            <SelectTrigger>
              <Select.Value />
              <Icon.ArrowDown />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">
                <Select.ItemText>Alle Snippets</Select.ItemText>
                <Select.ItemIndicator />
              </SelectItem>
              <SelectItem value="plan">
                <Select.ItemText>Pläne</Select.ItemText>
                <Select.ItemIndicator />
              </SelectItem>
              <SelectItem value="picture">
                <Select.ItemText>Bilder</Select.ItemText>
                <Select.ItemIndicator />
              </SelectItem>
            </SelectContent>
          </SelectRoot>
          <NewSnippetButton onClick={startSnipping}>
            <span>Neu</span>
            <Icon.NewSnip size="16" />
          </NewSnippetButton>
          <PopoverClose>
            <Icon.Close />
          </PopoverClose>
        </SnippetBarHeader>
        <SnippetBarImageList>
          {snippets.map(({ id, file, type, description, fileName }) => (
            <Snippet
              key={id}
              src={file}
              attachmentId={id}
              attachmentType={type}
              ticketId={ticketId}
              description={description}
              fileName={fileName}
              isMainPicture={id === mainPictureId}
            />
          ))}
        </SnippetBarImageList>
      </SnippetBar>
    </PopoverRoot>
  );
}

export { EditorSidebar };
