import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { TransparentButton } from '../button/transparent';

interface Props {
  title: string;
}

const LinkBack = ({ title, children }: React.PropsWithChildren<Props>) => {
  const location = useLocation();
  const history = useHistory();

  // Don't show the back button when the user comes from a direct link
  if (location.state && location.state.fromDashboard) {
    return (
      <TransparentButton
        onClick={() => history.goBack()}
        title={title}
        style={{ cursor: 'pointer' }}
      >
        {children}
      </TransparentButton>
    );
  }

  return null;
};

export { LinkBack };
