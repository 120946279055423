/**
 * Dashboard view which shows an overview from all tickets / status
 */
import { useQuery } from '@apollo/react-hooks';
import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import produce from 'immer';

import { TICKETS_DASHBOARD_QUERY } from '../queries/dashboard/tickets';
import { FlexChild } from '../components/layout';
import { GlowBoxTitle } from '../components/glowbox';
import { TicketListItem } from './components/ticket/ticket-list-Item';
import { TicketStatus } from '../types/apollo/globalTypes';
import TicketListEmptyMessage from './components/ticket/ticket-list-empty-message';
import { Header } from './components/style';
import { pathBuilder } from './components/overview/pathbuilder';
import SiteTitle from '../components/site-title';
import { getColor } from '../components/layout/styled-helpers';
import { ActionLink } from '../components/button/link';
import { IconsSmall } from '../components/icon';
import {
  filterTicketNew,
  filterTicketAnalyis,
  filterTicketReview,
  NewTicketaction,
  ReviewTicketAction,
  FinishedTicketAction,
  AnalysisTicketAction,
} from './tickets/tickets-shared';
import {
  emptyMessageReview,
  emptyMessageAnalysis,
  emptyMessageNew,
  emptyMessageFinished,
} from '../translations/empty-messages';
import {
  DashboardTickets,
  DashboardTicketsVariables,
} from '../types/apollo/DashboardTickets';

////////////////////////////////////////////////////////////////////////
// GlowBoxSection
interface TicketSectionProps {
  title: string;
  counter: number;
  linkToOverview: string;
  hideCounter?: boolean;
  noMargin?: boolean;
  emptyMessage: string;
}

interface GlowBoxSectionProps {
  title: string;
  counter: number;
  hideCounter?: boolean;
  linkToOverview: string;
}

const Footer = styled(FlexChild)`
  background-color: ${getColor('white-500')};
  border-radius: 0 0 6px 6px;
  font-size: 12px;
  font-weight: bold;
`;

const FooterLink = styled(Link)`
  display: block;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  padding: 9px 8px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const MAX_TICKETS = 2;

const GlowBoxSection = ({
  title,
  counter,
  linkToOverview,
  children,
}: React.PropsWithChildren<GlowBoxSectionProps>) => {
  const moreTickets = counter - MAX_TICKETS;

  return (
    <GlowBoxTitle
      title={title}
      counter={counter}
      renderToolbar={
        <ActionLink to={linkToOverview} icon={IconsSmall.arrowRight}>
          Alle anzeigen
        </ActionLink>
      }
    >
      <FlexChild mt={12}>
        {children}

        {/* Show footer if more than MAX_TICKETS are available */}
        {moreTickets > 0 ? (
          <Footer>
            <FooterLink
              to={linkToOverview}
              title="Alle Exposés aus diesem Bereich anzeigen"
            >
              + {moreTickets} {moreTickets === 1 ? 'Weiteres' : 'Weitere'}
            </FooterLink>
          </Footer>
        ) : null}
      </FlexChild>
    </GlowBoxTitle>
  );
};

////////////////////////////////////////////////////////////////////////
// Ticketlist

const TicketList = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 2px;
  table-layout: fixed;
`;

const TicketSection = ({
  children,
  noMargin,
  counter,
  emptyMessage,
  ...rest
}: React.PropsWithChildren<TicketSectionProps>) => (
  <FlexChild mt={noMargin ? undefined : 5} flex={1}>
    <GlowBoxSection counter={counter} {...rest}>
      <TicketList>
        <tbody>
          {counter > 0 ? (
            children
          ) : (
            <TicketListEmptyMessage>{emptyMessage}</TicketListEmptyMessage>
          )}
        </tbody>
      </TicketList>
    </GlowBoxSection>
  </FlexChild>
);

////////////////////////////////////////////////////////////////////////
// Dashboard view

enum TicketType {
  newTickets = 'newTickets',
  analysisTickets = 'analysisTickets',
  reviewTickets = 'reviewTickets',
}

// Typescript skillzz
// Get one property from the props of a react component
type OnUpdateNewTicketsT = Pick<
  React.ComponentProps<typeof NewTicketaction>,
  'onUpdate'
>['onUpdate'];

type OnDeleteNewTicketsT = Pick<
  React.ComponentProps<typeof NewTicketaction>,
  'onDelete'
>['onDelete'];

const queryVariables = {
  // Exceed the boundary by 1 to prevent jumping in the new tickets section
  maxTickets: MAX_TICKETS + 1,
  pathBuilder,
  filterNew: filterTicketNew,
  filterAnalysis: filterTicketAnalyis,
  filterReview: filterTicketReview,
  filterFinished: [TicketStatus.done],
};

const onDeleteTickets = (
  ticketId: string,
  ticketType: TicketType
): OnDeleteNewTicketsT => (cache) => {
  const data = cache.readQuery<DashboardTickets, typeof queryVariables>({
    query: TICKETS_DASHBOARD_QUERY,
    variables: queryVariables,
  });

  if (!data) {
    return;
  }

  const newData = produce(data, (draft) => {
    draft[ticketType].results = draft[ticketType].results.filter(
      (ticket) => ticket.id !== ticketId
    );
    draft[ticketType].count--;
  });

  cache.writeQuery({
    query: TICKETS_DASHBOARD_QUERY,
    variables: queryVariables,
    data: newData,
  });
};

const onUpdateNewTickets = (
  oldStatus: TicketType,
  newStatus: TicketType
): OnUpdateNewTicketsT => (cache, { data: reponse }) => {
  if (!reponse) {
    return;
  }

  const data = cache.readQuery<DashboardTickets, typeof queryVariables>({
    query: TICKETS_DASHBOARD_QUERY,
    variables: queryVariables,
  });

  if (!data) {
    return;
  }

  const newData = produce(data, (draft) => {
    const responseTicket = reponse.ticketAction.ticket;

    const ticketIndex = draft[oldStatus].results.findIndex(
      (ticket) => ticket.id === responseTicket.id
    );
    const ticket = draft[oldStatus].results[ticketIndex];

    if (!ticket) {
      return;
    }

    ticket.status = responseTicket.status;

    // Delete the ticket from the new tickets section
    draft[oldStatus].results = draft[oldStatus].results.filter(
      // eslint-disable-next-line no-empty-pattern
      ({}, index) => index !== ticketIndex
    );
    draft[oldStatus].count--;

    // Add the ticket with the new status to the list of analysed tickets
    draft[newStatus].count++;
    draft[newStatus].results = [
      ticket,
      ...draft[newStatus].results.slice(0, queryVariables.maxTickets - 1),
    ];
  });

  cache.writeQuery({
    query: TICKETS_DASHBOARD_QUERY,
    variables: queryVariables,
    data: newData,
  });
};

const DashboardView = () => {
  const { data, loading, refetch } = useQuery<
    DashboardTickets,
    DashboardTicketsVariables
  >(TICKETS_DASHBOARD_QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    variables: queryVariables,
  });

  if (!data || loading) {
    return <SiteTitle title="Übersicht" />;
  }

  const { newTickets, analysisTickets, reviewTickets, finishedTickets } = data;

  return (
    <>
      <SiteTitle title="Übersicht" />

      <Header title="Übersicht" hideBackNavigation onClose={() => refetch()} />

      <div>
        {/* New Tickets */}
        <TicketSection
          title="Neue Exposés"
          counter={newTickets.count}
          linkToOverview="/tickets/new"
          noMargin
          emptyMessage={emptyMessageNew}
        >
          {newTickets.results.slice(0, MAX_TICKETS).map((ticket) => (
            <TicketListItem
              key={ticket.id}
              ticket={ticket}
              overrides={{
                ActionCol: {
                  component: NewTicketaction,
                  props: {
                    onUpdate: onUpdateNewTickets(
                      TicketType.newTickets,
                      TicketType.analysisTickets
                    ),
                    onDelete: onDeleteTickets(ticket.id, TicketType.newTickets),
                  },
                },
              }}
            />
          ))}
        </TicketSection>

        {/* Analysis */}
        <TicketSection
          title="Analyse"
          counter={analysisTickets.count}
          linkToOverview="/tickets/analysis"
          emptyMessage={emptyMessageAnalysis}
        >
          {analysisTickets.results.slice(0, MAX_TICKETS).map((ticket) => (
            <TicketListItem
              key={ticket.id}
              ticket={ticket}
              overrides={{
                ActionCol: {
                  component: AnalysisTicketAction,
                  props: {
                    onUpdate: onUpdateNewTickets(
                      TicketType.analysisTickets,
                      TicketType.reviewTickets
                    ),
                    onDelete: onDeleteTickets(
                      ticket.id,
                      TicketType.analysisTickets
                    ),
                  },
                },
              }}
            />
          ))}
        </TicketSection>

        {/* Review */}
        <TicketSection
          title="Überprüfung"
          counter={reviewTickets.count}
          linkToOverview="/tickets/review"
          emptyMessage={emptyMessageReview}
        >
          {reviewTickets.results.slice(0, MAX_TICKETS).map((ticket) => (
            <TicketListItem
              key={ticket.id}
              ticket={ticket}
              overrides={{
                ActionCol: {
                  component: ReviewTicketAction,
                },
              }}
            />
          ))}
        </TicketSection>

        {/* Finished */}
        <TicketSection
          title="Abgeschlossen"
          linkToOverview="/tickets/finished"
          counter={finishedTickets.count}
          hideCounter
          emptyMessage={emptyMessageFinished}
        >
          {finishedTickets.results.slice(0, MAX_TICKETS).map((ticket) => (
            <TicketListItem
              key={ticket.id}
              ticket={ticket}
              overrides={{
                ActionCol: { component: FinishedTicketAction },
              }}
            />
          ))}
        </TicketSection>
      </div>
    </>
  );
};

export { DashboardView, TicketList };
