import * as React from 'react';
import styled from 'styled-components';

import { Box, Flex } from '../../layout';
import { ReactComponent as SuccessIcon } from './assets/icon-success.svg';
import { ModalBodyWrapper } from './styles';

const MessageBox = styled.div({
  backgroundColor: '#E9FEED',
  border: '1px solid #3EBC5A',
  padding: '12px',
  margin: '24px',
  color: '#339048',
  lineHeight: '140%',
  fontWeight: 600,
});

export const SuccessMessageTemplate = () => {
  return (
    <ModalBodyWrapper>
      <MessageBox>
        <Flex>
          <SuccessIcon />
          <Box pl="4">
            Die Erfassung der Daten war erfolgreich.
            <br />
            Diese Seite kann nun geschlossen werden.
          </Box>
        </Flex>
      </MessageBox>
    </ModalBodyWrapper>
  );
};
