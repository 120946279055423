import { useMutation, useQueryClient } from 'react-query';

import { getBaseUrl } from '../config';
import { useFetch } from '../fetch';
import { TicketItemReview } from './get-ticket';
import { getTicketAnalysisResultQueryKey } from './get-ticket-analysis-result';

type DeleteAttachmentRequestParams = {
  attachmentId: number;
};

const useDeleteAttachment = () => {
  const { delete: sendDelete } = useFetch();
  const baseUrl = getBaseUrl();

  return async ({ attachmentId }: DeleteAttachmentRequestParams) => {
    const { status } = await sendDelete(
      `${baseUrl}attachments/${attachmentId}/`
    );

    if (status === 204) {
      return true;
    }

    return false;
  };
};

type UseDeleteAttachmentMutationParams = {
  attachmentId: number;
  ticketId?: number;
};

function useDeleteAttachmentMutation() {
  const deleteAttachment = useDeleteAttachment();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ attachmentId }: UseDeleteAttachmentMutationParams) => {
      const deletionSuccessful = await deleteAttachment({ attachmentId });

      if (!deletionSuccessful) {
        throw new Error(`Deletion of attachment ${attachmentId} failed.`);
      }
    },
    {
      onSuccess(_, { attachmentId, ticketId }) {
        // Remove the attachment from associated ticket

        // Skip this when no ticketId was provided
        if (ticketId === undefined) {
          return;
        }

        queryClient.setQueryData<TicketItemReview | undefined>(
          getTicketAnalysisResultQueryKey(ticketId),
          (oldTicketData) => {
            if (oldTicketData === undefined) {
              return oldTicketData;
            }

            return {
              ...oldTicketData,
              attachments: oldTicketData.attachments.filter((attachment) => {
                return attachment.id !== attachmentId;
              }),
            };
          }
        );
      },
    }
  );
}

export { useDeleteAttachmentMutation };
