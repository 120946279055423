/**
 * Custom react final form field that maps the complex type to the inputValue
 *
 * given the following complex data structure:
 * {
 *    companyName: {
 *      inputValue: 'abc', <--
 *      type: 'STRING',
 *    }
 * }
 *
 * it maps the component that is passed as property to the inputValue property
 */

import * as React from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { INPUT_VALUE_PROPERTY_NAME } from './form-data-field-mapper.constants';

// Tries to add the additional properties that the passed component could have
// to the properties definition of InputValueFieldMapper
type TargetDataMapping = FieldRenderProps<string, HTMLElement>;

type InputValueFieldMapperProps<T> = {
  name: string;
  // TODO: type <any> should extend type TargetDataMapping here
  // Finding out how to do this already took me some hours without acceptable
  // result :(
  component: React.ComponentType<any>;
} & Omit<T, 'input' | 'meta'>;

function InputValueFieldMapper<T extends TargetDataMapping>(
  props: InputValueFieldMapperProps<T>
) {
  const { component: Component, name, ...restProps } = props;
  const { input, meta } = useField<string, HTMLElement>(
    `${name}.${INPUT_VALUE_PROPERTY_NAME}`
  );

  return <Component input={input} meta={meta} {...restProps} />;
}

export { InputValueFieldMapper };
