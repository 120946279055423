/**
 * Defines the mapping to the FormDataEntry
 */

import { FormDataEntry } from '../form-data/form-data.types';

type FormDataEntryKeys = keyof FormDataEntry;

const INPUT_VALUE_PROPERTY_NAME: FormDataEntryKeys = 'inputValue';
const INPUT_UNIT_PROPERTY_NAME: FormDataEntryKeys = 'unit';

export { INPUT_VALUE_PROPERTY_NAME, INPUT_UNIT_PROPERTY_NAME };
