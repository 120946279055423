import * as React from 'react';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { UserLoginVariables, UserLogin } from '../../types/apollo/UserLogin';
import { Headline, Subline } from '../../components/layout/text';
import { Box, FlexChild } from '../../components/layout';
import InputText from '../../components/input/text';
import { USER_LOGIN_MUTATION } from '../../mutations/user/login';
import { PrimaryButton } from '../../components/button/button-primary';
import { GlowBoxBase } from '../../components/glowbox';
import { FullHeight } from '../../components/layout/block';
import { useCurrentUser } from '../../components/current-user';

const ManualLink = styled.a(({ theme }) => ({
  color: theme.colors.text.main,
  display: 'block',
  textAlign: 'center',
  marginTop: '12px',
}));

const Container = styled.div`
  width: 380px;
  margin: 0 auto;
`;

const loginErrorMessage = 'Benutzername oder Passwort falsch!';

type FormValues = {
  username: string;
  password: string;
};

const Login = (props: RouteComponentProps) => {
  const { setToken, status } = useCurrentUser();

  const [login] = useMutation<UserLogin, UserLoginVariables>(
    USER_LOGIN_MUTATION,
    {
      onCompleted: (data) => {
        setToken(data.userLogin.token);
        props.history.push('/');
      },
    }
  );

  // Prevent calling the login page when the user is already logged in
  if (status === 'LoggedIn') {
    return <Redirect to="/" />;
  }

  return (
    <FullHeight alignItems="center">
      <Container>
        <GlowBoxBase>
          <FlexChild flex={1}>
            <Headline.H2>Willkommen</Headline.H2>
            <Subline>
              Bitte melden Sie sich an,
              <br />
              um fortzufahren.
            </Subline>

            <Form
              onSubmit={async (values) => {
                // Need explicit cast here because the typescript support for final form is not
                // that good
                const formValues: FormValues = values as FormValues;

                try {
                  await login({
                    variables: {
                      input: {
                        username: formValues.username,
                        password: formValues.password,
                      },
                    },
                  });
                } catch (err) {
                  return {
                    username: loginErrorMessage,
                    password: loginErrorMessage,
                  };
                }
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="username"
                    // @ts-ignore
                    component={InputText}
                    label="Benutzername:"
                    placeholder="Benutzername"
                  />
                  <Field
                    name="password"
                    // @ts-ignore
                    component={InputText}
                    label="Passwort:"
                    placeholder="**********"
                    password
                  />

                  <Box mt={5}>
                    <PrimaryButton type="submit">Anmelden</PrimaryButton>
                  </Box>
                </form>
              )}
            />
          </FlexChild>
        </GlowBoxBase>
      </Container>
    </FullHeight>
  );
};

export default Login;
