import * as React from 'react';
import { useState } from 'react';
import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from 'downshift';

import { Item } from '../ActionList';
import * as SelectMenu from '../SelectMenu';
import { AnchoredOverlay } from '../AnchoredOverlay';

import {
  StyledCombobox,
  StyledSearchInput,
  StyledSelectPanel,
} from './SelectPanel.css';

type ItemInput = {
  id: string;
  label: string;
};

/* -----------------------------------------------------------------------------
 * FilterList
 * ---------------------------------------------------------------------------*/

function filterListStateReducer<TItems = ItemInput>(
  state: UseComboboxState<TItems>,
  actionAndChanges: UseComboboxStateChangeOptions<TItems>
) {
  const { type, changes } = actionAndChanges;

  switch (type) {
    // Don't insert the selected item into the filter field
    case useCombobox.stateChangeTypes.ItemClick:
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
      return {
        ...changes,
        ...(changes.selectedItem && {
          inputValue: state.inputValue,
        }),
      };
  }

  return changes;
}

type FilterListProps = {
  /**
   * Items that should be showed when the FilterList is opened the first time
   * Should be a subset of items
   */
  initialItems?: ItemInput[];

  /**
   * Items that are used for the filtering
   */
  items: ItemInput[];
  open: boolean;
  placeholderText: string;
  onOpenChange(isOpen: boolean): void;
  onSelectedChange(item: ItemInput): void;
};

const FilterList = ({
  initialItems,
  items,
  open,
  placeholderText,
  onSelectedChange,
  onOpenChange,
}: FilterListProps) => {
  const [itemsToRender, setItemsToRender] = useState(initialItems ?? items);
  const filterItems = (filterText: string) => {
    return items.filter((item) => {
      const { label } = item;
      return label.toLowerCase().startsWith(filterText.toLowerCase());
    });
  };

  const {
    highlightedIndex,
    getMenuProps,
    getItemProps,
    getInputProps,
    getComboboxProps,
  } = useCombobox({
    items,
    // Always open because it is wrapped inside a dropdown
    isOpen: true,
    // First element in the list should be highlighted
    defaultHighlightedIndex: 0,
    stateReducer: filterListStateReducer,
    onIsOpenChange: () => {
      // Only trigger when the modal should be closed, since the modal is
      // controlled by parent component
      if (open) {
        onOpenChange(false);
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      // TODO: When list is filtered, incorrect item is returned when selected
      // by keyboard
      if (selectedItem) {
        onSelectedChange(selectedItem);
      }
    },

    onInputValueChange: ({ inputValue }) => {
      if (inputValue !== undefined) {
        setItemsToRender(filterItems(inputValue));
      } else {
        // Reset
        return setItemsToRender(items);
      }
    },
  });

  return (
    <StyledCombobox {...getComboboxProps()}>
      <StyledSearchInput
        {...getInputProps()}
        type="text"
        placeholder={placeholderText}
      />
      <div {...getMenuProps()}>
        {itemsToRender.map((item, index) => {
          const itemProps = getItemProps({
            item,
            index,
          });

          return (
            <Item
              {...itemProps}
              key={item.id}
              // Mapping onMouseDown to onClick here because when used together
              // with SelectMenu, selectMenu receives the `onMouseDown` first
              onMouseDown={itemProps.onClick}
              isHighlighted={highlightedIndex === index}
              originalValue={item.label}
            ></Item>
          );
        })}
      </div>
    </StyledCombobox>
  );
};

/* -----------------------------------------------------------------------------
 * SelectPanel
 * ---------------------------------------------------------------------------*/

type SelectAnchorProps = {
  onFocus?(): void;
};

type SelectPanelProps = FilterListProps & {
  renderAnchor(props: SelectAnchorProps): JSX.Element;
  onFocus?(): void;
};

const SelectPanel = ({
  open,
  onOpenChange,
  onFocus,
  renderAnchor,
  ...restProps
}: SelectPanelProps) => {
  const onAnchorFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <SelectMenu.Root open={open} onOpenChange={onOpenChange}>
      <StyledSelectPanel.Root>
        {renderAnchor({ onFocus: onAnchorFocus })}
        <StyledSelectPanel.Modal
          onMouseDownInside={(event) => {
            // Prevent closing of the modal when clicking inside the modal
            event.preventDefault();
          }}
        >
          <AnchoredOverlay>
            <FilterList
              open={open}
              onOpenChange={onOpenChange}
              {...restProps}
            />
          </AnchoredOverlay>
        </StyledSelectPanel.Modal>
      </StyledSelectPanel.Root>
    </SelectMenu.Root>
  );
};

export type { SelectPanelProps, SelectAnchorProps };
export { SelectPanel };
