/**
 * Wrapped TextLabelFloat component with a downshift combobox
 */

import * as React from 'react';
import { useEffect, useCallback, useMemo } from 'react';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';

import { useComboboxState } from './text-label-float-combobox.reducer';
import { GroupItemResult } from '../form-data-loader';
import { TextLabelFloat } from './text-label-float';
import { StyledContent } from '../dropdown/style';
import { ComboboxItem } from '../combobox/combobox';

/**
 * Format that should be used for currency formatting
 * 123.456,79
 */
const currencyFormat = new Intl.NumberFormat('de-DE', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Format that should be used for integer formatting (no fraction digits)
 * 123.456
 */
const integerFormat = new Intl.NumberFormat('de-DE', {
  style: 'decimal',
  maximumFractionDigits: 0,
});

/* -----------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------*/

const StyledRoot = styled.div({
  position: 'relative',
});

const StyledMenuContentWrapper = styled.div({
  position: 'absolute',
  zIndex: 10000,
  left: 0,
  right: 0,
});

/* -----------------------------------------------------------------------------
 * TextLabelFloatComboBox
 * ---------------------------------------------------------------------------*/

// Calculates the textValue for the combobox text input when an item is selected
// from the suggestion list
const getItemToString = (type: 'STRING' | 'CURRENCY' | 'INTEGER') => (
  item: GroupItemResult | null | undefined
) => {
  // Get inputValue from suggestion
  if (item !== null && item !== undefined) {
    const { unit, number, originalValue } = item.value;

    if (typeof number === 'number') {
      // TODO: Add number formatting
      return number.toString();
    }

    if (typeof unit === 'string') {
      return unit;
    }

    return originalValue;
  }

  return '';
};

interface TextLabelFloatComboBoxProps
  extends FieldRenderProps<any, HTMLInputElement> {
  inputType?: 'STRING' | 'CURRENCY' | 'INTEGER';
  items: GroupItemResult[];
  label: string;
  open?: boolean;
  renderExtension?: React.ReactNode;
  onHighlightChange(id: string): void;
  onOpenChange?(isOpen: boolean): void;
  onSelectSuggestion?(suggestion: GroupItemResult): void;
}

const TextLabelFloatComboBox = ({
  input,
  inputType = 'STRING',
  items,
  label,
  meta,
  open: externalOpen,
  renderExtension,
  onHighlightChange,
  onOpenChange,
  onSelectSuggestion,
}: TextLabelFloatComboBoxProps) => {
  const { open, send } = useComboboxState(externalOpen, onOpenChange);
  const onChange = input.onChange;
  const onInputValueChange = useCallback(
    (changes: UseComboboxStateChange<GroupItemResult>): void => {
      switch (changes.type) {
        case useCombobox.stateChangeTypes.InputChange: {
          const inputValue = changes.inputValue as string;
          onChange(inputValue);
          break;
        }

        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter: {
          if (changes.selectedItem) {
            const suggestion = changes.selectedItem;

            let inputValue: string;

            if (typeof suggestion.value.number === 'number') {
              inputValue = suggestion.value.number.toString();
            } else if (typeof suggestion.value.unit === 'string') {
              // Set the unit instead of the original value when the
              // field has a unit but no number
              inputValue = suggestion.value.unit;
            } else {
              inputValue = suggestion.value.originalValue;
            }

            onChange(inputValue);

            if (onSelectSuggestion) {
              onSelectSuggestion(suggestion);
            }
          }
        }
      }
    },
    [input.name, onChange, onSelectSuggestion]
  );
  const itemToString = useMemo(() => getItemToString(inputType), [inputType]);
  const hasError = !!meta.error;

  useEffect(() => {
    // Notify reducer about error state
    if (hasError) {
      send({ type: 'FIELD_IS_INVALID' });
    } else {
      send({ type: 'FIELD_IS_VALID' });
    }
  }, [hasError, send]);

  const {
    isOpen,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    items,
    initialHighlightedIndex: 0,
    isOpen: open,
    itemToString,
    onInputValueChange,
    onStateChange: (event) => {
      switch (event.type) {
        case useCombobox.stateChangeTypes.InputKeyDownArrowDown: {
          send({ type: 'FIELD_ON_ARROW_DOWN_KEY' });
          break;
        }

        case useCombobox.stateChangeTypes.InputBlur: {
          send({ type: 'FIELD_ON_BLUR' });
          break;
        }
        case useCombobox.stateChangeTypes.InputKeyDownEscape: {
          send({ type: 'FIELD_ON_ESCAPE_KEY' });
          break;
        }
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter: {
          // TODO: Send selected item to state machine
          send({ type: 'SELECT_SUGGESTION' });
          break;
        }
      }
    },
  });

  useEffect(() => {
    if (
      isOpen &&
      highlightedIndex !== undefined &&
      highlightedIndex >= 0 &&
      items[highlightedIndex]
    ) {
      onHighlightChange(items[highlightedIndex].id);
    }
  }, [isOpen, items, onHighlightChange, highlightedIndex]);

  const {
    // don't override value since it is handled by downshift
    value: _externalValue,
    onFocus: externalOnFocus,
    ...externalInputProps
  } = input;
  const { ref: inputRef, ...inputProps } = getInputProps({
    ...externalInputProps,
    onFocus(event) {
      if (externalOnFocus) {
        externalOnFocus(event);
      }
      send({ type: 'FIELD_ON_FOCUS' });
    },
    onPointerDown() {
      send({ type: 'FIELD_ON_CLICK' });
    },
  });

  return (
    <StyledRoot {...getComboboxProps()}>
      <TextLabelFloat
        ref={inputRef}
        label={label}
        meta={meta}
        input={inputProps}
        renderExtension={renderExtension}
        randomizeInputName
      />

      <StyledMenuContentWrapper {...getMenuProps()}>
        {isOpen ? (
          <StyledContent minWidth="100%">
            {/* Render Dropdown */}
            {items.map((item, index) => {
              const { value, type } = item;

              return (
                <ComboboxItem
                  key={item.id}
                  isHighlighted={highlightedIndex === index}
                  originalValue={value.originalValue}
                  unit={value.unit}
                  numberSystem={value.numberSystem}
                  number={value.number}
                  subItems={'items' in item ? item.items : undefined}
                  focusHighlight={onHighlightChange}
                  type={type}
                  {...getItemProps({ item, index })}
                ></ComboboxItem>
              );
            })}
          </StyledContent>
        ) : null}
      </StyledMenuContentWrapper>
    </StyledRoot>
  );
};

export type { TextLabelFloatComboBoxProps };
export { TextLabelFloatComboBox };
