import * as React from 'react';
import withSideEffect from 'react-side-effect';

type PropsList = {
  title: string;
  children?: JSX.Element;
};

const mainTitle = 'Datenextraktion für Exposés';

// TODO: Upgrade `react-side-effect` dependency to latest version to remove
//       `any` type.
const SiteTitle = ({ children }: { children?: JSX.Element }) => {
  if (children) {
    // Empty Wrapper is a TS-workaround for React.Children.only
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
    return <>{React.Children.only(children)}</>;
  } else {
    return null;
  }
};

function reducePropsToState(propsList: PropsList[]) {
  const innermostProps = propsList[propsList.length - 1];
  if (innermostProps) {
    return innermostProps.title;
  }
}

function handleStateChangeOnClient(title?: string) {
  document.title = title ? `${title} – ${mainTitle}` : mainTitle;
}

export default withSideEffect<PropsList, any>(
  reducePropsToState,
  handleStateChangeOnClient
)(SiteTitle);
