import * as React from 'react';
import styled, { css } from 'styled-components';

import { getColor } from '../layout/styled-helpers';
import { boxShadow, Box } from '../layout';

const TextInput = styled.input`
  border-radius: 6px;
  width: 100%;
  border: none;
  padding: 13px 16px 15px;

  ${boxShadow};

  ${({ hasError }) =>
    hasError &&
    css`
      border: 2px solid ${getColor('red-500')};
      padding: 11px 14px 13px;
    `};
`;

const TextLabel = styled.label`
  display: block;
  color: ${({ hasError }) =>
    hasError ? getColor('red-500') : getColor('gray-500')};
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
  letter-spacing: 0.06em;
`;

// type Props = {
//   label: string,
//   placeholder: string,
//   password?: boolean,
//   focus?: boolean,
// };

class InputText extends React.Component {
  input = React.createRef();

  componentDidMount() {
    if (this.props.focus) {
      this.input.current.focus();
    }
  }

  render() {
    const hasError = this.props.meta && !!this.props.meta.submitError;

    return (
      <Box mb="8px">
        <TextLabel hasError={hasError}>{this.props.label}</TextLabel>
        <TextInput
          {...this.props.input}
          hasError={hasError}
          type={this.props.password ? 'password' : 'text'}
          placeholder={this.props.placeholder}
          autoComplete={this.props.input.name}
          ref={this.input}
        />
      </Box>
    );
  }
}

export default InputText;
