const defaultHeaders = {
  // Set no chache header for Internet explorer
  'Cache-control': 'no-cache',
  Pragma: 'no-cache',
  Expires: 0,
  // Default content type
  'Content-Type': 'application/json',
};

const HOSTNAME = process.env.REACT_APP_HOSTNAME;
const API_PORT = process.env.REACT_APP_API_PORT;
const SUFFIX = process.env.REACT_APP_SUFFIX;

function getBaseUrl() {
  const protocol = API_PORT === '443' ? 'https://' : 'http://';

  let baseUrl: string;
  if (HOSTNAME === 'UNSAFE_EMPTY_HOSTNAME') {
    // When the Hostname is not set then only use the SUFFIX as baseUrl
    baseUrl = SUFFIX!;
  } else {
    baseUrl = `${protocol}${HOSTNAME}${
      ['80', '443'].indexOf(API_PORT!) === -1 ? `:${API_PORT}` : ''
    }${SUFFIX}`;
  }

  return baseUrl;
}

export { defaultHeaders, getBaseUrl };
