/**
 * The notification-item which is displayed
 */

import * as React from 'react';
import styled from 'styled-components';
import { getColor } from '../layout/styled-helpers';

const Wrapper = styled.div`
  background-color: ${getColor('gray-800')};
  box-shadow: 0px 8px 16px rgba(25, 34, 51, 0.16);
  border-radius: 6px;
  color: #ffffff;
  padding: 8px 12px;
  font-weight: bold;
  line-height: 18px;
  font-size: 13px;
  margin: 0 0 12px;
`;

// type Props = {
//   title: string,
// };

const Notification = (props) => {
  return <Wrapper>{props.title}</Wrapper>;
};

export default Notification;
