import * as React from 'react';
import styled from 'styled-components';

export const Tree = styled.ul`
  margin: 8px 24px;
  padding: 0;
`;

const StyledLeaf = styled.li`
  list-style: none;
  /* border-left: 2px solid rgba(0, 0, 0, 0.2); */
  padding: 0;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 0 0 2px 2px;
    border-style: solid;
    height: 12px;
    width: 8px;
  }

  & > span {
    padding: 0 16px;
    font-size: 13px;
    color: #3c464d;
    display: inline-block;
  }
`;

export const Leaf = ({ children }: React.PropsWithChildren) => (
  <StyledLeaf>
    <span>{children}</span>
  </StyledLeaf>
);
