import { useMutation, useQueryClient } from 'react-query';

import { getBaseUrl } from '../config';
import { useFetch } from '../fetch';
import { camelcaseKeys } from '../utils';
import {
  ProcessedAttachmentItemPlaceholder,
  TicketItemReview,
} from './get-ticket';
import { getTicketAnalysisResultQueryKey } from './get-ticket-analysis-result';

type DocumentType = 'picture' | 'plan';

/**
 * Response from the API for
 * POST /tickets/{ticketId}/add_attachment/
 */
type AddAttachmentResponse = {
  attachment: {
    id: number;
    file_type: 'png' | 'jpeg';
    file_name: string;
    suggestions: [];
    url: string;
    file: string;
    type: DocumentType;
    description: string;
  };
  status: 'OK';
};

const useAddAttachment = () => {
  const { post } = useFetch();
  const baseUrl = getBaseUrl();

  return async (
    ticketId: number,
    documentType: DocumentType,
    description: string,
    dataBlob: Blob,
    fileName: string
  ) => {
    const formData = new FormData();
    formData.append('file', dataBlob, fileName);
    formData.append('type', documentType);
    formData.append('description', description);

    const { data } = await post<AddAttachmentResponse>(
      `${baseUrl}tickets/${ticketId}/add_image/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return camelcaseKeys(data.attachment);
  };
};

type UseAddAttachmentMutationParams = {
  ticketId: number;
  dataURI: string;
  dataBlob: Blob;
  documentType: DocumentType;
  description: string;
  fileName: string;
};

const useAddAttachmentMutation = () => {
  const addAttachment = useAddAttachment();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      ticketId,
      dataBlob,
      documentType,
      description,
      fileName,
    }: UseAddAttachmentMutationParams) => {
      return addAttachment(
        ticketId,
        documentType,
        description,
        dataBlob,
        fileName
      );
    },
    {
      onMutate({ ticketId, dataURI, documentType, description, fileName }) {
        // Adds placeholder data entry to the attachments that can be displayed
        // until the server processed the request.

        // Generate fake id that is outside of range of possible server ids
        const min = -100000;
        const max = 0;
        const fakeRandomId = Math.floor(Math.random() * (max - min) + min);
        const attachmentPlaceholder: ProcessedAttachmentItemPlaceholder = {
          id: fakeRandomId,
          file: dataURI,
          status: 'uploading',
          type: documentType,
          suggestions: [],
          isPlaceholder: true,
          description,
          fileName,
        };
        queryClient.setQueryData<TicketItemReview>(
          getTicketAnalysisResultQueryKey(ticketId),
          (oldTicketData) => {
            if (!oldTicketData) {
              // Should never happen
              throw new Error(`No queryData for ticket '${ticketId}'`);
            }

            return {
              ...oldTicketData,
              attachments: [
                // The placeholder gets added to the first position for better
                // visibility
                attachmentPlaceholder,
                ...oldTicketData.attachments,
              ],
            };
          }
        );

        return { attachmentPlaceholder, description, fileName };
      },
      onSuccess(result, { ticketId }, context) {
        // Replaces placeholder from onMutate with the actual data
        if (!result || !context) {
          // Should never happen
          throw new Error('Invalid response for useAddAttachmentMutation.');
        }

        queryClient.setQueryData<TicketItemReview>(
          getTicketAnalysisResultQueryKey(ticketId),
          (oldTicketData) => {
            if (!oldTicketData) {
              // Should never happen
              throw new Error(`No queryData for ticket '${ticketId}'`);
            }

            return {
              ...oldTicketData,
              attachments: oldTicketData.attachments.map((attachment) => {
                // Replace with server response
                if (attachment.id === context.attachmentPlaceholder.id) {
                  return result;
                }

                return attachment;
              }),
            };
          }
        );
      },
    }
  );
};

export { useAddAttachmentMutation };
