import * as React from 'react';
import styled, { css } from 'styled-components';

import Icon, { IconSize } from '../icon';
import { getSpace, getColor } from '../layout/styled-helpers';

const primaryStyle = css`
  background-color: ${({ disabled }) =>
    disabled ? getColor('gray-300') : getColor('gray-800')};
  color: white;
  border-radius: 6px;
  border: none;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.06em;

  padding: ${getSpace(3, 5)};

  ${(props) =>
    props.block &&
    css`
      display: block;
      width: 100%;
    `};

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? getColor('gray-300') : getColor('black')};
  }
`;

const Button = styled.button`
  ${primaryStyle};
`;

const ButtonSmall = styled(Button)`
  padding: ${getSpace(2)} ${getSpace(3)};
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.06em;
`;

const ButtonLarge = styled(Button)`
  font-size: 16px;
`;

const ButtonPrimary = (props) => {
  return (
    <Button onClick={props.onClick} disabled={props.disabled}>
      {props.label}
    </Button>
  );
};

const ButtonPrimarySmall = ({ label, disabled, onClick, ...rest }) => (
  <ButtonSmall disabled={disabled} onClick={(e) => !disabled && onClick(e)}>
    {label}
  </ButtonSmall>
);

const ButtonPrimaryLarge = ({ label, disabled, onClick, ...rest }) => (
  <ButtonLarge
    disabled={disabled}
    onClick={(e) => !disabled && onClick(e)}
    {...rest}
  >
    {label}
  </ButtonLarge>
);

const ActionButton = styled(Button)`
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.06em;
  padding: 5px 12px 6px;
  margin: 0 6px;
  height: 28px;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
`;

/**
 * Small Button with an optional icon on it
 */
const ButtonPrimaryAction = ({ icon, label, ...rest }) => {
  return (
    <ActionButton {...rest}>
      {label}
      <Icon name={icon} size={IconSize.small} fill="white" ml={1} />
    </ActionButton>
  );
};

export {
  ButtonPrimary as default,
  ButtonPrimarySmall,
  ButtonPrimaryLarge,
  ButtonPrimaryAction,
};
