/**
 * Wrapper which loads the data and prepares it for the editor
 */

import * as React from 'react';

import {
  generateSuggestions,
  normalizeSuggestions,
  groupSuggestions,
} from './suggestions';
import {
  AttachmentSuggestions,
  Attribute,
  Suggestion,
} from './form-data-loader.types';
import { useGetTicketAnalysisResultQuery } from '../../api/ticket/get-ticket-analysis-result';
import {
  ProcessedAttachmentItem,
  TicketItemReview,
} from '../../api/ticket/get-ticket';

/* -----------------------------------------------------------------------------
 * Utils
 * ---------------------------------------------------------------------------*/

/**
 * Extracts the suggestions from the attachment
 */
function normalizeSuggestionForAttachment(attachment: ProcessedAttachmentItem) {
  const suggestionIdPrefix = attachment.id;

  // Parse every attribute in the suggestion
  // The suggestions in the app have a slightly other representation in the app
  // as they are delivered by the API
  // So we need to normalize them first to bring them into the desired format
  const suggestions: Suggestion[] = attachment.suggestions.map(
    (suggestion, index) => {
      const { bbox: extraBoundingBox } = suggestion.extra;
      const attribute: Attribute = {
        match: suggestion.extra.match,
        bbox: extraBoundingBox,
        page: {
          number: parseInt(suggestion.extra.page.number, 10),
          bbox: suggestion.extra.page.bbox,
        },
        coordinates: suggestion.extra.coordinates,
      };

      return {
        id: `${suggestionIdPrefix}_${index}`,
        type: 'SUGGESTION',
        attribute,
        value: suggestion.value,
        kpi: suggestion.kpi,
      };
    }
  );

  // Normalize all suggestions by id
  const normalizedSuggestions = normalizeSuggestions(suggestions);
  const suggestionsByKey = generateSuggestions(suggestions);
  const groupedSuggestions = groupSuggestions(suggestionsByKey);

  return {
    suggestions: normalizedSuggestions,
    suggestionsByKey,
    groupedSuggestions,
  };
}

/* -----------------------------------------------------------------------------
 * Hook
 * ---------------------------------------------------------------------------*/

type UseFormDataLoaderReturnType = {
  attachmentSuggestions: Record<number, AttachmentSuggestions>;
  data: TicketItemReview;
} | null;

function useFormDataLoader(ticketId: number): UseFormDataLoaderReturnType {
  const { data: ticket } = useGetTicketAnalysisResultQuery({ ticketId });

  return React.useMemo(() => {
    if (!ticket) {
      return null;
    }

    const attachmentSuggestions: Record<number, AttachmentSuggestions> = {};

    // Generate the suggestions for each attachment
    const attachmentSuggestionsToProcess = ticket.attachments.filter(
      (attachment): attachment is ProcessedAttachmentItem =>
        !('isPlaceholder' in attachment) && attachment.type === 'document'
    );

    for (const attachmentSuggestion of attachmentSuggestionsToProcess) {
      attachmentSuggestions[
        attachmentSuggestion.id
      ] = normalizeSuggestionForAttachment(attachmentSuggestion);
    }

    return { attachmentSuggestions, data: ticket };
  }, [ticket]);
}

export { useFormDataLoader, normalizeSuggestionForAttachment };
