import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getAnnotationsByPage } from '../../redux/pdf-annotations/reducer';

const Frame = styled.div`
  position: absolute;
  top: ${(props) => props.y * 100}%;
  left: ${(props) => props.x * 100}%;
  height: ${(props) => props.height * 100}%;
  width: ${(props) => props.width * 100}%;
`;

// Provide an inner frame to add padding for the highlighted box
// Frame padding is in percent
const FRAME_PADDING = 8;
const FrameInner = styled.div`
  background: rgba(255, 204, 51, 0.12);
  border: 3px solid #ffcc33;

  position: absolute;
  top: -${FRAME_PADDING}px;
  right: -${FRAME_PADDING * 2}px;
  bottom: -${FRAME_PADDING}px;
  left: -${FRAME_PADDING * 2}px;
`;

class AnnotationLayer extends React.Component {
  render() {
    const { annotations } = this.props;

    if (!annotations) {
      return null;
    }

    return (
      <div>
        {annotations.map((annotation, index) => (
          <Frame
            key={index}
            x={annotation.x}
            y={annotation.y}
            width={annotation.width}
            height={annotation.height}
          >
            <FrameInner />
          </Frame>
        ))}
      </div>
    );
  }
}

export default connect((state, props) => ({
  annotations: getAnnotationsByPage(
    state.pdfAnnotations,
    props.pdfPage.pageNumber
  ),
}))(AnnotationLayer);
