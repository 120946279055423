import * as React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import { getColor } from '../layout/styled-helpers';

// Default size
import { ReactComponent as ListIcon } from './assets/list.svg';
import { ReactComponent as ProcessedIcon } from './assets/processed.svg';
import { ReactComponent as ProcessingIcon } from './assets/processing.svg';
import { ReactComponent as ArchiveIcon } from './assets/archive.svg';
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg';
import { ReactComponent as Spinner } from './assets/spinner.svg';
import { ReactComponent as Mail } from './assets/mail.svg';
import { ReactComponent as Document } from './assets/document.svg';
import { ReactComponent as Plus } from './assets/plus.svg';
import { ReactComponent as Minus } from './assets/minus.svg';
import { ReactComponent as Trash } from './assets/trash.svg';
import { ReactComponent as NavigationBack } from './assets/navigation-back.svg';
import { ReactComponent as Cross } from './assets/cross.svg';

// Small size
import { ReactComponent as ArchiveIconSmall } from './assets/small/archive.svg';
import { ReactComponent as ProcessIconSmall } from './assets/small/process.svg';
import { ReactComponent as CrossIconSmall } from './assets/small/cross.svg';
import { ReactComponent as ArrowLeftSmall } from './assets/small/arrow-left.svg';
import { ReactComponent as ArrowDownSmall } from './assets/small/arrow-down.svg';
import { ReactComponent as ArrowRightSmall } from './assets/small/arrow-right.svg';
import { ReactComponent as PlusSmall } from './assets/small/plus.svg';
import { ReactComponent as EditSmall } from './assets/small/edit.svg';
import { ReactComponent as TrashSmall } from './assets/small/trash.svg';
import { ReactComponent as TimerSmall } from './assets/small/timer.svg';
import { ReactComponent as LocationSmall } from './assets/small/location.svg';

export enum IconsDefault {
  list = 'list',
  processed = 'processed',
  processing = 'processing',
  archive = 'archive',
  arrowLeft = 'arrowLeft',
  spinner = 'spinner',
  mail = 'mail',
  document = 'document',
  plus = 'plus',
  minus = 'minus',
  trash = 'trash',
  navigationBack = 'navigationBack',
  cross = 'cross',
}

type IconsDefaultList = {
  [key in IconsDefault]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
};

// Matches the name to the icon
const iconsDefault: IconsDefaultList = {
  [IconsDefault.list]: ListIcon,
  [IconsDefault.processed]: ProcessedIcon,
  [IconsDefault.processing]: ProcessingIcon,
  [IconsDefault.archive]: ArchiveIcon,
  [IconsDefault.arrowLeft]: ArrowLeft,
  [IconsDefault.spinner]: Spinner,
  [IconsDefault.mail]: Mail,
  [IconsDefault.document]: Document,
  [IconsDefault.plus]: Plus,
  [IconsDefault.minus]: Minus,
  [IconsDefault.trash]: Trash,
  [IconsDefault.navigationBack]: NavigationBack,
  [IconsDefault.cross]: Cross,
};

export enum IconsSmall {
  archive = 'archive',
  process = 'process',
  cross = 'cross',
  arrowLeft = 'arrowLeft',
  arrowDown = 'arrowDown',
  arrowRight = 'arrowRight',
  plus = 'plus',
  edit = 'edit',
  trash = 'trash',
  timer = 'timer',
  location = 'location',
}

type IconsSmallList = {
  [key in IconsSmall]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
};

const iconsSmall: IconsSmallList = {
  [IconsSmall.archive]: ArchiveIconSmall,
  [IconsSmall.process]: ProcessIconSmall,
  [IconsSmall.cross]: CrossIconSmall,
  [IconsSmall.arrowLeft]: ArrowLeftSmall,
  [IconsSmall.arrowDown]: ArrowDownSmall,
  [IconsSmall.arrowRight]: ArrowRightSmall,
  [IconsSmall.plus]: PlusSmall,
  [IconsSmall.edit]: EditSmall,
  [IconsSmall.trash]: TrashSmall,
  [IconsSmall.timer]: TimerSmall,
  [IconsSmall.location]: LocationSmall,
};

export enum IconSize {
  small = 16,
  default = 32,
}

const IconWrapper = styled.span<{ size: IconSize; fill?: string }>`
  ${space} display: inline-block;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  vertical-align: bottom;

  & path,
  & rect {
    fill: ${(props) =>
      props.fill ? getColor(props.fill) : getColor('gray-800')};
  }
`;

interface Props {
  size?: IconSize;
  name: IconsDefault | IconsSmall;
  fill?: string;
}

const Icon = ({ size = IconSize.default, name, fill }: Props) => {
  const SeletedIcon =
    size === IconSize.small
      ? iconsSmall[name as IconsSmall]
      : iconsDefault[name as IconsDefault];

  if (!SeletedIcon) {
    return null;
  }

  return (
    <IconWrapper fill={fill} size={size}>
      <SeletedIcon width={size} height={size} />
    </IconWrapper>
  );
};

interface IconProps {
  fill?: string;
}

interface MediumIconProps extends IconProps {
  name: keyof typeof IconsDefault;
}

interface SmallIconProps extends IconProps {
  name: keyof typeof IconsSmall;
}

export const MediumIcon = ({ name, fill }: MediumIconProps) => {
  const iconName = IconsDefault[name];
  return <Icon name={iconName} size={IconSize.default} fill={fill} />;
};

export const SmallIcon = ({ name, fill }: SmallIconProps) => {
  const iconName = IconsSmall[name];
  return <Icon name={iconName} size={IconSize.small} fill={fill} />;
};

export default Icon;
