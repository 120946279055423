import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import {
  UploadAttachment as UploadAttachmentT,
  UploadAttachmentVariables,
} from '../../types/apollo/UploadAttachment';
import {
  DeleteAttachment as DeleteAttachmentT,
  DeleteAttachmentVariables,
} from '../../types/apollo/DeleteAttachment';

export const UPLOAD_ATTACHMENT = gql`
  mutation UploadAttachment($file: File!, $ticketId: ID!) {
    uploadAttachment(file: $file, ticketId: $ticketId) {
      attachment @type(name: "Attachment") {
        id
        file
      }
      status
      duplicates @type(name: "AttachmentsDuplicate") {
        ticketId
      }
    }
  }
`;

export class UploadAttachment extends Mutation<
  UploadAttachmentT,
  UploadAttachmentVariables
> {
  static defaultProps = {
    mutation: UPLOAD_ATTACHMENT,
  };
}

const DElETE_ATTACHMENT = gql`
  mutation DeleteAttachment($attachmentId: ID!) {
    deleteAttachment(attachmentId: $attachmentId)
    @rest(
      type: "Void"
      path: "attachments/{args.attachmentId}/"
      method: "DELETE"
    ) {
      NoResponse
    }
  }
`;

export class DeleteAttachment extends Mutation<
  DeleteAttachmentT,
  DeleteAttachmentVariables
> {
  static defaultProps = {
    mutation: DElETE_ATTACHMENT,
  };
}
