import { RestLink } from 'apollo-link-rest';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { snakeCase } from 'lodash-es';
import camelCase from 'camelcase';
import { ServerError } from 'apollo-link-http-common';

import { getBaseUrl } from './config';
import { UploadLink } from './apollo-link-upload';
import { getAccessToken, removeAccessToken } from '../utils/access-token';

const baseURL = getBaseUrl();

const authLink = setContext((request) => {
  const _headers = request.variables?.context?.headers ?? {};

  // Set no chache header for Internet explorer
  const headers = {
    'Cache-control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 0,
    ..._headers,
  };

  // Skip authorization header for login
  if (request.operationName === 'UserLogin') {
    return headers;
  }

  const token = getAccessToken();

  return {
    headers: {
      Authorization: token ? `Token ${token.token}` : '',
      ...headers,
    },
  };
});

const restLink = new RestLink({
  uri: baseURL,
  fieldNameNormalizer: (key: string) => camelCase(key),
  fieldNameDenormalizer: (key: string) => snakeCase(key),
});

const uploadLink = new UploadLink({
  uri: `${baseURL}tickets/`,
});

function logoutUser() {
  removeAccessToken();
  window.location.replace('/login');
}

const errorLink = onError(({ networkError, operation }) => {
  // If something is wrong with the currentUser let the user re-authenticate
  if (operation.operationName === 'CurrentUser') {
    logoutUser();
  }

  if (networkError) {
    // Treat networkError as ServerError
    if ('statusCode' in networkError) {
      const serverError = networkError as ServerError;

      // User is not logged in anymore, redirect user to login page
      if (serverError.statusCode === 401) {
        logoutUser();
      }
    }
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, restLink, uploadLink]),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development',
});

export { client };
