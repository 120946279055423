/**
 * Provides a wrapper which calls the backend for the current user
 */

import * as React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import decodeToken from 'jwt-decode';

import { CURRENT_USER_QUERY } from '../../queries/user/current-user';
import { CurrentUser as CurrentUserT } from '../../types/apollo/CurrentUser';
import {
  getAccessToken,
  setAccessToken,
  removeAccessToken,
  AccessToken,
} from '../../utils/access-token';
import { useGetTokenRefreshMutation } from '../../api/auth/token/refresh/token-refresh';

type LoginStatus = 'LoggedIn' | 'LoggedOut';

interface Context {
  user?: CurrentUserT['currentUser'];
  setToken: (token: string) => Promise<void>;
  removeToken: () => void;
  status: LoginStatus;
}

export const CurrentUserContext = React.createContext<Context | undefined>(
  undefined
);

export const CurrentUserProvider = ({ children }: React.PropsWithChildren) => {
  const [
    memoryAccessToken,
    setMemoryAccessToken,
  ] = React.useState<AccessToken | null>(getAccessToken);

  const loginStatus = memoryAccessToken !== null ? 'LoggedIn' : 'LoggedOut';

  // Get the data for the current user from the server
  const [getCurrentUserData, { data, error }] = useLazyQuery<CurrentUserT>(
    CURRENT_USER_QUERY
  );

  const { mutateAsync: getRefreshToken } = useGetTokenRefreshMutation();

  // Update when memoryToken Changes
  React.useEffect(() => {
    if (memoryAccessToken === null) {
      // Remove token from localstorage
      removeAccessToken();
    } else if (memoryAccessToken /*&& data === undefined*/ && !error) {
      setAccessToken(memoryAccessToken.token, memoryAccessToken.expiresAt);
      getCurrentUserData();
    }
  }, [memoryAccessToken, data, error, getCurrentUserData]);

  const setToken = React.useRef(async (token: string) => {
    // Get refresh token from API
    const refreshTokenResponse = await getRefreshToken(token);

    if (refreshTokenResponse) {
      // Decode the JWT Token
      const { exp } = decodeToken<{ exp: number }>(refreshTokenResponse.token);

      const accessToken: AccessToken = {
        token: refreshTokenResponse.token,
        expiresAt: new Date(exp * 1000),
        hostName: process.env.REACT_APP_HOSTNAME || '',
      };

      setMemoryAccessToken(accessToken);
    }
  });

  const removeToken = React.useRef(() => {
    setMemoryAccessToken(null);
  });

  // Attach listener for local-storage events to refresh the login status when something changes
  // e.g. logout in other tab
  const localStorageListener = React.useRef(() => {
    setMemoryAccessToken(getAccessToken());
  });

  React.useEffect(
    () => {
      if (loginStatus === 'LoggedIn') {
        getCurrentUserData();
      }

      window.addEventListener('storage', localStorageListener.current);

      return () => {
        // TODO: Check if eslint is correct here
        // eslint-disable-next-line
        window.removeEventListener('storage', localStorageListener.current);
      };
    }, // TODO: Check if eslint is correct here
    // eslint-disable-next-line
    []
  );

  const context = React.useMemo<Context>(() => {
    return {
      user: loginStatus === 'LoggedIn' && data ? data.currentUser : undefined,
      status: loginStatus,
      setToken: setToken.current,
      removeToken: removeToken.current,
    };
  }, [loginStatus, data]);

  return (
    <CurrentUserContext.Provider value={context}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export function useCurrentUser() {
  const context = React.useContext(CurrentUserContext);

  if (!context) {
    throw new Error('useCurrentUser must be used within CurrentUserProvider');
  }

  return context;
}

export const CurrentUser = CurrentUserContext.Consumer;
