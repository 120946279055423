import camelcaseKeys from 'camelcase-keys';

/**
 * Wrapper around camelcase-keys that ensures that deep: true is set by default.
 * @param input
 * @returns
 */
function camelcaseKeysDeep<T extends Record<string, any> | readonly any[]>(
  input: T
) {
  return camelcaseKeys(input, { deep: true });
}

export { camelcaseKeysDeep as camelcaseKeys };
