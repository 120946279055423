import { useMutation, useQueryClient } from 'react-query';

import { getBaseUrl } from '../config';
import { useFetch } from '../fetch';
import { camelcaseKeys } from '../utils';
import { TicketItemReview } from './get-ticket';
import { getTicketAnalysisResultQueryKey } from './get-ticket-analysis-result';

type PatchTicketParams = {
  main_picture_id: number | null;
};

const usePatchTicket = () => {
  const { patch } = useFetch();
  const baseUrl = getBaseUrl();

  return async (ticketId: number, params: PatchTicketParams) => {
    const { data } = await patch<TicketItemReview>(
      `${baseUrl}tickets/${ticketId}/`,
      params
    );

    return camelcaseKeys(data);
  };
};

type UsePatchTicketMutationParams = {
  ticketId: number;
  mainPictureId: number | null;
};

function usePatchTicketMutation() {
  const patchTicket = usePatchTicket();
  const queryClient = useQueryClient();

  return useMutation(
    ({ ticketId, mainPictureId }: UsePatchTicketMutationParams) => {
      return patchTicket(ticketId, {
        main_picture_id: mainPictureId,
      });
    },
    {
      onSuccess(data, { ticketId }) {
        queryClient.setQueryData<TicketItemReview>(
          getTicketAnalysisResultQueryKey(ticketId),
          (oldTicketData) => {
            if (oldTicketData === undefined) {
              throw new Error(`No queryData for ticket '${ticketId}'`);
            }

            return {
              ...oldTicketData,
              mainPictureId: data.mainPictureId,
            };
          }
        );
      },
    }
  );
}

export { usePatchTicketMutation };
