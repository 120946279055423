/**
 * Displays the left form for the editor
 */

import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, FormProps, useForm } from 'react-final-form';
import { useMutation } from '@apollo/react-hooks';
import camelcase from 'camelcase';
import setFieldData from 'final-form-set-field-data';

import { Box, Flex, FlexChild } from '../../../../components/layout';
import ButtonPrimary from '../../../../components/button/primary';
import WorkspaceForm from './form';
import { useNotification } from '../../../../components/notifications';
import { GroupedSuggestions } from '../../../../components/form-data-loader';
import { TICKET_SUBMIT } from '../../../../mutations/tickets/ticket';
import { FileList } from './file-list';
import { VersionInfo } from '../../../../components/version-info';
import { useModal, ModalTemplate } from '../../../../components/modal';
import {
  TicketSubmit,
  TicketSubmitVariables,
} from '../../../../types/apollo/TicketSubmit';
import {
  setAddressData,
  resetSuggestion,
  setSuggestion,
} from '../../../../components/form-data';
import { initialFormData } from '../../../../components/form-data/form-data';
import { TicketFormData } from '../../../../components/form-data/form-data.types';
import {
  normalizeFormDataToValidateResultPayload,
  PostValidateResultPayload,
} from '../../../../components/form-data/form-data.normalizer';
import { validateFormInputs } from '../../../../components/form-data-validation';
import {
  ProcessedAttachmentItem,
  TicketItemDone,
  TicketItemReview,
} from '../../../../api/ticket/get-ticket';
import { FormApi } from 'final-form';

const Wrapper = styled.div`
  display: flex;
  width: 470px;
  z-index: 900;
  flex-direction: column;

  /* Fix for 100% */
  max-height: 100vh;
  padding-top: 60px;
  margin-top: -60px;
`;

const ItemList = styled(Flex)`
  overflow-x: hidden;
  overflow-y: auto;
`;

function parseFormError(errors: { [key: string]: string[] }) {
  const result: Record<string, string> = {};

  Object.keys(errors).forEach((key) => {
    const parsedKey = camelcase(key);
    if (parsedKey in initialFormData && Array.isArray(errors[key])) {
      result[parsedKey] = errors[key][0];
    }
  });

  return result;
}

interface Props {
  suggestions: GroupedSuggestions;
  showBox: (id: string) => void;
  ticket: TicketItemReview | TicketItemDone;
  changeFile: (id: number) => void;
  currentAttachmentId: number;
}

const EditorWorkspace = ({
  showBox,
  suggestions,
  ticket,
  currentAttachmentId,
  changeFile,
}: Props) => {
  const { showModal } = useModal();
  const { createNotification } = useNotification();

  const location = useLocation();
  const history = useHistory();

  const [hasPictureAttachment, setHasPictureAttachment] = useState(false);

  const [submitAnalysis] = useMutation<TicketSubmit, TicketSubmitVariables>(
    TICKET_SUBMIT,
    {
      onCompleted() {
        if (location.state && location.state.fromDashboard) {
          createNotification(`„${ticket.title}“ wurde abgeschlossen.`);
          history.goBack();
        } else {
          // When the user comes from a direct link we only show a success message
          // and leave her on the site
          showModal({
            template: ModalTemplate.TICKET_SUCCESS,
            title: 'Daten erfolgreich erfasst',
          });
        }
      },
    }
  );

  React.useEffect(() => {
    // Check if the ticket was already reviewed
    if (ticket.status === 'done') {
      showModal({
        template: ModalTemplate.TICKET_SUCCESS,
        title: 'Daten erfolgreich erfasst',
      });
    }
  }, [ticket.status, showModal]);

  React.useEffect(() => {
    const numberOfPictureAttachments = ticket.attachments.filter(
      (attachment): attachment is ProcessedAttachmentItem =>
        attachment.type !== 'document'
    ).length;

    setHasPictureAttachment(numberOfPictureAttachments > 0);
  }, [ticket.attachments]);

  async function submitResults(
    ticketId: string,
    input: PostValidateResultPayload
  ) {
    try {
      await submitAnalysis({
        variables: {
          ticketId: ticketId,
          input: input,
        },
      });
    } catch (err) {
      // @ts-ignore
      const body = err.networkError && err.networkError.result;
      let formError: any;

      if (body) {
        formError = parseFormError(body);
      }

      showModal({
        template: ModalTemplate.TICKET_ERROR,
        title: 'Daten konnten nicht erfasst werden',
        props: { formError },
      });

      if (formError) {
        return formError;
      }
    }
  }

  const onSubmit = React.useCallback(
    async (values: TicketFormData) => {
      validateFormInputs(values);
      const normalizedValues = normalizeFormDataToValidateResultPayload(values);

      if (!hasPictureAttachment) {
        showModal(
          {
            template: ModalTemplate.TICKET_WARNING,
            title: 'Keine Snippets',
          },
          (cancel) => {
            if (!cancel) {
              submitResults(ticket.id.toString(), normalizedValues);
            }
          }
        );
      } else {
        submitResults(ticket.id.toString(), normalizedValues);
      }
    },
    [showModal, ticket.id, submitAnalysis, hasPictureAttachment]
  );

  const [currentIndex, attachmentList] = React.useMemo(() => {
    let currentIndex = 0;
    const attachmentList = ticket.attachments
      .filter(
        (attachment): attachment is ProcessedAttachmentItem =>
          !('isPlaceholder' in attachment) && attachment.type === 'document'
      )
      .map((_attachment, index) => {
        // TypeScript doesn't check that attachment is already a
        // ProcessedAttachmentItem :(
        const attachment = _attachment as ProcessedAttachmentItem;
        if (attachment.id === currentAttachmentId) {
          currentIndex = index;
        }

        return {
          title: attachment.fileName,
          id: attachment.id,
        };
      });

    return [currentIndex, attachmentList];
  }, [ticket.attachments, currentAttachmentId]);

  return (
    <Wrapper>
      <FileList
        files={attachmentList}
        onChange={changeFile}
        selectedIndex={currentIndex}
      />
      <VersionInfo version={ticket.analysisPackageVersion}></VersionInfo>
      <ItemList flexDirection="column" flex="1" px={3} pt={3}>
        <FlexChild flex="1">
          <Form
            onSubmit={onSubmit}
            initialValues={initialFormData}
            validate={validateFormInputs}
            mutators={{
              setAddressData,
              resetSuggestion,
              setSuggestion,
              // @ts-ignore
              setFieldData,
            }}
          >
            {({ handleSubmit, invalid }) => (
              <>
                <WorkspaceForm
                  suggestions={suggestions}
                  focusHighlight={showBox}
                />
                <Flex flex="1" justifyContent="flex-end" alignItems="center">
                  <Box px={4}>
                    <ButtonPrimary
                      label="Überprüfung abschließen"
                      onClick={handleSubmit}
                      disabled={invalid}
                    />
                  </Box>
                </Flex>
              </>
            )}
          </Form>
        </FlexChild>
      </ItemList>
    </Wrapper>
  );
};

export default EditorWorkspace;
