/**
 * Yuna design (dark color scheme)
 */

import baseTheme, { BaseTheme } from './base';

// TODO: Find a way to precompile this

const colorPrimary = '#024F6C';

const yunaTheme: BaseTheme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    bg: {
      ...baseTheme.colors.bg,
      header: '#024F6C',
      buttonPrimary: colorPrimary,
      buttonAction: colorPrimary,
      highlight: '#5f8895',
    },

    header: {
      ...baseTheme.colors.header,
      borderColor: 'none',
      title: '#ffffff',
    },

    text: {
      ...baseTheme.colors.text,
      buttonPrimary: '#ffffff',
      highlight: '#ffffff',
    },
  },
};

export default yunaTheme;
