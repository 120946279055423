import styled from 'styled-components';
import * as styledSystem from 'styled-system';
import { darken } from 'polished';

/**
 * Button for subactions
 */
const SubActionButton = styled.button<styledSystem.HeightProps>(
  ({ theme }) => ({
    borderRadius: '6px',
    backgroundColor: theme.colors.bg.buttonAction,
    border: 'none',
    fontWeight: 600,
    color: '#ffffff',
    padding: '7px 16px 7px',
    textShadow: '0px 1px 2px rgba(0,0,0, 0.08)',

    '&:hover': {
      backgroundColor: darken(0.1, theme.colors.bg.buttonAction),
    },
  }),
  styledSystem.height
);

const SubActionButtonCover = styled(SubActionButton)`
  display: block;
  width: 100%;
`;

export { SubActionButton, SubActionButtonCover };
