import React from 'react';

import SiteTitle from '../../components/site-title';
import { TicketOverview } from '../components/overview';
import { emptyMessageAnalysis } from '../../translations/empty-messages';
import { Block } from '../../lib/block';
import { filterTicketAnalyis, AnalysisTicketAction } from './tickets-shared';

const siteTitle = 'Analyse';

export const AnalysisTicketsOverview = () => (
  <Block mx={70}>
    <SiteTitle title={siteTitle} />

    <TicketOverview
      filterBy={filterTicketAnalyis}
      emptyMessage={emptyMessageAnalysis}
      overrides={{
        TicketAction: {
          component: AnalysisTicketAction,
        },
      }}
      title={siteTitle}
      hideActionBar
      pollInterval={5000}
    />
  </Block>
);
