import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import cn from 'classnames';

import { ValidationError } from '../form-data-validation';

/* -----------------------------------------------------------------------------
 * Utils
 * ---------------------------------------------------------------------------*/

const ErrorMessages: Record<ValidationError, string> = {
  NOT_A_NUMBER: 'Nur Zahleneingabe (1000,00) erlaubt.',
  INVALID_PROPERTY_USE: 'Ungültige Eingabe. Bitte aus Vorschlägen wählen.',
};

function generateRandomId() {
  return Math.random().toString(16).slice(-8);
}

/* -----------------------------------------------------------------------------
 * Reducer
 * ---------------------------------------------------------------------------*/

interface FloatLabelState {
  status: 'expanded' | 'floatingNoValue' | 'floatingHasValue';
}

const floatLabelInitialState: FloatLabelState = {
  status: 'expanded',
};

interface FloatLabelEventOnFocus {
  type: 'onFocus';
}

interface FloatLabelEventOnBlur {
  type: 'onBlur';
}

interface FloatLabelEventOnChange {
  type: 'onChange';
  payload: {
    data: string;
  };
}

type FloatLabelEvent =
  | FloatLabelEventOnFocus
  | FloatLabelEventOnBlur
  | FloatLabelEventOnChange;

function floatLabelReducer(
  state: FloatLabelState,
  event: FloatLabelEvent
): FloatLabelState {
  switch (state.status) {
    case 'expanded': {
      switch (event.type) {
        case 'onChange': {
          if (event.payload.data !== '') {
            return {
              ...state,
              status: 'floatingHasValue',
            };
          }
          break;
        }

        case 'onFocus': {
          return {
            ...state,
            status: 'floatingNoValue',
          };
        }
      }
      break;
    }

    case 'floatingNoValue': {
      switch (event.type) {
        case 'onChange': {
          if (event.payload.data !== '') {
            return {
              ...state,
              status: 'floatingHasValue',
            };
          }
          break;
        }

        case 'onBlur': {
          return {
            ...state,
            status: 'expanded',
          };
        }
      }
      break;
    }

    case 'floatingHasValue': {
      switch (event.type) {
        case 'onChange': {
          if (event.payload.data === '') {
            return {
              ...state,
              status: 'floatingNoValue',
            };
          }
        }
      }
    }
  }

  return state;
}

/* -----------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------*/

const StyledFormControl = styled.div(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.colors['gray-300']}`,
  fontSize: '16px',

  '&.error': {
    borderColor: theme.colors.error.border,
  },
}));

const StyledFormLabel = styled.label(({ theme }) => ({
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  left: '12px',
  transformOrigin: 'top left',
  fontSize: '14px',
  transform: 'translate(0, 18px) scale(1)',

  color: theme.colors['gray-700'],

  '&.floating': {
    transform: 'translate(0, 6px) scale(0.8)',
  },

  '.error > &': {
    color: theme.colors.error.text,
  },
}));

const StyledFormInput = styled.input(({ theme }) => ({
  width: '100%',
  padding: '22px 12px 6px',
  border: 'none',
  outline: 'none',
  color: theme.colors['gray-800'],
}));

const ErrorMessage = styled.div(({ theme }) => ({
  position: 'absolute',
  top: '47px',

  left: 0,
  right: 0,
  color: 'white',
  backgroundColor: theme.colors.error.bg,
  padding: '4px 12px 0',
  fontSize: '13px',
  fontWeight: 600,
  zIndex: 10000,
}));

/* -----------------------------------------------------------------------------
 * TextLabelFloat
 * ---------------------------------------------------------------------------*/

interface TextLabelFloatProps extends FieldRenderProps<any, HTMLInputElement> {
  label?: string;
  randomizeInputName?: boolean;
  renderExtension?: React.ReactNode;
}

const TextLabelFloat = React.forwardRef<HTMLInputElement, TextLabelFloatProps>(
  (props, forwardedRef) => {
    const {
      label,
      randomizeInputName,
      renderExtension,
      meta,
      input: { value, onFocus, onBlur, name, ...restInputProps },
    } = props;
    const randomId = React.useRef(
      randomizeInputName ? generateRandomId() : undefined
    );
    const [state, dispatch] = React.useReducer(
      floatLabelReducer,
      floatLabelInitialState
    );
    const internalOnFocus = React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        dispatch({ type: 'onFocus' });

        if (onFocus) {
          onFocus(event);
        }
      },
      [onFocus, dispatch]
    );
    const internalOnBlur = React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        dispatch({ type: 'onBlur' });

        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur, dispatch]
    );

    React.useEffect(() => {
      dispatch({
        type: 'onChange',
        payload: {
          data: value as string,
        },
      });
    }, [value]);

    const isFloating =
      state.status === 'floatingHasValue' || state.status === 'floatingNoValue';

    // When randomizeInputName is set, we give the field a unique `name`
    // property to ensure the browser autocomplete (form fill) doesn't show up
    const fieldName =
      randomId.current !== undefined ? `${name}_${randomId.current}` : name;

    const error = meta.error as ValidationError | undefined;
    const hasError = !!error;
    const showErrorMessage = hasError && meta.active;

    return (
      <StyledFormControl className={cn({ error: hasError })}>
        <StyledFormLabel
          htmlFor={fieldName}
          className={isFloating ? 'floating' : ''}
        >
          {label}
        </StyledFormLabel>

        <StyledFormInput
          type="text"
          {...restInputProps}
          ref={forwardedRef}
          name={fieldName}
          value={value}
          onFocus={internalOnFocus}
          onBlur={internalOnBlur}
          autoComplete="new-password"
        />
        {renderExtension}
        {showErrorMessage ? (
          <ErrorMessage>{ErrorMessages[error!]}</ErrorMessage>
        ) : null}
      </StyledFormControl>
    );
  }
);

export { TextLabelFloat };
