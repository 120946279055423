/**
 * The submenu in the combobox menu
 */

import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { StyledComboboxItem } from './combobox.style';
import { StyledContent as StyledDropdownContent } from '../dropdown/style';
import { Suggestion } from '../form-data-loader';

/* -----------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------*/

const StyledContent = styled(StyledDropdownContent)({
  position: 'absolute',
  top: '4px',
  right: '0',
  maxHeight: '200px',
  overflowY: 'auto',
});

/* -----------------------------------------------------------------------------
 * ComboboxSubMenu
 * ---------------------------------------------------------------------------*/

interface ComboboxSubMenuProps {
  isOpen: boolean;
  onClose(): void;
  onChange(item: Suggestion): void;
  items: Suggestion[];
}

const ComboboxSubMenu = ({
  isOpen,
  items,
  onClose,
  onChange,
}: ComboboxSubMenuProps) => {
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);

  useEffect(() => {
    onChange(items[highlightedIndex]);
  }, [items, highlightedIndex, onChange]);

  if (!isOpen) {
    return null;
  }

  return (
    <StyledContent onMouseLeave={onClose}>
      {items.map((item, index) => (
        <StyledComboboxItem.Root
          key={index}
          className={highlightedIndex === index ? 'focus' : ''}
          onMouseEnter={() => setHighlightedIndex(index)}
          onMouseDown={() => {
            onClose();
          }}
        >
          <StyledComboboxItem.ValueColumn>
            {item.value.originalValue}
          </StyledComboboxItem.ValueColumn>
        </StyledComboboxItem.Root>
      ))}
    </StyledContent>
  );
};

export { ComboboxSubMenu };
