import * as React from 'react';
import styled from 'styled-components';

import { Flex, FlexChild } from '../../../components/layout';
import {
  Ticket_ticket_attachments,
  Ticket_ticket_createdBy,
} from '../../../types/apollo/Ticket';
import { TicketStatus } from '../../../types/apollo/globalTypes';
import { getColor } from '../../../components/layout/styled-helpers';
import Accordion from '../../../components/accordion';
import { single } from '../../../components/accordion/shared';
import { Tree, Leaf } from '../../../components/filetree';
import { TicketRow, TicketCol } from './style';
import { UserMenuSmall } from '../../../components/user/user-menu';
import { StatusBadge } from '../../../components/status-badge';
import { getOverrides } from '../../../helpers/overrides';

////////////////////////////////////////////////////////////////////////
// Styles

const TicketIdentifier = styled.span`
  color: ${getColor('gray-700')};
  font-size: 13px;
  letter-spacing: 0;
  font-variant-numeric: normal;
`;

const TicketTitle = styled.h4`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.08em;
  color: #000000;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TicketExtension = styled.tr`
  background-color: ${getColor('white-500')};
`;

const SmallText = styled.a`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.08em;
  color: #a4a4a4;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Attachments = ({
  attachments,
  onClick,
}: {
  attachments?: Ticket_ticket_attachments[];
  onClick: () => void;
}) => {
  let translation = 'Keine Anhänge';

  if (attachments) {
    if (attachments.length === 1) {
      translation = '1 Anhang';
    } else if (attachments.length > 1) {
      translation = `${attachments.length} Anhänge`;
    }
  }

  return (
    <SmallText onClick={onClick} title="Anhänge anzeigen">
      {translation}
    </SmallText>
  );
};

////////////////////////////////////////////////////////////////////////
// Component

export interface Ticket {
  id: string;
  title: string;
  attachments?: Ticket_ticket_attachments[];
  status: TicketStatus;
  failureReason?: string | null;
  createdBy: Ticket_ticket_createdBy;
}

const EmptyActionCol = () => null;

interface Overrides {
  ActionCol?: {
    component?: React.ComponentType<any>;
    props?: {};
  };
}

interface Props {
  ticket: Ticket;
  overrides?: Overrides;
}

const TicketListItem = ({ ticket, overrides = {} }: Props) => {
  const [ActionCol, actionColProps] = getOverrides(
    overrides.ActionCol,
    EmptyActionCol
  );

  const { attachments, status } = ticket;
  return (
    <Accordion stateReducer={single}>
      {({ openIndexes, handleItemClick }) => (
        <>
          <TicketRow>
            {/* First Column */}
            <TicketCol width="50%">
              <Flex flexDirection="column">
                <FlexChild>
                  <TicketTitle>
                    <TicketIdentifier>#{ticket.id}</TicketIdentifier>{' '}
                    {ticket.title}
                  </TicketTitle>
                </FlexChild>
                <FlexChild>
                  <Flex>
                    {status === TicketStatus.failed ? (
                      <FlexChild mr="2">
                        <StatusBadge
                          onClick={() => handleItemClick('FAILURE_REASON')}
                          title="Fehlermeldung anzeigen"
                        >
                          Analyse Fehlgeschlagen
                        </StatusBadge>
                      </FlexChild>
                    ) : null}
                    <FlexChild>
                      <Attachments
                        attachments={attachments}
                        onClick={() => handleItemClick('ATTACHMENTS')}
                      />
                    </FlexChild>
                  </Flex>
                </FlexChild>
              </Flex>
            </TicketCol>

            <TicketCol>
              <UserMenuSmall
                firstName={ticket.createdBy.firstName}
                lastName={ticket.createdBy.lastName}
              />
            </TicketCol>

            {/* Third Column */}
            <TicketCol width="auto">
              <ActionCol ticket={ticket} {...actionColProps} />
            </TicketCol>
          </TicketRow>

          {/* Failure Reason */}
          {ticket.status === TicketStatus.failed &&
          openIndexes.includes('FAILURE_REASON') ? (
            <TicketExtension>
              <td colSpan={3}>
                <pre>{ticket.failureReason}</pre>
              </td>
            </TicketExtension>
          ) : null}

          {/* Attachment tree container */}
          {openIndexes.includes('ATTACHMENTS') ? (
            <TicketExtension>
              <td colSpan={3}>
                <Tree>
                  {attachments &&
                    attachments.map((attachment) => (
                      <Leaf key={attachment.id}>{attachment.fileName}</Leaf>
                    ))}
                </Tree>
              </td>
            </TicketExtension>
          ) : null}
        </>
      )}
    </Accordion>
  );
};

export { TicketListItem };
