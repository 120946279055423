import React from 'react';
import styled from 'styled-components';

const FormGroupTitle = styled.h4`
  text-transform: uppercase;
  padding: 11px 12px 12px;
  margin: 0;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: #ffffff;
`;

const FormGroupContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 24px;
`;

export const FormGroup = ({
  title,
  children,
}: React.PropsWithChildren<{
  title: string;
}>) => (
  <FormGroupContainer>
    <FormGroupTitle>{title}</FormGroupTitle>
    {children}
  </FormGroupContainer>
);
