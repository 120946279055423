/**
 * Translations for the form labels
 */

export const formLabelTranslations = {
  companyName: 'Firmenname',
  companyContactName: 'Name des Ansprechpartners',
  companyEmail: 'E-Mail-Adresse',
  companyPhonenumber: 'Telefonnummer',
  propertyName: 'Bezeichnung',
  propertyGrossIncome: 'Bruttomiete',
  propertyNetIncome: 'Nettomiete',
  propertyLandArea: 'Grundstücksfläche',
  propertyLettableArea: 'Mietfläche',
  propertyOccupancy: 'Vermietungsstand',
  propertyUse: 'Nutzungsart',
  propertyParkingSpaces: 'Anzahl Stellplätze',
  propertyHotelRooms: 'Hotelräume',
  propertyConstructionYear: 'Baujahr',
};

// Maps keys of the fields to human readable names
const FieldLabels = {
  companyCity: 'Stadt',
  companyContactName: formLabelTranslations.companyContactName,
  companyCountry: 'Land',
  companyEmail: formLabelTranslations.companyEmail,
  companyLocationLat: 'Koordinaten (lat)',
  companyLocationLng: 'Koordinaten (lng)',
  companyName: formLabelTranslations.companyName,
  companyPhonenumber: formLabelTranslations.companyPhonenumber,
  companyPostalCode: 'PLZ',
  companyStreetName: 'Straße',
  companyStreetNumber: 'Hausnummer',
  propertyCity: 'Stadt',
  propertyConstructionYear: formLabelTranslations.propertyConstructionYear,
  propertyCountry: 'Land',
  propertyGrossIncome: formLabelTranslations.propertyGrossIncome,
  propertyHotelRooms: formLabelTranslations.propertyHotelRooms,
  propertyLandArea: formLabelTranslations.propertyLandArea,
  propertyLettableArea: formLabelTranslations.propertyLettableArea,
  propertyLocationLat: 'Koordinaten (lat)',
  propertyLocationLng: 'Koordinaten (lng)',
  propertyName: 'Bezeichnung',
  propertyNetIncome: formLabelTranslations.propertyNetIncome,
  propertyOccupancy: formLabelTranslations.propertyOccupancy,
  propertyParkingSpaces: formLabelTranslations.propertyParkingSpaces,
  propertyPostalCode: 'PLZ',
  propertyStreetName: 'Straße',
  propertyStreetNumber: 'Hausnummer',
  propertyUse: formLabelTranslations.propertyUse,
  ticketAreaUnit: 'AreaUnit',
  ticketCurrencyUnit: 'CurrencyUnit',
} as const;

export { FieldLabels };
