/**
 * Toolbar for the pdf viewer
 */

import * as React from 'react';
import styled, { css } from 'styled-components';
import { Toolbar } from '@eoda-intern/react-pdfjs';

import { Fixed } from '../layout';
import Icon from '../icon';

const Container = styled(Fixed)`
  background-color: white;
  left: 50%;
  transform: translateX(-267px); /* (470 / 2) + ((32 * 2 + 1)) / 2) */
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.03), -2px 2px 8px rgba(0, 0, 0, 0.33);
  border-radius: 6px;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  height: 32px;
  padding: 0 2px;

  ${({ border }) =>
    border &&
    css`
      border-right: 1px solid #ebebeb;
    `};
`;

const PDFToolbar = (props) => {
  return (
    <Toolbar>
      {({ zoomIn, zoomOut }) => (
        // zIndex needs to be > 1000 to be visible above pdfjs pages
        <Container zIndex="1001" bottom={16}>
          <Button onClick={() => zoomIn()} border>
            <Icon name="plus" />
          </Button>
          <Button onClick={() => zoomOut()}>
            <Icon name="minus" />
          </Button>
        </Container>
      )}
    </Toolbar>
  );
};

export default PDFToolbar;
