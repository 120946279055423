/**
 * Library Component: Action button
 * Button with an optional Icon on it
 */

import React from 'react';
import styled, {
  css,
  FlattenSimpleInterpolation,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

import { getOverrides } from '../../../helpers/overrides';
import { SmallIcon } from '../../../components/icon';
import { FlexChild } from '../../../components/layout';
import { Spinner } from '../../../components/spinner';

export const actionButtonStyle = css<{ styledOverrides?: any }>(
  ({ theme, styledOverrides = {} }) => ({
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.colors['gray-800'],
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '12px',
    letterSpacing: '0.06em',
    display: 'flex',
    alignItems: 'baseline',
    padding: '8px 8px 7px',
    borderRadius: '6px',
    whiteSpace: 'nowrap',

    '&:hover': {
      backgroundColor: theme.colors.bg.buttonPrimary,
      color: theme.colors.text.buttonPrimary,
    },

    '&.left': {
      flexDirection: 'row-reverse',
    },
    ...styledOverrides,
  })
);

const StyledRoot = styled.button(() => actionButtonStyle);

interface Overrides {
  Root?: {
    component?: React.ComponentType<any>;
    props?: {};
    style?: FlattenSimpleInterpolation | FlattenInterpolation<ThemeProps<any>>;
  };
  Icon?: {
    component?: React.ComponentType<any>;
    props?: {};
  };
}

enum IconPosition {
  left,
  right,
}

export interface ActionButtonProps {
  overrides?: Overrides;
  onClick?: (e: React.SyntheticEvent) => void;
  children: string;
  icon?: boolean;
  iconPosition?: keyof typeof IconPosition;
  loading?: boolean;
  disabled?: boolean;
}

export const ActionButton = ({
  overrides = {},
  onClick,
  children,
  iconPosition = 'right',
  icon: showIcon = false,
  loading = false,
  disabled = false,
}: ActionButtonProps) => {
  const rootClassNames =
    IconPosition[iconPosition] === IconPosition.left ? 'left' : '';
  const [Root, rootProps] = getOverrides(overrides.Root, StyledRoot);
  const [Icon, iconProps] = getOverrides(overrides.Icon, SmallIcon);

  return (
    <Root
      onClick={(e: React.SyntheticEvent) => {
        // Prevent click event if the button is disabled
        if (onClick && !disabled) {
          onClick(e);
        }
      }}
      className={rootClassNames}
      aria-label="button"
      role="button"
      {...rootProps}
    >
      <FlexChild>{children}</FlexChild>
      {showIcon ? (
        <FlexChild
          pl={IconPosition[iconPosition] === IconPosition.right ? 1 : undefined}
          pr={IconPosition[iconPosition] === IconPosition.left ? 2 : undefined}
        >
          {loading ? (
            <Spinner />
          ) : (
            <Icon name="arrowRight" fill="gray-800" {...iconProps} />
          )}
        </FlexChild>
      ) : null}
    </Root>
  );
};
