import * as React from 'react';

import { StyledComboboxItem } from './Item.css';

type ItemProps = {
  count?: number;
  id?: string;
  isHighlighted?: boolean;
  label?: string;
  number?: string;
  originalValue: string;
  unit?: string | null;
  onClick?(event: React.MouseEvent<HTMLDivElement>): void;
  onMouseDown?(event: React.MouseEvent<HTMLDivElement>): void;
  onMouseMove?(event: React.MouseEvent<HTMLDivElement>): void;
};

const Item = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ItemProps>
>((props, forwardedRef) => {
  const {
    children,
    isHighlighted,
    number,
    originalValue,
    unit,
    onClick,
    onMouseDown,
    onMouseMove,
  } = props;
  const showNumberRow =
    number !== null &&
    number !== undefined &&
    unit !== null &&
    unit !== undefined;

  return (
    <StyledComboboxItem.Root
      ref={forwardedRef}
      className={isHighlighted ? 'focus' : ''}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
    >
      <StyledComboboxItem.ValueColumn>
        <div>{originalValue}</div>

        {/* Show additional info for number and unit */}
        {showNumberRow ? (
          <StyledComboboxItem.NumberRow>
            <StyledComboboxItem.NumberValue>
              {number}
            </StyledComboboxItem.NumberValue>
            <StyledComboboxItem.UnitValue>{unit}</StyledComboboxItem.UnitValue>
          </StyledComboboxItem.NumberRow>
        ) : null}
      </StyledComboboxItem.ValueColumn>

      {/* Show number subitems and menu */}
      <div>{children}</div>
    </StyledComboboxItem.Root>
  );
});

export { Item };
