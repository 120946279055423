/**
 * Basic dropdown
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

type RenderProps = {
  isOpen: boolean;
  toggle: (e: React.SyntheticEvent) => void;
};

type DropdownRenderProps = {
  close: (e?: React.SyntheticEvent) => void;
};

interface Props {
  children: (props: RenderProps) => React.ReactNode;
  dropdown: (props: DropdownRenderProps) => React.ReactNode;
}

const Root = styled.div({
  position: 'relative',
});

export const Dropdown = ({ children, dropdown }: Props) => {
  const [state, setState] = useState({ isOpen: false });

  function internalToggle(value?: boolean) {
    setState((prevState) => ({
      isOpen: value !== undefined ? value : !prevState.isOpen,
    }));
  }

  function toggle(e: React.SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();
    internalToggle();
  }

  function close(e?: React.SyntheticEvent) {
    internalToggle(false);
  }

  function clickListener(e: Event) {
    internalToggle(false);
  }

  useEffect(() => {
    // When dropdown is open get all click events
    window.addEventListener('click', clickListener);

    return () => {
      window.removeEventListener('click', clickListener);
    };
  });

  const renderProps = {
    isOpen: state.isOpen,
    toggle,
  };

  return (
    <Root>
      {children(renderProps)}
      {state.isOpen
        ? dropdown({
            close,
          })
        : null}
    </Root>
  );
};
