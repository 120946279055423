import styled from 'styled-components';

/**
 * Transparent button
 * (As alternative to links without href)
 */
export const TransparentButton = styled.button({
  padding: 0,
  backgroundColor: 'transparent',
  border: 'none',
});
