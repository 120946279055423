import gql from 'graphql-tag';

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser @rest(type: "User", path: "users/current/") {
      id
      username
      firstName
      lastName
    }
  }
`;

export { CURRENT_USER_QUERY };
