import styled from 'styled-components';
import { useModal } from '..';

import { SubActionButtonCover } from '../../button/sub-action';
import { Box } from '../../layout';
import { ModalBodyWrapper } from './styles';

const MessageBox = styled.div({
  backgroundColor: '#FFE1DF',
  border: '1px solid #EC736B',
  padding: '12px',
  margin: '24px',
  color: '#DF2800',
  lineHeight: '140%',
  fontWeight: 600,
});

const Footer = styled(Box)`
  border-top: 1px solid #dbdbdb;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const WarningMessageTemplate = () => {
  const { closeModal } = useModal();

  return (
    <ModalBodyWrapper>
      <MessageBox>
        Keine Bilder oder Pläne wurden hinterlegt. <br />
        Überprüfung trotzdem abschließen?
      </MessageBox>

      <Footer px="5" py="4" mt="5">
        <SubActionButtonCover height="42px" onClick={() => closeModal(true)}>
          Zurück zur Überprüfung
        </SubActionButtonCover>
        &emsp;
        <SubActionButtonCover height="42px" onClick={() => closeModal(false)}>
          Ohne Bilder abschließen
        </SubActionButtonCover>
      </Footer>
    </ModalBodyWrapper>
  );
};
