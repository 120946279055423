import styled from 'styled-components';

import * as SelectMenu from '../SelectMenu';

const StyledCombobox = styled.div({
  minWidth: '100px',
});

const StyledSearchInput = styled.input(({ theme }) => ({
  width: '100%',
  minWidth: '60px',
  padding: '4px 6px',
  fontSize: '14px',
}));

const StyledSelectPanel = {
  Root: styled.div({ position: 'relative' }),
  Modal: styled(SelectMenu.Modal)({
    position: 'absolute',
    top: '32px',
    right: 0,
    zIndex: 1000,
  }),
};

export { StyledCombobox, StyledSearchInput, StyledSelectPanel };
