import React from 'react';

import { Wrapper, FullHeight } from '../block';
import { Fixed } from '..';
import MenuHeader from '../../menu/header';
import { DisplayNotifications } from '../../notifications';
import { BackgroundImage } from './style';

interface LayoutHeaderPropsT {
  fullHeight?: boolean;
}

const BaseLayout = ({
  children,
  fullHeight,
}: React.PropsWithChildren<LayoutHeaderPropsT>) => {
  return (
    <BackgroundImage>
      <Fixed top="0" right="0" left="0" zIndex={1000}>
        <MenuHeader />
      </Fixed>

      {fullHeight ? (
        <FullHeight>{children}</FullHeight>
      ) : (
        <Wrapper pt={60} pb={40}>
          {children}
        </Wrapper>
      )}

      <DisplayNotifications />
    </BackgroundImage>
  );
};

export function withBaseLayout<T extends {}>(
  Component: React.ComponentType<any>
): React.FC<T> {
  return (props) => (
    <BaseLayout>
      <Component {...props} />
    </BaseLayout>
  );
}

export function withBaseLayoutNoPadding<T extends {}>(
  Component: React.ComponentType<any>
): React.FC<T> {
  return (props) => (
    <BaseLayout fullHeight>
      <Component {...props} />
    </BaseLayout>
  );
}
