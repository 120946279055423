/**
 * Helper which shows an empty page with the modal to edit an template
 */

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ModalTemplate, useModal } from '../../components/modal';
import { TicketQuery, TICKET_QUERY } from '../../queries/tickets/ticket';
import { Ticket } from '../../types/apollo/Ticket';

const EditTicketView = (
  props: RouteComponentProps<{
    ticketId: string;
  }>
) => {
  // The Query is also executed in the modal itself but we need the ticket here to get
  // the title of the ticket
  const { showModal } = useModal();

  return (
    <TicketQuery
      query={TICKET_QUERY}
      variables={{ ticketId: props.match.params.ticketId }}
      onCompleted={(data) => {
        // :face-with-rolling-eyes:
        // https://github.com/apollographql/react-apollo/pull/2496
        const ticketData = data as Ticket;
        const { ticket } = ticketData;

        showModal({
          template: ModalTemplate.TICKET_EDIT,
          title: `„${ticket.title}“ bearbeiten`,
          props: {
            ticketId: ticket.id,
          },
        });
      }}
    >
      {() => null}
    </TicketQuery>
  );
};

export { EditTicketView };
