/**
 * Some useful util components
 */
import styled, { css } from 'styled-components';
import * as styledSystem from 'styled-system';

interface FixedProps
  extends styledSystem.ZIndexProps,
    styledSystem.TopProps,
    styledSystem.RightProps,
    styledSystem.BottomProps,
    styledSystem.LeftProps {}

const Fixed = styled.div<FixedProps>`
  position: fixed;
  ${styledSystem.top}
  ${styledSystem.right}
  ${styledSystem.bottom}
  ${styledSystem.left}
  ${styledSystem.zIndex}
`;

const Absolute = styled.div<styledSystem.SpaceProps>`
  position: absolute;
  ${styledSystem.space}
`;

interface BoxProps
  extends styledSystem.ColorProps,
    styledSystem.SpaceProps,
    styledSystem.HeightProps {}

const Box = styled.div<BoxProps>`
  ${styledSystem.space}
  ${styledSystem.height}
  ${styledSystem.width}
  ${styledSystem.color}
  ${styledSystem.borderRadius}
`;

interface FlexProps
  extends styledSystem.FlexProps,
    styledSystem.FlexDirectionProps,
    styledSystem.JustifyContentProps,
    styledSystem.AlignItemsProps {}

const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${styledSystem.alignItems}
  ${styledSystem.alignContent}
  ${styledSystem.justifyContent}
  ${styledSystem.flexWrap}
  ${styledSystem.flexDirection}
  ${styledSystem.flex}
  ${styledSystem.flexBasis}
  ${styledSystem.order}
`;

export const StackView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface FlexChildProps
  extends styledSystem.AlignSelfProps,
    styledSystem.FlexProps,
    styledSystem.FlexBasisProps {}

const FlexChild = styled(Box)<FlexChildProps>`
  ${styledSystem.justifySelf}
  ${styledSystem.alignSelf}
    ${styledSystem.flex}
    ${styledSystem.zIndex}
    ${styledSystem.flexBasis}
`;

// Hack so that typescript thinks that Flex object is unmodified
// Pls dont tell typescript that it's not!
Object.assign(Flex, { Child: FlexChild });

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const boxShadow = css`
  box-shadow: 2px 2px 3px rgba(51, 59, 73, 0.1),
    -2px 2px 4px rgba(51, 59, 73, 0.1);
`;

export { Absolute, Box, boxShadow, Fixed, Flex, FlexChild, List };
