import React from 'react';
import { DropzoneRootProps } from 'react-dropzone';
import styled, { css } from 'styled-components';

import { getColor } from '../layout/styled-helpers';

import { ReactComponent as UploadIllustration } from './assets/upload.svg';

interface ContainerProps extends DropzoneRootProps {
  isDragActive: boolean;
  isDragReject: boolean;
  small?: boolean;
}

const BasicContainer = styled.div<ContainerProps>`
  border-width: 2px;
  border-color: ${getColor('gray-400')};
  border-style: dashed;
  cursor: pointer;
  padding: ${({ small }) => (small ? '4px 0 8px' : '16px 0')};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${({ small }) =>
    small &&
    css`
      margin: 0 6px 6px;
    `}

  ${({ isDragReject }) =>
    isDragReject &&
    css`
      border-color: getColor('red-500');
    `}
    

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      border-color: getColor('blue-400');
    `}

  background-color: ${(props) =>
    props.isDragReject || props.isDragActive ? '#eee' : '#F6F6F6'};
`;

const TextBox = styled.div`
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.06em;
  color: ${getColor('gray-600')};
  margin-top: 6px;
`;

const SelectButton = styled.button`
  background: transparent;
  border: none;
  color: ${getColor('blue-500')};
  font-size: 13px;
  display: inline;
`;

interface UploadContainerProps extends ContainerProps {
  size: 'cover' | 'small';
}

export const UploadContainer = React.forwardRef(
  ({ size, children, ...props }: UploadContainerProps, ref) => {
    return (
      <BasicContainer {...props} ref={ref} small={size === 'small'}>
        {size === 'small' ? (
          <>
            <TextBox>
              Zum Hochladen PDF-Dateien in das Feld ziehen oder
              <SelectButton
                onClick={(e) => {
                  if (props.onClick) {
                    props.onClick(e);
                  }
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                Auswählen...
              </SelectButton>
              {children}
            </TextBox>
          </>
        ) : (
          <>
            <UploadIllustration />

            <TextBox>
              Zum Hochladen PDF-Dateien in das Feld ziehen
              <br /> oder
              <br />
              <SelectButton
                onClick={(e) => {
                  if (props.onClick) {
                    props.onClick(e);
                  }
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                Auswählen...
              </SelectButton>
            </TextBox>
            {children}
          </>
        )}
      </BasicContainer>
    );
  }
);
