import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Action } from 'redux';
import { connect, DispatchProp } from 'react-redux';
import mitt from 'mitt';

import Viewer from '../../../components/viewer';
import { Flex } from '../../../components/layout';
import EditorWorkspace from './components/workspace';
import SiteTitle from '../../../components/site-title';
import { setAnnotations } from '../../../redux/pdf-annotations/actions';
import { AttachmentSuggestions } from '../../../components/form-data-loader';
import { ViewerMap } from './viewer-map';
import {
  ViewerSwitcher,
  ViewerSwitcherConsumer,
} from '../../../components/viewer-switcher';
import { GoogleMapsClient } from '../../../components/google-maps-client';
import { EditorSidebar } from '../../../components/editor-sidebar';
import { EmitterEvents as ReactPDFEmitterEvents } from '@eoda-intern/react-pdfjs';
import {
  ProcessedAttachmentItem,
  ProcessedAttachmentItemPlaceholder,
  TicketItemReview,
} from '../../../api/ticket/get-ticket';

type EditorMode =
  | 'view' // Shows the PDF in viewer and allows to select text
  | 'snip'; // Disables text selection in viewer, displays a box for snipping

type ChangeModeEvent = {
  mode: EditorMode;
};

type ModeChangedEvent = {
  mode: EditorMode;
};

type EmitterEvents = {
  snip: ReactPDFEmitterEvents['snip'];
  changeMode: ChangeModeEvent;
  modeChanged: ModeChangedEvent;
};

interface Props extends DispatchProp<Action> {
  suggestions: Record<number, AttachmentSuggestions>;
  ticket: TicketItemReview;
}

const Editor = (props: Props) => {
  const [currentAttachment, setCurrentAttachment] = React.useState<
    ProcessedAttachmentItem | ProcessedAttachmentItemPlaceholder | undefined
  >(() => {
    // Find first attachment that is of type document
    return props.ticket.attachments.find(
      (attachment) => attachment.type === 'document'
    );
  });
  const viewer = useRef<any>();

  // Changes the file in the viewer
  const changeFile = (id: number) => {
    setCurrentAttachment(
      props.ticket.attachments.find((attachment) => attachment.id === id)
    );
  };

  // Show highlight box on the corresponding page
  const showSuggestion = (suggestionId: string): void => {
    // Get the suggestion
    // Attributes should be set here because it can only be used when the network request is finished
    if (!currentAttachment) {
      return;
    }

    const suggestionsForAttachment = props.suggestions[currentAttachment.id];

    if (!suggestionsForAttachment) {
      return;
    }

    const suggestion = suggestionsForAttachment.suggestions[suggestionId];
    if (!suggestion) {
      return;
    }

    const { attribute } = suggestion;
    let visibleAttributes;

    visibleAttributes = {
      [attribute.page.number]: attribute.coordinates.map((coordinate) => ({
        x: coordinate.x,
        y: coordinate.y,
        width: coordinate.w,
        height: coordinate.h,
      })),
    };

    props.dispatch!(setAnnotations(visibleAttributes));

    const firstCoordinate = attribute.coordinates[0];

    // Build the rectangle where the page should be scrolled to
    const rect = {
      x: firstCoordinate.x,
      y: firstCoordinate.y,
      width: firstCoordinate.w,
      height: firstCoordinate.h,
    };

    if (viewer.current) {
      viewer.current.goToPage(attribute.page.number, rect);
    }
  };

  const emitterRef = React.useRef(mitt<EmitterEvents>());
  const snippetCreatedHandler = (event: EmitterEvents['snip']) => {
    emitterRef.current.emit('snip', event);
  };
  const editorModeChangedHandler = (mode: EditorMode) => {
    emitterRef.current.emit('modeChanged', { mode });
  };

  useEffect(() => {
    function changeModeHandler(event: ChangeModeEvent) {
      if (viewer.current) {
        viewer.current.setEditorMode(event.mode);
      }
    }

    emitterRef.current.on('changeMode', changeModeHandler);

    return () => {
      emitterRef.current.off('changeMode', changeModeHandler);
    };
  }, []);

  if (!currentAttachment) {
    // Should never happen
    return null;
  }

  const { ticket, suggestions } = props;
  const { groupedSuggestions } = suggestions[currentAttachment.id];

  return (
    <Flex flex="1">
      <GoogleMapsClient>
        <SiteTitle title={ticket.title} />
        <ViewerSwitcher>
          <ViewerSwitcherConsumer>
            {({ state }) => {
              switch (state.type) {
                case 'pdf': {
                  return (
                    <Viewer
                      source={currentAttachment.file}
                      ref={viewer}
                      onSnippetCreated={snippetCreatedHandler}
                      onEditorModeChanged={editorModeChangedHandler}
                      highlights={[]}
                    />
                  );
                }
                case 'map': {
                  return <ViewerMap />;
                }
              }
            }}
          </ViewerSwitcherConsumer>

          <EditorSidebar
            attachments={ticket.attachments}
            emitterRef={emitterRef}
            ticketId={ticket.id}
            mainPictureId={ticket.mainPictureId}
          />

          <EditorWorkspace
            ticket={ticket}
            showBox={showSuggestion}
            suggestions={groupedSuggestions}
            changeFile={changeFile}
            currentAttachmentId={currentAttachment.id}
          />
        </ViewerSwitcher>
      </GoogleMapsClient>
    </Flex>
  );
};

export type { EmitterEvents, ModeChangedEvent, EditorMode };
export default connect()(Editor);
